import React, {useEffect, useState} from 'react';
import './GroupExpenses.css';
import Header from "../../../../../Components/Header/Header";
import GroupDayPicker from "../GroupAbsence/GroupDayPicker/GroupDayPicker";
import StudentSearch from "../GroupAddStudent/StudentSearch/StudentSearch";
import GroupAttend from "../GroupAbsence/GroupAttend/GroupAttend";
import {useParams} from "react-router-dom";
import {fetchStudentsExpenses, addStudentToExpenses, addAllStudentsToExpenses} from "../../../../../store/actions/groups.actions";
import {connect} from "react-redux";
import Spinner from "../../../../../Components/Spinner/Spinner";
import Student from "../GroupAddStudent/Student/Student";

const GroupExpenses = ({students, fetchStudentsExpenses, addingAllStudentsExpenses, addAllStudentsToExpenses, currentGroupCount, addStudentToExpenses, fetchingStudentsExpenses}) => {
    const [date, setDate] = useState(`${new Date().getMonth() + 1}`.length < 2 ? `${new Date().getFullYear()}-0${new Date().getMonth() + 1}` : `${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
    const [pay, setPay] = useState(2);
    const [term, setTerm] = useState('');
    const [searchResults, setSearchResults] = useState(students);
    const params = useParams();

    useEffect(() => {
        handleSearch(term);
    }, [students, pay, date]);

    useEffect(() => {
        if(!pay) return;
        const data = {
            date,
            group_id: params.group_id,
            pay
        }
        fetchStudentsExpenses(data);
    }, [pay, date]);

    const handleSearch = (value) => {
        let studentsMatch = [];
        if(!isNaN(value)) {
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.phone.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch);
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.phone.toLowerCase().split('').indexOf(firstLetter[0]) - b.phone.toLowerCase().split('').indexOf(firstLetter[0]));

        } else {
            console.log(value);
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.name?.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch)
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.name.toLowerCase().split('').indexOf(firstLetter[0]) - b.name.toLowerCase().split('').indexOf(firstLetter[0]));
        }

        setSearchResults(studentsMatch);
    }
    const inputChangeHandler = e => {
        setTerm(e.target.value);
        let value = e.target.value;
        if(!value || value.trim().length == 0) setSearchResults(students);
        handleSearch(value);
    }
    return (
        <div className={'GroupExpenses'}>
            <Header text={'اعدادات المصروفات'} />
            <div className="GroupAbsence__header">
                <GroupDayPicker expenses={true} date={date} setDate={setDate} />
                <StudentSearch term={term} inputChangeHandler={inputChangeHandler} />
                <GroupAttend absenses={false} setAttend={setPay} attend={pay} />
            </div>
            {
                fetchingStudentsExpenses ? (
                    <Spinner />
                ) : (
                    <div className={'GroupAddStudent__students'}>
                        {
                            searchResults?.map((s, i) => (
                                <Student clickToAddFunc={addStudentToExpenses} date={date} pay={pay} student={s} key={i} currentGroupCount={currentGroupCount} />
                            ))
                        }
                    </div>
                )
            }
            {
                pay == 2 && (
                    <div onClick={e => {
                        const data = {
                            date,
                            grade: params.id,
                            group_id: params.group_id,
                            pay,
                        }
                        addAllStudentsToExpenses(data);
                    }} className="GroupExpenses__add--all">
                        {
                            addingAllStudentsExpenses ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>اضافة الكل</span>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    students: state.groups.expenses,
    fetchingStudentsExpenses: state.groups.fetchingStudentsExpenses,
    currentGroupCount: state.groups.currentGroupCount,
    addingAllStudentsExpenses: state.groups.addingAllStudentsExpenses
})

export default connect(mapStateToProps, {fetchStudentsExpenses, addAllStudentsToExpenses, addStudentToExpenses}) (GroupExpenses);