import React, {useEffect, useState} from 'react';
import './ClassExams.css';
import Header from '../../../../../../../Components/Header/Header'
import { connect } from 'react-redux';
import { fetchClassExams } from '../../../../../../../store/actions/doors.actions';
import {Outlet, useParams} from 'react-router-dom';
import Spinner from '../../../../../../../Components/Spinner/Spinner';
import ClassExam from "./ClassExam/ClassExam";
import ClassExamModal from "./ClassExamModal/ClassExamModal";
import ClassExamForm from "./ClassExamForm/ClassExamForm";

function ClassExams({fetchClassExams, fetchingClassExams, exams}) {
    const [currentExam, setCurrentExam] = useState(null);
    const [modal, setModal] = useState(false);
    const [show, setShow] = useState(false);
    const params = useParams();
    useEffect(() => {
        const data = {
            grade: params.id,
            class_id: params.class_id
        }
        fetchClassExams(data);
    }, []);
    useEffect(() => {
	    const home = document.querySelector('.ClassPage');
	    const freezeStyles = () => {
	        home.classList.add('Home__hide')
	    }
	    const releaseStyles = () => {
	        home.classList.remove('Home__hide')
	    }

	    freezeStyles();

	    return () => {
	        releaseStyles();
	    }
    }, []);
  return (
    <div className='ClassExams'>
        <Header text={'الاداء'} />
        {
            fetchingClassExams ? (
                <Spinner />
            ) : (
                <div className='ClassExams__list'>
                    {
                        exams.map((exam, i) => (
                            <ClassExam link={`${exam.id}/details`} modal={modal} setModal={setModal} currentExam={currentExam} setCurrentExam={setCurrentExam} exam={exam} key={exam.id} />
                        ))
                    }
                </div>
            )
        }
        <ClassExamForm show={show} setShow={setShow} />
        <ClassExamModal modal={modal} setModal={setModal} currentExam={currentExam} setCurrentExam={setCurrentExam} />
        <div onClick={e => setShow(true)} className='ClassPdfs__add'>
            <p>اضافة اداء</p>
            <i class="fa-solid fa-plus"></i>
        </div>
        <Outlet />
    </div>
  )
}

const mapStateToProps = state => ({
    fetchingClassExams: state.doors.fetchingClassExams,
    exams: state.doors.exams
})

export default connect(mapStateToProps, {fetchClassExams}) (ClassExams);