import React, {useEffect, useRef, useState} from 'react';
import './GroupPosts.css';
import {fetchGroupPosts, resetGroupPosts, addGroupPost} from "../../../../../store/actions/groups.actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Header from "../../../../../Components/Header/Header";
import Spinner from "../../../../../Components/Spinner/Spinner";
import GroupPostsLilst from "./GroupPostsList/GroupPostsLilst";

const GroupPosts = ({posts, addingGroupPost, morePosts, loadingMore, loadingTheAddedPost, fetchingPosts, addGroupPost, record, fetchGroupPosts, resetGroupPosts}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const params = useParams();

    const loadPosts = () => {
        const data = {
            record,
            group_id: params.group_id,
            grade: params.id
        };
        fetchGroupPosts(data);
    }

    useEffect(() => {
        loadPosts();
        return () => {
            resetGroupPosts();
        }
    }, []);

    // const enterKeyPressHandler = e => {
    //     if(e.key === 'Enter' && !e.shiftKey) sendMessageHandler(e);
    // }

    const fileRef = useRef();

    const fileInputChangeHandler = async e => {
        console.log(e.target.files);
        const file = e.target.files[0];
        console.log(file);
        await readURL(file);
        fileRef.current.value = '';
    }

    async function readURL(input) {
        if (input) {
            console.log(input);
            const reader = new FileReader();
            reader.onload = await function (e) {
                setSelectedImage( {file: input, url: e.target.result});
            }
            reader.readAsDataURL(input);
        }
    }

    const formateDate = date => {
        if(date.length == 1) {
            return `0${date}`;
        }
        return date;
    }

    const publish = async e => {
        const text = inputRef?.current?.innerText;
        console.log(text)
        const now = new Date();
        const date = `${now.getFullYear()}-${formateDate(`${now.getMonth() + 1}`)}-${formateDate(`${now.getDate()}`)} ${formateDate(`${now.getHours() - 12}`)}:${formateDate(`${now.getMinutes()}`)}:${formateDate(`${now.getSeconds()}`)}`;
        console.log(date);
        const data = {
            post_details: text,
            group_id: params.group_id,
            filename: selectedImage == null ? null :  selectedImage.file,
            type_uploading: selectedImage == null ? 0 : 1,
            grade: params.id,
            date,
            record
        };
        await addGroupPost(data);

    }

    const inputRef = useRef();

    return (
        <div className={'GroupPosts'}>
            <Header text={'المنشورات'} />
            <div className="GroupPosts__form">
                <div className="w-input-text-group">
                    <div ref={inputRef} id="w-input-text" contentEditable></div>
                    <div className="w-placeholder">
                        اضافة منشور جديد
                    </div>
                </div>
                {
                    selectedImage && (
                        <div className="GroupPosts__image--show">
                            <img src={selectedImage.url} alt=""/>
                        </div>
                    )
                }
                <div className="GroupPosts__btns">
                    <div onClick={publish} className="GroupPosts__btn GroupPosts__publish">
                        {
                            addingGroupPost ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>نشر</span>
                            )
                        }
                    </div>
                    <div className="GroupPosts__btn GroupPosts__image">
                        <input ref={fileRef} onChange={e => fileInputChangeHandler(e)} type="file" accept={'image/*'} className={'GroupPosts__file'}/>
                        <span>اضافة صورة</span>
                    </div>
                </div>
            </div>

            {
                loadingTheAddedPost && <div className={'GroupPosts__loadpost--spinner'}>
                    <i className="fa-solid fa-circle-notch"></i>
                </div>
            }
            {
                fetchingPosts ? (
                    <Spinner />
                ) : (
                    <GroupPostsLilst posts={posts} />
                )
            }
            {
                loadingMore && <div className={'GroupPosts__loadpost--spinner'}>
                    <i className="fa-solid fa-circle-notch"></i>
                </div>
            }
            {
                morePosts && <div className={'GroupPosts__moreposts'} onClick={loadPosts}>load more</div>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    posts: state.groups.posts,
    morePosts: state.groups.morePosts,
    record: state.groups.record,
    fetchingPosts: state.groups.fetchingPosts,
    loadingTheAddedPost: state.groups.loadingTheAddedPost,
    loadingMore: state.groups.loadingMore,
    addingGroupPost: state.groups.addingGroupPost
});

export default connect(mapStateToProps, {fetchGroupPosts, resetGroupPosts, addGroupPost}) (GroupPosts);