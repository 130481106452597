import {
    END_CREATING_EXSAM,
    END_DELETING_EXSAM,
    END_FETCHING_EXSAM, END_FETCHING_STUDENTS_MARKS, END_INSERTING_STUDENT_EXAM_DEGREE,
    FETCH_EXAMS, FETCH_STUDENTS_MARKS,
    START_CREATING_EXSAM,
    START_DELETING_EXSAM,
    START_FETCHING_EXSAM, START_FETCHING_STUDENTS_MARKS, START_INSERTING_STUDENT_EXAM_DEGREE,
} from "./types.actions";
import axios from "axios";
import {BASE_URL} from "../../utls/constants";
import {setAlert} from "./ui.actions";



export const startFetchingExsam = {
    type: START_FETCHING_EXSAM
}

export const endFetchingExsam = {
    type: END_FETCHING_EXSAM
}
export const fetchExsam = (grade,class_id )=> async dispatch => {
    try {
        dispatch(startFetchingExsam);
        const formData = new FormData();
        formData.append('grade', grade);
        formData.append('class_id', 0 );
        const res = await axios.post(`${BASE_URL}select_exams.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200) {
            console.log("res",res);
            dispatch({
                type: FETCH_EXAMS,
                exsams: res.data
            });
        }
        dispatch(endFetchingExsam)
    } catch(e) {
        console.error(e.message);
        dispatch(endFetchingExsam);
    }
}

export const startCreatingExsam = {
    type: START_CREATING_EXSAM
}

export const endCreatingExsam = {
    type: END_CREATING_EXSAM
}

export const createExsame = data => async dispatch => {
    try {
        dispatch(startCreatingExsam);
        const formData = new FormData();
        formData.append('grade', data.grade);
        formData.append('exam_name', data.exam_name);
        formData.append('exam_mark', data.exam_mark);
        formData.append('exam_description', data.exam_description);
        formData.append('start_exam_date', data.start_exam_date);
        formData.append('end_exam_date', data.end_exam_date);
        formData.append('class_id', data.class_id);
        formData.append('date', data.date);
        formData.append('start_time', data.start_time);
        formData.append('end_time', data.end_time);
        formData.append('exam_status', data.exam_status);

        const res = await axios.post(`${BASE_URL}insert_exam.php`, formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(res);
        if(res.status === 200) {
            console.log("res2",res);
            dispatch(fetchExsam(data.grade, 0));
            dispatch(setAlert('تم الانشاء بنجاح', 'success'));
            data.exam_status == 0 && data.navigate(`/grade/${data.grade}/exsam/${res.data.id}/details/add`);
            data.exam_status == 4 && data.navigate(`/grade/${data.grade}/exsam/daily/${res.data.id}/students/${data.group_id}`)
        }
        dispatch(endCreatingExsam);
        return res;
    } catch (e) {
        console.error(e.message);
    }
}


 export const startDeletingExsam={
    type:START_DELETING_EXSAM
 }

 export const endDeletingExsam= {
    type:END_DELETING_EXSAM
 }

export const deletExsam=( class_id,grade,exam_id)=> async(dispatch)=> {
    try{
        dispatch(startDeletingExsam);
        const formData= new FormData();
        formData.append('grade',grade);
        formData.append('class_id',class_id);
        formData.append('exam_id',exam_id);
        const res = await axios.post(`${BASE_URL}delete_exam.php` ,formData,{
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        if(res.status==200 && res.data == '1'){
            dispatch(fetchExsam(grade,class_id));
            dispatch(setAlert('تم الحذف بنجاح', 'success'));
            dispatch(endDeletingExsam);
        }
    } catch (e) {
        console.error(e.message);
        dispatch(endDeletingExsam);
    }
}

export const startInsertingStudentExamDegree = {
    type: START_INSERTING_STUDENT_EXAM_DEGREE
}

export const endInsertingStudentExamDegree = {
    type: END_INSERTING_STUDENT_EXAM_DEGREE
}

export const insertStudentExamDegree = data => async dispatch => {
    try {
        dispatch(startInsertingStudentExamDegree);
        const formData = new FormData();
        formData.append('student_id', data.student_id);
        formData.append('exam_id', data.exam_id);
        formData.append('mark', data.mark);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);

        const res = await axios.post(`${BASE_URL}insert_student_mark.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم اضافة الدرجة بنجاح!', 'success'));
        }
        dispatch(endInsertingStudentExamDegree);
        return res;
    } catch (e) {
        console.error(e.message);
        dispatch(endInsertingStudentExamDegree);
        dispatch(setAlert(e.message, 'danger'));
    }
}

/////////////////////////////////////
/* STUDENTS MARKS */
////////////////////////////////////

export const startFetchingStudentsMarks = {
    type: START_FETCHING_STUDENTS_MARKS
}

export const endFetchingStudentsMarks = {
    type: END_FETCHING_STUDENTS_MARKS
}

export const fetchStudentsMarks = data => async dispatch => {
    try {
        dispatch(startFetchingStudentsMarks);
        const formData = new FormData();
        formData.append('exam_id', data.exam_id);
        formData.append('attend', 1);
        const res = await axios.post(`${BASE_URL}select_student_mark.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_STUDENTS_MARKS,
                marks: res.data
            });
        }
        dispatch(endFetchingStudentsMarks);
    } catch (e) {
        console.error(e.message);
        dispatch(endFetchingStudentsMarks);
    }
}