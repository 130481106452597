import React, {useEffect, useState} from 'react';
import './ClassExamDetails.css';
import {connect} from "react-redux";
import {
    changeClassCurrentExam,
    fetchClassExams,
    fetchClassQuestions
} from "../../../../../../../../store/actions/doors.actions";
import {Link, Outlet, useParams} from "react-router-dom";
import Header from "../../../../../../../../Components/Header/Header";
import Spinner from "../../../../../../../../Components/Spinner/Spinner";
import ClassExamQuestion from "./ClassExamQuestion/ClassExamQuestion";
import ClassExamSave from "./ClassExamSave/ClassExamSave";
import {toggleClassExamSaver} from "../../../../../../../../store/actions/ui.actions";
import {fetchExsam} from "../../../../../../../../store/actions/exsam.action";

const ClassExamDetails = ({
    fetchClassQuestions,
    fetchingClassQuestions,
    classQuestions,
    currentExam,
    changeClassCurrentExam,
    exams,
    toggleClassExamSaver,
    openClassExamSaver,
    homeClass,
    isClass,
    fetchExsam,
    fetchingExsam
}) => {
    const params = useParams();
    useEffect(() => {
        console.log(params.exam_id)
        fetchClassQuestions(params.exam_id)
    }, []);

    useEffect(() => {
        const home = document.querySelector(homeClass);
        const freezeStyles = () => {
            home.classList.add('Home__hide')
        }
        const releaseStyles = () => {
            home.classList.remove('Home__hide');
        }

        freezeStyles();

        return () => {
            releaseStyles();
        }
    }, []);

    useEffect(() => {
        exams.length > 0 && changeClassCurrentExam(exams.filter(e => e.id == params.exam_id)[0]);
    }, [exams]);

    useEffect(() => {
        console.log(exams, isClass);
        // exams.length == 0 && !isClass && !fetchingExsam && fetchExsam(params.id, 0);
        !isClass && exams.length == 0 && fetchExsam(params.id, 0);
        const data = {
            grade: params.id,
            class_id: params.class_id
        };
        if(isClass && exams.length == 0 && params.class_id) fetchClassExams(data);
    }, []);
    return (
        <div className={'ClassExamDetails'}>
            <Header text={'تفاصيل الاختبار'} />
            {
                fetchingClassQuestions ? (
                    <Spinner />
                ) : (
                    <>
                        <div className={'ClassExamDetails__list'}>
                            {
                                classQuestions.map((q, i) => (
                                    <ClassExamQuestion key={q.id} question={q} number={i + 1} />
                                ))
                            }
                        </div>
                        {
                            currentExam?.exam_status != '1' && (
                                <div className="ClassExamDetails__btns">
                                    <div className={'ClassExamDetails__btns--complete'} onClick={e => toggleClassExamSaver()}>حفظ الاسئلة</div>
                                    <Link to={isClass ? `/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/exams/${currentExam?.id && currentExam.id}/details/add` : `/grade/${params.id}/exsam/${currentExam?.id && currentExam.id}/details/add`} className={'ClassExamDetails__btns--continue'}>تكملة الاختبار</Link>
                                </div>
                            )
                        }
                    </>
                )
            }
            <ClassExamSave show={openClassExamSaver}/>
            <Outlet />
        </div>
    );
};

const mapStateToProps = state => ({
    fetchingClassQuestions: state.doors.fetchingClassQuestions,
    classQuestions: state.doors.classQuestions,
    currentExam: state.doors.currentExam,
    openClassExamSaver: state.doors.openClassExamSaver,
    fetchingExsam: state.exsams.fetchingExsam
});

export default connect(mapStateToProps, {fetchClassQuestions, changeClassCurrentExam, toggleClassExamSaver, fetchExsam}) (ClassExamDetails);