import "./exsamlist.css"
import ShowElement from "../../../../Components/ShowElement/ShowElement";
import ExsamImage from '../../../../assets/images/exam.jpg'
import ClassExam from "../../Doors/Door/Class/Class.Page/ClassExams/ClassExam/ClassExam";
import React, {useState} from "react";
import ClassExamModal from "../../Doors/Door/Class/Class.Page/ClassExams/ClassExamModal/ClassExamModal";
const ExsamList = ({element}) => {
    const [currentExam, setCurrentExam] = useState(null);
    const [modal, setModal] = useState(false);
    const [show, setShow] = useState(false);
    const renderShowElement=element.map((el)=>{
        // return< ShowElement el={el.exam_name} image={ExsamImage} ID={el.id}/>
        return <ClassExam link={`${element.id}/details`} modal={modal} setModal={setModal} currentExam={currentExam} setCurrentExam={setCurrentExam} exam={el} key={el.id} />
    })
    console.log()
    return (
        <div className='ListOfElement'>
            {renderShowElement}
            <ClassExamModal modal={modal} setModal={setModal} currentExam={currentExam} setCurrentExam={setCurrentExam} />
        </div>
    )
}

export default ExsamList;