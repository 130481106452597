import * as actionTypes from '../actions/types.actions';

const inistialState ={
    videos: [],
    fetchingVideo : false,
    creatingVideo:false,
    deletingVideo:false,
    edittingVideoList :false
}
export default (state=inistialState,action)=>{
    switch (action.type){
        case actionTypes.START_FETCHING_VIDEO:
            return {
                ...state,
                fetchingVideo: true
            }
        case actionTypes.END_FETCHING_VIDEO:
            return{
                ...state,
                fetchingVideo: false
            }
        case actionTypes.FETCH_VIDEO:
            return {
                ...state,
                videos: action.videos
            }
        case actionTypes.START_CREATING_VIDEO:
            return {
                ...state,
                creatingVideo:true
            }
        case actionTypes.END_CREATING_VIDEO:
            return {
                ...state,
                creatingVideo: false
            }
        case actionTypes.START_LIST_DELETING_VIDEO:
            return {
                ...state,
                deletingVideo: true
            }
        case actionTypes.END_LIST_DELETING_VIDEO:
            return {
                ...state,
                deletingVideo: false
            }
        case actionTypes.START_EDITING_VIDEO_LIST:
            return {
                ...state,
                edittingVideoList: true
            }
        case actionTypes.END_EDITING_VIDEO_LIST:
            return {
                ...state,
                edittingVideoList: false
            }
        default:
            return state;
    }

}