import React, {useState} from 'react';
import './ClassExamForm.css';
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {createClassExam} from "../../../../../../../../store/actions/doors.actions";

const ClassExamForm = ({createClassExam, creatingClassExam, show, setShow}) => {
    const [form, setForm] = useState({
        name: {
            value: '',
            name: 'name',
        },
        desc: {
            value: '',
            name: 'desc'
        },
        duration: {
            value: {
                hr: '',
                min: ''
            },
            name: 'duration'
        }
    });

    const min = 0;
    const maxHr = 12;
    const maxMin = 60;
    const params = useParams();
    const navigate = useNavigate();
    const formSubmitHandler = async e => {
        e.preventDefault();
        const data = {
            exam_name: form.name.value,
            exam_description: form.desc.value,
            grade: params.id,
            exam_mark: 0,
            class_id: params.class_id,
            exam_time: form.duration.value.hr * 60 + form.duration.value.min
        };
        console.log(data);
        await createClassExam(data, navigate, params.door_id);
        setForm({
            name: {
                value: '',
                name: 'name',
            },
            desc: {
                value: '',
                name: 'desc'
            },
            duration: {
                value: {
                    hr: '',
                    min: ''
                },
                name: 'duration'
            }
        });
        setShow(false);
    }
    return (
        <div className={`ClassExamForm ${show && 'ClassExamForm__show'}`}>
            <div className="ClassExamForm__container">
                <div className="ClassExamForm__header">
                    <h2>تفاصيل الاختبار</h2>
                </div>
                <form className="ClassExamForm__form">
                    <div className="ClassExamForm__form--element">
                        <label htmlFor="name">اسم الاختبار</label>
                        <input name={'name'} type="text" value={form.name.value} onChange={e => setForm({
                            ...form,
                            name: {
                                ...form.name,
                                value: e.target.value
                            }
                        })}/>
                    </div>
                    <div className="ClassExamForm__form--element">
                        <textarea value={form.desc.value} onChange={e => {
                            setForm({
                                ...form,
                                desc: {
                                    ...form.desc,
                                    value: e.target.value
                                }
                            })
                        }} name={'desc'} placeholder={'اضف وصف للاختبار'} />
                    </div>
                    <div className="ClassExamForm__form--element">
                        <label htmlFor="{'duration'}">حدد وقت الاختبار</label>
                        <div className="ClassExamForm__form--element-duration">
                            <input value={form.duration.value.hr} onChange={e => {
                                const value = Math.max(min, Math.min(maxHr, Number(e.target.value)));
                                setForm({
                                    ...form,
                                    duration: {
                                        ...form.duration,
                                        value: {
                                            ...form.duration.value,
                                            hr: value
                                        }
                                    }
                                })
                            }} type={"number"} placeholder={'hr'} name={'duration'} />
                            <input value={form.duration.value.min} onChange={e => {
                                const value = Math.max(min, Math.min(maxMin, Number(e.target.value)));
                                setForm({
                                    ...form,
                                    duration: {
                                        ...form.duration,
                                        value: {
                                            ...form.duration.value,
                                            min: value
                                        }
                                    }
                                });
                            }} type={"number"} placeholder={'min'} name={'duration'} />
                        </div>
                    </div>
                    <div className="ClassExamForm__form--btns">
                        <button onClick={e => {
                            e.preventDefault();
                            formSubmitHandler(e);

                        }} type={'submit'}>
                            {
                                creatingClassExam ? (
                                    <span><i className="fa-solid fa-circle-notch"></i></span>
                                ) : (
                                    <span>حفظ</span>
                                )
                            }
                        </button>
                        <button onClick={e => {
                            e.preventDefault();
                            setShow(false)
                        }} type={'cancel'}>الغاء</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    creatingClassExam: state.doors.creatingClassExam
});

export default connect(mapStateToProps, {createClassExam}) (ClassExamForm);