import React from "react";
import './ShowVideoList.css';
import {useSelector,useDispatch} from "react-redux";
import {deletVideoList} from "../../../../../store/actions/video.action";
import {useParams,useNavigate,useLocation,Link} from "react-router-dom";
import {editVideoList}  from "../../../../../store/actions/video.action";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const ShowVideoList = ({el,image,ID,setEditvideo,setEditID})=>{
    const location = useLocation()
    const dispatch= useDispatch()
    const params  = useParams()
    const navigate= useNavigate();
    const deletingVideo=useSelector((state)=>state.videos.deletingVideo)
    const handleNavigate=()=>{
        navigate(ID)
    }
    const handelDeletVideoList=()=>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            customClass: 'swal__wide',
            title: ' هل انتا متأكد ؟',
            text: "! انك تريد حذف قائمه الفديوهات",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '! قم بالحذف',
            cancelButtonText: 'الغاء',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletVideoList(params.id,ID))
                if (deletingVideo===false){
                    MySwal.fire({
                        title:'!تم الخذف',
                        text: '.تم حذف القائمه الخاصة بك',
                        customClass: 'swal__wide',
                        icon: 'success',
                    })
                }
            }
        })
    }
    const handleDeletItem=(e)=>{
        e.stopPropagation();
        handelDeletVideoList()
    }
    const handelEdittingVideo=(e)=>{
        e.stopPropagation();
        setEditvideo(false)
        setEditID(ID)
    }
    let divColor="";
    let textColor=''
    if (location.pathname.search('video')===9){
        divColor="ShowElement__info--container1"
        textColor="ShowElement__text1"
    }else {
        divColor="ShowElement__info--container"
        textColor="ShowElement__text"
    }

    console.log("params.id",deletingVideo)


    return(
        <div className="ShowElement">
            <div onClick={handleNavigate} className={divColor}>
                <div className='ShowElement__info--image'>
                    <img src={image} alt='ShowElement'/>
                    <h3 className={textColor}>
                        {el}
                    </h3>
                </div>
                <div onClick={handleDeletItem } className="controle__icons--ShowElement">
                    <i className="fa-solid fa-xmark ico__ShowElement--icon"></i>
                </div>
                <div onClick={handelEdittingVideo} className='controle__icons--ShowElement1'>
                    <i className="fa-solid fa-pen ico__ShowElement--icon1 "></i>
                </div>
            </div>

        </div>
    )
}
export default ShowVideoList;
