import React from 'react';
import './GroupPostsList.css';
import GroupPost from "./GroupPost/GroupPost";

const GroupPostsLilst = ({posts}) => {
    return (
        <div className={'GroupPostsList'}>
            {
                posts?.length > 0 && posts?.map((p, i) => (
                    <GroupPost post={p} key={p.id} />
                ))
            }
        </div>
    );
};

export default GroupPostsLilst;