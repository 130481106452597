import React, {useEffect, useState} from 'react';
import './ExamsDailyStudent.css';
import avatar from '../../../../../../assets/images/student.avatar.jpg';
import {useParams} from "react-router-dom";
import {setAlert} from "../../../../../../store/actions/ui.actions";
import {insertStudentExamDegree} from "../../../../../../store/actions/exsam.action";
import {connect} from "react-redux";
import exams from "../../../../../../Components/Exams/Exams";

const formateDate = date => {
    if(date.length == 1) {
        return `0${date}`;
    }
    return date;
}

const ExamsDailyStudent = ({student, exam, insertingDegree, exsams, setAlert, insertStudentExamDegree}) => {
    const now = new Date();
    const [date, setDate] = useState(`${now.getFullYear()}-${formateDate(`${now.getMonth() + 1}`)}-${formateDate(`${now.getDate()}`)}`);
    const [degree, setDegree] = useState(null);
    const [added, setAdded] = useState(false);
    const [inserting, setInserting] = useState(false);

    const params = useParams();

    const degreeInsertHandler = async e => {
        e.preventDefault();
        if(degree < 0 || degree == null) return setAlert('من فضلك ادخل درجة الطلاب!', 'danger');
        if(degree > exam?.exam_mark) return setAlert('من فضلك لا تدخل درجة اعلي من درجة الامتحان!', 'danger');
        const data = {
            student_id: student?.id,
            exam_id: params.exam_id,
            mark: degree,
            group_id: params.group_id,
            date
        };
        setInserting(true);
        const res = await insertStudentExamDegree(data);
        if(res?.status == 200 && res.data == '1') {
            setDegree(0);
            setAdded(true);
            setInserting(false);
        }
    }

    return (
        <div className={'ExamsDailyStudent'}>
            <div className="ExamsDailyStudent__left">
                <div className="ExamsDailyStudent__avatar">
                    <img src={avatar} alt={'Our Student'} />
                </div>
                <div className="ExamsDailyStudent__details">
                    <div className="ExamsDailyStudent__details--name">
                        <p>{student?.name}</p>
                    </div>
                    <div className="ExamsDailyStudent__details--phone">
                        <p>{student?.phone}</p>
                    </div>
                </div>
            </div>
            <div className="ExamsDailyStudent__right">
                <div className="ExamsDailyStudent__element">
                    <input min={1} value={degree} onChange={e => setDegree(e.target.value)} type="number" className="ExamsDailyStudent__element--input"/>
                </div>
                <div className="ExamsDailyStudent__element">
                    <button onClick={e => {
                        degreeInsertHandler(e);
                    }} className="ExamsDailyStudent__button">
                        {
                            inserting ? (
                                <span><i className="fa-solid fa-circle-notch ExamsDailyStudent__spin"></i></span>
                            ) : (
                                <span>{added ? <i className="fa-solid fa-check-double ExamsDailyStudent__check"></i> : <i className="fa-solid fa-square-plus"></i>}</span>
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    insertingDegree: state.exsams.insertingDegree,
    exams: state.exsams.exsams
})

export default connect(mapStateToProps, {insertStudentExamDegree, setAlert}) (ExamsDailyStudent);