import React, {useEffect, useState} from 'react';
import './ClassYoutube.css';
import Header from '../../../../../../../Components/Header/Header.js';
import YoutubeForm from './YoutubeForm/YoutubeForm.js';
import { fetchClassVideos, deleteVideo, editVideo } from '../../../../../../../store/actions/doors.actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../../../../Components/Spinner/Spinner';
import YoutubeVideo from './YoutubeVideo/YoutubeVideo';
import VideoPlayer from '../../../../../../../Components/Youtube/VideoPlayer/VideoPlayer';
import YoutubeVideoDeleteModal from "./YoutubeVideoDeleteModal/YoutubeVideoDeleteModal";
import YoutubeVideoEditModal from "./YoutubeVideoEditModal/YoutubeVideoEditModal";


function ClassYoutube({openVideoPlayer, homeVideo, editingVideo, editVideo, deleteVideo, deletingVideo, fetchClassVideos, fetchingClassVideos, videos}) {
	const [show, setShow] = useState(false);
	const params = useParams();
	const [deleting, setDeleting] = useState(false);
	const [deletedVideo, setDeletedVideo] = useState(null);
	const [editing, setEditing] = useState(false);
	const [editedVideo, setEditedVideo] = useState(null);
	const [textTitle, setTextTitle] = useState('');


	useEffect(() => {
		fetchClassVideos(params.class_id ? params.class_id : 0, params.video_list ? params.video_list : 0);
	}, []);

    useEffect(() => {
	    const home = document.querySelector(homeVideo);
	    const freezeStyles = () => {
	        home.classList.add('Home__hide')
	    }
	    const releaseStyles = () => {
	        home.classList.remove('Home__hide')
	    }

	    freezeStyles();

	    return () => {
	        releaseStyles();
	    }
    }, []);


	useEffect(() => {
		if(params.class_id) setTextTitle('فديوهات الفصل');
		if(params.video_list) setTextTitle('مكتبة الفديوهات');
	}, []);
	return (
		<div className='ClassYoutube'>
			<Header text={textTitle} />
			{
				fetchingClassVideos ? (
					<Spinner />
				): (
					<div className='ClassYoutube__list'>
						{
							videos.length > 0 && videos.map((v, i) => (
								<YoutubeVideo editing={editing} setEditing={setEditing} setEditedVideo={setEditedVideo} setDeleting={setDeleting} setDeletedVideo={setDeletedVideo} video={v} key={i} number={i} />
							))
						}
					</div>
				)
			}
			{
				openVideoPlayer && <VideoPlayer />
			}
			<YoutubeVideoEditModal editingVideo={editingVideo} editVideo={editVideo} editing={editing} setEditing={setEditing} editedVideo={editedVideo} setEditedVideo={setEditedVideo} />
			<YoutubeVideoDeleteModal deleteVideo={deleteVideo} deletingVideo={deletingVideo} deletedVideo={deletedVideo} setDeletedVideo={setDeletedVideo} deleting={deleting} setDeleting={setDeleting} />
			<YoutubeForm show={show} setShow={setShow} />
			<div onClick={e => setShow(true)} className='ClassYoutube__add'>
				<p>ارفع فديوهاتك</p>
				<i class="fa-solid fa-plus"></i>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	fetchingClassVideos: state.doors.fetchingClassVideos,
	videos: state.doors.videos,
	openVideoPlayer: state.ui.openVideoPlayer,
	deletingVideo: state.doors.deletingVideo,
	editingVideo: state.doors.editingVideo
})

export default connect(mapStateToProps, {fetchClassVideos, editVideo, deleteVideo}) (ClassYoutube);