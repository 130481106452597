import React from 'react';
import './Class.screen.css';
import { Link, useParams } from 'react-router-dom';
import {connect} from "react-redux";
import {deleteClassExam, deleteDoorClass} from "../../../../../store/actions/doors.actions";

function ClassScreen({clas, setDeletedClassId, setOpenEditClass, setOpenConfirm, setEditedClassId, deletingDoorClass}) {
  const params = useParams();
  return (
    <Link to={`/grade/${params.id}/doors/${params.door_id}/class/${clas.id}`} className='ClassScreen'>
        <p className='ClassScreen__name'>{clas.name}</p>
        <div className="ClassScreen__links">
            <p className='ClassScreen__lock'><i class="fa-solid fa-unlock-keyhole"></i></p>
            <p className="ClassScreen__edit" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setOpenEditClass(true);
                setEditedClassId(clas.id);
            }}>
                <i className="fa-solid fa-pen"></i>
            </p>
            <p className="ClassScreen__delete" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeletedClassId(clas.id);
                setOpenConfirm(true);
            }}>
                <i className="fa-solid fa-trash"></i>
            </p>
        </div>
    </Link>
  )
}

const mapStateToProps = state => ({
    deletingDoorClass: state.doors.deletingDoorClass
})

export default connect(mapStateToProps, {deleteDoorClass}) (ClassScreen);