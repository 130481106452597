export const START_LOGGING_USER_IN = "START_LOGGING_USER_IN";
export const END_LOGGING_USER_IN = "END_LOGGING_USER_IN";
export const FETCHING_TEACHER_INFO = "FETCHING_TEACHER_INFO";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const RETURN_USER_ERROR = "RETURN_USER_ERROR";
export const LOAD_USER_START = "LOAD_USER_START";
export const LOAD_USER_END = "LOAD_USER_END";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const FETCH_GRADE_DOORS = 'FETCH_GRADE_DOORS';
export const START_FETCHING_GRADE_DOORS = 'START_FETCHING_GRADE_DOORS';
export const END_FETCHING_GRADE_DOORS = 'END_FETCHING_GRADE_DOORS';
export const START_CREATING_DOOR = 'START_CREATING_DOOR';
export const END_CREATING_DOOR = 'END_CREATING_DOOR';
export const CREATE_DOOR = 'CREATE_DOOR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const START_FETCHING_DOOR_CLASSES = 'START_FETCHING_DOOR_CLASSES';
export const END_FETCHING_DOOR_CLASSESS = 'END_FETCHING_DOOR_CLASSESS';
export const FETCH_DOOR_CLASSES = 'FETCH_DOOR_CLASSES';
export const CREATE_CLASS = 'CREATE_CLASS';
export const START_CREATING_CLASS = 'START_CREATING_CLASS';
export const END_CREATING_CLASS = 'END_CREATING_CLASS';
export const START_CREATING_CLASS_VIDEO = 'START_CREATING_CLASS_VIDEO';
export const END_CREATING_CLASS_VIDEO = 'END_CREATING_CLASS_VIDEO';
export const CREATE_CLASS_VIDEO = 'CREATE_CLASS_VIDEO';
export const START_FETCHING_CLASS_VIDEOS = 'START_FETCHING_CLASS_VIDEOS';
export const END_FETCHING_CLASS_VIDEOS = 'END_FETCHING_CLASS_VIDEOS';
export const FETCHING_CLASS_VIDEOS = 'FETCHING_CLASS_VIDEOS';
export const CHANGE_CURRENT_VIDEO_URL = 'CHANGE_CURRENT_VIDEO_URL';
export const TOGGLE_VIDEO_PLAYER = 'TOGGLE_VIDEO_PLAYER';
export const START_FETCHING_CLASS_PDFS = 'START_FETCHING_CLASS_PDFS';
export const END_FETCHING_CLASS_PDFS = 'END_FETCHING_CLASS_PDFS';
export const FETCH_CLASS_PDFS = 'FETCH_CLASS_PDFS';
export const START_UPLOADING_CLASS_PDF = 'START_UPLOADING_CLASS_PDF';
export const END_UPLOADING_CLASS_PDF = "END_UPLOADING_CLASS_PDF";
export const UPLOAD_CLASS_PDF = "UPLOAD_CLASS_PDF";
export const START_FETCHING_CLASS_EXAMS = 'START_FETCHING_CLASS_EXAMS';
export const END_FETCHING_CLASS_EXAMS = 'END_FETCHING_CLASS_EXAMS';
export const FETCH_CLASS_EXAMS = 'FETCH_CLASS_EXAMS';
export const START_CREATING_CLASS_EXAM = 'START_CREATING_CLASS_EXAM';
export const END_CREATING_CLASS_EXAM = 'END_CREATING_CLASS_EXAM';
export const CREATE_CLASS_EXAM = 'CREATE_CLASS_EXAM';
export const START_DELETING_CLASS_EXAM = 'START_DELETING_CLASS_EXAM';
export const END_DELETING_CLASS_EXAM = 'END_DELETING_CLASS_EXAM';
export const DELETE_CLASS_EXAM = 'DELETE_CLASS_EXAM';
export const START_FETCHING_CLASS_QUESTIONS = 'START_FETCHING_CLASS_QUESTIONS';
export const END_FETCHING_CLASS_QUESTIONS = 'END_FETCHING_CLASS_QUESTIONS';
export const FETCH_CLASS_QUESTIONS = 'FETCH_CLASS_QUESTIONS';
export const CHANGE_CLASS_CURRENT_EXAM = 'CHANGE_CLASS_CURRENT_EXAM';
export const START_CREATING_CLASS_EXAM_QUESTION = 'START_CREATING_CLASS_EXAM_QUESTION';
export const END_CREATING_CLASS_EXAM_QUESTION = 'END_CREATING_CLASS_EXAM_QUESTION';
export const CREATE_CLASS_EXAM_QUESTION = 'CREATE_CLASS_EXAM_QUESTION';
export const START_UPDATING_CLASS_EXAM = 'START_UPDATING_CLASS_EXAM';
export const END_UPDATING_CLASS_EXAM = 'END_UPDATING_CLASS_EXAM';
export const UPDATE_CLASS_EXAM = 'UPDATE_CLASS_EXAM';
export const TOGGLE_CLASS_EXAM_SAVER = 'TOGGLE_CLASS_EXAM_SAVER';
export const START_DELETING_DOOR_CLASS = 'START_DELETING_DOOR_CLASS';
export const END_DELETING_DOOR_CLASS = 'END_DELETING_DOOR_CLASS';
export const START_EDITING_DOOR_CLASS = "START_EDITING_DOOR_CLASS";
export const END_EDITING_DOOR_CLASS = "END_EDITING_DOOR_CLASS";
export const START_DELETING_DOOR = "START_DELETING_DOOR";
export const END_DELETING_DOOR = "END_DELETING_DOOR";
export const DELETE_DOOR = "DELETE_DOOR";
export const START_EDITING_DOOR = "START_EDITING_DOOR";
export const END_EDITING_DOOR = "END_EDITING_DOOR";
export const EDIT_DOOR = "EDIT_DOOR";
export const START_DELETING_VIDEO = "START_DELETING_VIDEO";
export const END_DELETING_VIDEO = "END_DELETING_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const START_EDITING_VIDEO = "START_EDITING_VIDEO";
export const END_EDITING_VIDEO = "END_EDITING_VIDEO";
export const EDIT_VIDEO = "EDIT_VIDEO";
export const START_DELETING_PDF = "START_DELETING_PDF";
export const END_DELETING_PDF =  "END_DELETING_PDF";
export const DELETE_PDF = "DELETE_PDF";
export const START_EDITING_PDF = "START_EDITING_PDF";
export const END_EDITING_PDF = "END_EDITING_PDF";
export const EDIT_PDF = "EDIT_PDF";
export const START_FETCHING_GRADE_GROUPS = "START_FETCHING_GRADE_GROUPS";
export const END_FETCHING_GRADE_GROUPS = "END_FETCHING_GRADE_GROUPS";
export const FETCH_GRADE_GROUPS = "FETCH_GRADE_GROUPS";
export const START_DELETING_GRADE_GROUP = "START_DELETING_GRADE_GROUP";
export const END_DELETING_GRADE_GROUP = "END_DELETING_GRADE_GROUP";
export const DELETE_GRADE_GROUP = "DELETE_GRADE_GROUP";
export const START_CREATING_GROUP = "START_CREATING_GROUP";
export const END_CREATING_GROUP = "END_CREATING_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const START_ADDING_STUDENT_TO_GROUP = "START_ADDING_STUDENT_TO_GROUP";
export const END_ADDING_STUDENT_TO_GROUP = "END_ADDING_STUDENT_TO_GROUP";
export const ADD_STUDENT_TO_GROUP = "ADD_STUDENT_TO_GROUP";
export const START_ADDING_STUDENT_TO_GRADE = "START_ADDING_STUDENT_TO_GRADE";
export const END_ADDING_STUDENT_TO_GRADE = "END_ADDING_STUDENT_TO_GRADE";
export const ADD_STUDENT_TO_GRADE = "ADD_STUDENT_TO_GRADE";
export const START_FETCHING_STUDENTS_TO_ADD = "START_FETCHING_STUDENTS_TO_ADD";
export const END_FETCHING_STUDENTS_TO_ADD = "END_FETCHING_STUDENTS_TO_ADD";
export const FETCH_STUDENTS_TO_ADD = "FETCH_STUDENTS_TO_ADD";
export const START_FETCHING_THE_GROUP_COUNT = "START_FETCHING_THE_GROUP_COUNT";
export const END_FETCHING_THE_GROUP_COUNT = "END_FETCHING_THE_GROUP_COUNT";
export const FETCH_THE_GROUP_COUNT = "FETCH_THE_GROUP_COUNT";
export const START_FETCHING_ABSENCE_STUDENTS = "START_FETCHING_ABSENCE_STUDENTS";
export const END_FETCHING_ABSENCE_STUDENTS = "END_FETCHING_ABSENCE_STUDENTS";
export const FETCH_ABSENCE_STUDENTS = "FETCH_ABSENCE_STUDENTS";
export const START_ADDING_STUDENT_TO_ABSENCE = "START_ADDING_STUDENT_TO_ABSENCE";
export const END_ADDING_STUDENT_TO_ABSENCE = "END_ADDING_STUDENT_TO_ABSENCE";
export const ADD_STUDENT_TO_ABSENCE = "ADD_STUDENT_TO_ABSENCE";
export const START_FETCHING_STUDENTS_EXPENSES = "START_FETCHING_STUDENTS_EXPENSES";
export const END_FETCHING_STUDENTS_EXPENSES = "END_FETCHING_STUDENTS_EXPENSES";
export const FETCH_STUDENTS_EXPENSES = "FETCH_STUDENTS_EXPENSES";
export const START_ADDING_STUDENT_TO_EXPENSES = "START_ADDING_STUDENT_TO_EXPENSES";
export const END_ADDING_STUDENT_TO_EXPENSES = "END_ADDING_STUDENT_TO_EXPENSES";
export const ADD_STUDENT_TO_EXPENSES = "ADD_STUDENT_TO_EXPENSES";
export const START_ADDING_ALL_STUDENTS_TO_EXPENSES = "START_ADDING_ALL_STUDENTS_TO_EXPENSES";
export const END_ADDING_ALL_STUDENTS_TO_EXPENSES = "END_ADDING_ALL_STUDENTS_TO_EXPENSES";
export const ADD_ALL_STUDENTS_TO_EXPENSES = "ADD_ALL_STUDENTS_TO_EXPENSES";
export const IMPORT_ALL_STUDENTS_DATA = 'IMPORT_ALL_STUDENTS_DATA';
export const START_IMPORTING_ALL_STUDENTS_DATA = 'START_IMPORTING_ALL_STUDENTS_DATA';
export const END_IMPORTING_ALL_STUDENTS_DATA = 'END_IMPORTING_ALL_STUDENTS_DATA';
export const START_FETCHING_GROUP_POSTS = 'START_FETCHING_GROUP_POSTS';
export const END_FETCHING_GROUP_POSTS = 'END_FETCHING_GROUP_POSTS';
export const FETCH_GROUP_POSTS = "FETCH_GROUP_POSTS";
export const RESET_GROUP_POSTS = "RESET_GROUP_POSTS";
export const START_ADDING_GROUP_POST = "START_ADDING_GROUP_POST";
export const END_ADDING_GROUP_POST = "END_ADDING_GROUP_POST";
export const ADD_GROUP_POST = "ADD_GROUP_POST";
export const START_LOADING_MORE = 'START_LOADING_MORE';
export const END_LOADING_MORE = 'END_LOADING_MORE';
export const START_LOADING_THE_ADDED_POST = "START_LOADING_THE_ADDED_POST";
export const END_LOADING_THE_ADDED_POST = "END_LOADING_THE_ADDED_POST";
export const START_FETCHING_EXSAM="START_FETCHING_EXSAM"
export const END_FETCHING_EXSAM="END_FETCHING_EXSAM"
export const FETCH_EXAMS = 'FETCH_EXSAMS';
export const START_CREATING_EXSAM="START_CREATING_EXSAM";
export const END_CREATING_EXSAM="END_CREATING_EXSAM";
export const START_DELETING_EXSAM="START_DELETING_EXSAM";
export const END_DELETING_EXSAM="END_DELETING_EXSAM";
export const END_INSERTING_STUDENT_EXAM_DEGREE = "END_INSERTING_STUDENT_EXAM_DEGREE";
export const START_INSERTING_STUDENT_EXAM_DEGREE = "START_INSERTING_STUDENT_EXAM_DEGREE";
export const INSERT_STUDENT_EXAM_DEGREE = "INSERT_STUDENT_EXAM_DEGREE";
export const START_FETCHING_VIDEO='START_FETCHING_VIDEO';
export const END_FETCHING_VIDEO='END_FETCHING_VIDEO';
export const FETCH_VIDEO='FETCHING_VIDEO'
export const START_CREATING_VIDEO='START_CREATING_VIDEO';
export const END_CREATING_VIDEO= 'END_CREATING_VIDEO';
export const START_LIST_DELETING_VIDEO="START_DELETING_VIDEO";
export const END_LIST_DELETING_VIDEO="END_DELETING_VIDEO";
export const START_EDITING_VIDEO_LIST='START_EDITING_VIDEO_LIST'
export const END_EDITING_VIDEO_LIST='END_EDITING_VIDEO_LIST';
export const START_FETCHING_STUDENTS_MARKS = "START_FETCHING_STUDENTS_MARKS";
export const END_FETCHING_STUDENTS_MARKS = "END_FETCHING_STUDENTS_MARKS";
export const FETCH_STUDENTS_MARKS = "FETCH_STUDENTS_MARKS";
export const START_FETCHING_QUESTION_BANK = "START_FETCHING_QUESTION_BANK";
export const END_FETCHING_QUESTION_BANK = "END_FETCHING_QUESTION_BANK";
export const FETCH_QUESTION_BANK = "FETCH_QUESTION_BANK";
export const START_LOGGING_USER_OUT = "START_LOGGING_USER_OUT";
export const END_LOGGING_USER_OUT = "END_LOGGING_USER_OUT";
export const LOG_USER_OUT = "LOG_USER_OUT";
export const START_UPDATING_EXAM_STATUS = "START_UPDATING_EXAM_STATUS";
export const END_UPDATING_EXAM_STATUS = "END_UPDATING_EXAM_STATUS";
export const UPDATE_EXAM_STATUS = "UPDATE_EXAM_STATUS";
export const UPDATE_CLASS_EXAM_STATUS = "UPDATE_CLASS_EXAM_STATUS";
export const FETCH_GRADE_STUDENTS_QUESTIONS = "FETCH_GRADE_STUDENTS_QUESTIONS";
export const START_GRADE_STUDENTS_QUESTIONS = "START_GRADE_STUDENTS_QUESTIONS";
export const END_GRADE_STUDENTS_QUESTIONS = "END_GRADE_STUDENTS_QUESTIONS";
export const CHANGE_GRADE_ID = "CHANGE_GRADE_ID";
export const START_FETCHING_WALLET = "START_FETCHING_WALLET";
export const END_FETCHING_WALLET = "END_FETCHING_WALLET";
export const FETCH_WALLET = "FETCH_WALLET";
export const START_FETCHING_TEACHER_ASSISTANTS = "START_FETCHING_TEACHER_ASSISTANTS";
export const END_FETCHING_TEACHER_ASSISTANTS = "END_FETCHING_TEACHER_ASSISTANTS";
export const FETCH_TEACHER_ASSISTANTS = "FETCH_TEACHER_ASSISTANTS";
export const START_ADDING_TEACHER = "START_ADDING_TEACHER";
export const END_ADDING_TEACHER = "END_ADDING_TEACHER";
export const ADD_TEACHER = "ADD_TEACHER";
export const START_FETCHING_GRADE_STUDENTS = "START_FETCHING_GRADE_STUDENTS";
export const END_FETCHING_GRADE_STUDENTS = "END_FETCHING_GRADE_STUDENTS";
export const FETCH_GRADE_STUDENTS = "FETCH_GRADE_STUDENTS";
export const START_EXCEPTING_STUDENT = "START_EXCEPTING_STUDENT";
export const END_EXCEPTING_STUDENT = "END_EXCEPTING_STUDENT";
export const EXCEPT_STUDENT = "EXCEPT_STUDENT";