import React, {useEffect, useState} from 'react'
import "./Grade.css"
import gradeImage1 from "../../assets/images/student1.jpg"
import gradeImage2 from "../../assets/images/student2.avif";
import gradeImage3 from "../../assets/images/third.png";
import Group from "../../assets/images/group.avif"
import Exsame from "../../assets/images/exsame.webp"
import Video from "../../assets/images/video.jpg"
import PDF from "../../assets/images/pdf.png"
import Question from "../../assets/images/question.avif"
import Chapter from "../../assets/images/chapter.avif";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";

const Grade = () => {
    const [pathLength, setPathLength] = useState(null);
    const history = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    
    useEffect(() => {
        setPathLength(history.pathname.split('/').length > 3);
    }, [history]);

    const images = {
        gradeImage1,
        gradeImage2,
        gradeImage3
    };

    return (
        <div className='Grade'>
            <div className='Grade__container'>
                <div className='Grade__right'>
                    <div className='Grade__right--container'>
                        <div className="Grade__right--container-flex">
                            <div onClick={e => navigate(`/grade/${params.id}/groups`)} className='Grade__right--group'>
                                <img  className="Grade__right--group-image" src={Group}/>
                                <h3 className='Grade__right--group-text'>مجموعاتي</h3>
                            </div>
                            <div onClick={e => navigate(`/grade/${params.id}/exsam`)} className='Grade__right--group'>
                                <img className='Grade__right--group-image' src={Exsame}/>
                                <h3 className='Grade__right--group-text'>امتحاناتي</h3>
                            </div>
                            <div onClick={e => navigate(`/grade/${params.id}/video`)} className='Grade__right--group'>
                                <img className='Grade__right--group-image' src={Video}/>
                                <h3 className='Grade__right--group-text'>مكتبة فيديوهاتي</h3>
                            </div>
                            <div onClick={e => navigate(`/grade/${params.id}/pdfs`)} className='Grade__right--group'>
                                <img className='Grade__right--group-image' src={PDF}/>
                                <h3 className='Grade__right--group-text'>PDF رفع</h3>
                            </div>
                            <div onClick={e => navigate(`/grade/${params.id}/sq`)} className='Grade__right--group'>
                                <img className='Grade__right--group-image' src={Question}/>
                                <h3 className='Grade__right--group-text'>اسئلة الطلبة</h3>
                            </div>
                            <div onClick={e => navigate(`/grade/${params.id}/doors`)} className='Grade__right--group'>
                                <img className='Grade__right--group-image' src={Chapter}/>
                                <h3 className='Grade__right--group-text'>الابواب</h3>
                            </div>
                        </div>

                    </div>
                </div>
                {/* ************************************** */}
                <div className={`Grade__left ${pathLength && 'Grade__left--full'}`}>
                    {
                        !pathLength && <img className='Grade__left--image' src={images[`gradeImage${params.id}`]}/>
                    }
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Grade;