import React, {useEffect, useState} from 'react';
import './TeacherAssistant.css';
import Header from "../../../Components/Header/Header";
import {fetchTeacherAssistants} from "../../../store/actions/Settings.actions";
import {connect} from "react-redux";
import Spinner from "../../../Components/Spinner/Spinner";
import Assistant from "./Assistant/Assistant";
import AssistantForm from "./AssistantForm/AssistantForm";
import {Outlet} from "react-router-dom";

const TeacherAssistant = ({fetchTeacherAssistants, fetchingTeachers, teachers}) => {
    const [adding, setAdding] = useState(false);
    useEffect(() => {
        fetchTeacherAssistants();
    }, []);
    return (
        <div className={'TeacherAssistant'}>
            <Header text={'المساعدين'} />
            {
                fetchingTeachers ? (
                    <Spinner />
                ) : (
                    <div className={'TeacherAssistant__assistants'}>{
                        teachers?.map((teacher, i) => (
                            <Assistant teacher={teacher} key={i} />
                        ))
                    }</div>
                )
            }
            <div onClick={e => setAdding(true)} className="TeacherAssistant__btn">
                <p><span>اضف مساعد</span><span><i className="fa-solid fa-plus"></i></span></p>
            </div>
            {adding && (
                <>
                    <AssistantForm adding={adding} setAdding={setAdding} />
                    <div onClick={e => setAdding(false)} className={'Assistant__backdrop'}>
                        <div className="TeacherAssistant__backdrop--close"><i className="fa-solid fa-xmark"></i></div>
                    </div>
                </>
            )}
            <Outlet />
        </div>
    );
};

const mapStateToProps = state => ({
    fetchingTeachers: state.settings.fetchingTeachers,
    teachers: state.settings.teachers
})

export default connect(mapStateToProps, {fetchTeacherAssistants}) (TeacherAssistant);