import React from 'react';
import './ClassBankQuestionAnswer.css';

const ClassBankQuestionAnswer = ({answ, rightAnsw}) => {
    return (
        <div style={{color: `${rightAnsw && 'green'}`}} className={'ClassBankQuestionAnswer'}>
            <p dangerouslySetInnerHTML={{__html: answ}}></p>
        </div>
    );
};

export default ClassBankQuestionAnswer;