import { act } from 'react-dom/test-utils';
import * as actionTypes from '../actions/types.actions';
import exams from "../../Components/Exams/Exams";

const initialState = {
    doors: [],
    fetchingDoors: true,
    creatingDoor: false,
    fetchingClasses: true,
    classes: [],
    creatingClass: false,
    creatingClassVideo: false,
    videos: [],
    fetchingClassVideos: false,
    fetchingClassPdfs: false,
    pdfs: [],
    uploadingClassPdf: false,
    fetchingClassExams: false,
    exams: [],
    creatingClassExam: false,
    deletingClassExam: false,
    fetchingClassQuestions: false,
    classQuestions: [],
    currentExam: null,
    creatingClassQuestion: false,
    updatingClassExam: false,
    deletingDoorClass: false,
    editingDoorClass: false,
    deletingDoor: false,
    editingDoor: false,
    deletingVideo: false,
    editingVideo: false,
    deletingPdf: false,
    updatingStatus: false

};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.START_FETCHING_GRADE_DOORS:
            return {
                ...state,
                fetchingDoors: true
            }
        case actionTypes.END_FETCHING_GRADE_DOORS:
            return {
                ...state,
                fetchingDoors: false
            }
        case actionTypes.FETCH_GRADE_DOORS:
            return {
                ...state,
                doors: action.doors
            }
        case actionTypes.START_CREATING_DOOR:
            return {
                ...state,
                creatingDoor: true
            }
        case actionTypes.END_CREATING_DOOR:
            return {
                ...state,
                creatingDoor: false
            }
        case actionTypes.START_FETCHING_DOOR_CLASSES:
            return {
                ...state,
                fetchingClasses: true
            }
        case actionTypes.END_FETCHING_DOOR_CLASSESS:
            return {
                ...state,
                fetchingClasses: false
            }
        case actionTypes.FETCH_DOOR_CLASSES:
            return {
                ...state,
                classes: action.classes
            }
        case actionTypes.START_CREATING_CLASS:
            return {
                ...state,
                creatingClass: true
            }
        case actionTypes.END_CREATING_CLASS:
            return {
                ...state,
                creatingClass: false
            }
        case actionTypes.START_CREATING_CLASS_VIDEO:
            return {
                ...state,
                creatingClassVideo: true
            }
        case actionTypes.END_CREATING_CLASS_VIDEO:
            return {
                ...state,
                creatingClassVideo: false
            }
        case actionTypes.START_FETCHING_CLASS_VIDEOS:
            return {
                ...state,
                fetchingClassVideos: true
            }
        case actionTypes.END_FETCHING_CLASS_VIDEOS:
            return {
                ...state,
                fetchingClassVideos: false
            }
        case actionTypes.FETCHING_CLASS_VIDEOS:
            return {
                ...state,
                videos: [...action.videos]
            }
        case actionTypes.START_FETCHING_CLASS_PDFS:
            return {
                ...state,
                fetchingClassPdfs: true
            }
        case actionTypes.END_FETCHING_CLASS_PDFS:
            return {
                ...state,
                fetchingClassPdfs: false
            }
        case actionTypes.FETCH_CLASS_PDFS:
            return {
                ...state,
                pdfs: action.pdfs
            }
        case actionTypes.START_UPLOADING_CLASS_PDF:
            return {
                ...state,
                uploadingClassPdf: true
            }
        case actionTypes.END_UPLOADING_CLASS_PDF:
            return {
                ...state,
                uploadingClassPdf: false
            }
        case actionTypes.START_FETCHING_CLASS_EXAMS:
            return {
                ...state,
                fetchingClassExams: true
            }
        case actionTypes.END_FETCHING_CLASS_EXAMS:
            return {
                ...state,
                fetchingClassExams: false
            }
        case actionTypes.FETCH_CLASS_EXAMS:
            return {
                ...state,
                exams: action.exams
            }
        case actionTypes.START_CREATING_CLASS_EXAM:
            return {
                ...state,
                creatingClassExam: true
            }
        case actionTypes.END_CREATING_CLASS_EXAM:
            return {
                ...state,
                creatingClassExam: false
            }
        case actionTypes.CREATE_CLASS_EXAM:
            return {
                ...state,
                exams: [action.newExam, ...state.exams]
            }
        case actionTypes.START_DELETING_CLASS_EXAM:
            return {
                ...state,
                deletingClassExam: true
            }
        case actionTypes.END_DELETING_CLASS_EXAM:
            return {
                ...state,
                deletingClassExam: false
            }
        case actionTypes.START_FETCHING_CLASS_QUESTIONS:
            return {
                ...state,
                fetchingClassQuestions: true
            }
        case actionTypes.END_FETCHING_CLASS_QUESTIONS:
            return {
                ...state,
                fetchingClassQuestions: false
            }
        case actionTypes.FETCH_CLASS_QUESTIONS:
            return {
                ...state,
                classQuestions: action.questions
            }
        case actionTypes.CHANGE_CLASS_CURRENT_EXAM:
            return {
                ...state,
                currentExam: action.exam
            }
        case actionTypes.START_CREATING_CLASS_EXAM_QUESTION:
            return {
                ...state,
                creatingClassQuestion: true
            }
        case actionTypes.END_CREATING_CLASS_EXAM_QUESTION:
            return {
                ...state,
                creatingClassQuestion: false
            }
        case actionTypes.CREATE_CLASS_EXAM_QUESTION:
            return {
                ...state,
                classQuestions: [...state.classQuestions, action.question]
            }
        case actionTypes.START_UPDATING_CLASS_EXAM:
            return {
                ...state,
                updatingClassExam: true
            }
        case actionTypes.END_UPDATING_CLASS_EXAM:
            return {
                ...state,
                updatingClassExam: false
            }
        case actionTypes.UPDATE_CLASS_EXAM:
            return (() => {
                let updatedClassExamIndex = state.exams.findIndex(e => e.id == action.updatedExam.id);
                console.log(updatedClassExamIndex);
                state.exams[updatedClassExamIndex] = action.updatedExam;
                return {
                    ...state,
                    exams: [...JSON.parse(JSON.stringify(state.exams))],
                    currentExam: action.updatedExam
                }
            })();
        case actionTypes.START_DELETING_DOOR_CLASS:
            return {
                ...state,
                deletingDoorClass: true
            }
        case actionTypes.END_DELETING_DOOR_CLASS:
            return {
                ...state,
                deletingDoorClass: false
            }
        case actionTypes.START_EDITING_DOOR_CLASS:
            return {
                ...state,
                editingDoorClass: true
            }
        case actionTypes.END_EDITING_DOOR_CLASS:
            return {
                ...state,
                editingDoorClass: false
            }
        case actionTypes.START_DELETING_DOOR:
            return {
                ...state,
                deletingDoor: true
            }
        case actionTypes.END_DELETING_DOOR:
            return {
                ...state,
                deletingDoor: false
            }
        case actionTypes.START_EDITING_DOOR:
            return {
                ...state,
                editingDoor: true
            }
        case actionTypes.END_EDITING_DOOR:
            return {
                ...state,
                editingDoor: false
            }
        case actionTypes.START_DELETING_VIDEO:
            return {
                ...state,
                deletingVideo: true
            }
        case actionTypes.END_DELETING_VIDEO:
            return {
                ...state,
                deletingVideo: false
            }
        case actionTypes.START_EDITING_VIDEO:
            return {
                ...state,
                editingVideo: true
            }
        case actionTypes.END_EDITING_VIDEO:
            return {
                ...state,
                editingVideo: false
            }
        case actionTypes.START_DELETING_PDF:
            return {
                ...state,
                deletingPdf: true
            }
        case actionTypes.END_DELETING_PDF:
            return {
                ...state,
                deletingPdf: false
            }
        case actionTypes.START_UPDATING_EXAM_STATUS:
            return {
                ...state,
                updatingStatus: true
            }
        case actionTypes.END_UPDATING_EXAM_STATUS:
            return {
                ...state,
                updatingStatus: false
            }
        default:
            return state;
    }
}