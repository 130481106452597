import React from 'react'
import './ClassPdf.css';
import { Link, useParams } from 'react-router-dom';

function ClassPdf({pdf, number, setDeletedPdf, key, deleting, setDeleting, setEditedPdf, setEditing, editing}) {
  const params = useParams();
  return (
    <Link to={`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/pdfs/${pdf.id}`} className='ClassPdf'>
        <p className='ClassPdf__name'>{pdf.pdf_name}</p>
        <span className='ClassPdf__num'>{number}</span>
        <div className="ClassPdf__left">
            <div onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeletedPdf(pdf);
                setDeleting(true);
            }} className="ClassPdf__delete"><i className="fa-solid fa-trash"></i></div>
            <p className='ClassPdf__icon'><i className="fa-solid fa-file-pdf"></i></p>
        </div>
    </Link>
  )
}

export default ClassPdf;