import React, {useState} from 'react';
import './GroupDayPicker.css';

const GroupDayPicker = ({date, setDate, expenses}) => {
    return (
        <div className={'GroupDayPicker'}>
            <p>{date}</p>
            <input type="date" value={date} defaultValue={date} defaultChecked={true} onChange={e => {
                expenses ? setDate(`${new Date(e.target.value).getMonth() + 1}`.length < 2 ? `${new Date(e.target.value).getFullYear()}-0${new Date(e.target.value).getMonth() + 1}` : `${new Date(e.target.value).getFullYear()}-${new Date(e.target.value).getMonth() + 1}`)  : setDate(new Date(e.target.value).toLocaleDateString('en-ZA'))
            }}/>
        </div>
    );
};

export default GroupDayPicker;