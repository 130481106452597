import React, {useState} from 'react';
import './StudentAddForm.css';
import {connect} from "react-redux";
import {addStudentToGrade} from "../../../../../../store/actions/groups.actions";
import {setAlert} from "../../../../../../store/actions/ui.actions";
import {useParams} from "react-router-dom";

const StudentAddForm = ({openForm, setOpenForm, addingStudentToGrade, addStudentToGrade, setAlert}) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const params = useParams();
    const formSubmitHandler = async e => {
        e.preventDefault();
        if(!name.length || !phone.length || !email.length || !password.length) return setAlert('من فضلك اكمل كل المعلومات المطلوبة', 'danger');
        const data = {
            name,
            password,
            phone,
            email,
            grade: params.id,
            group_id: params.group_id
        }
        const res = await addStudentToGrade(data);
        console.log(res);
        if(res.data == '1') {
            setOpenForm(false);
            setName('');
            setPassword('');
            setPhone('');
            setEmail('');
        }
    }

    return (
        <div className={`StudentAddForm ${openForm && 'StudentAddForm__open'}`}>
            <form onSubmit={formSubmitHandler} action="" className="StudentAddForm__form">
                <div className="StudentAddForm__form--element">
                    <label htmlFor="" className="StudentAddForm__form--label">الاسم</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" className="StudentAddForm__form--input" />
                </div>
                <div className="StudentAddForm__form--element">
                    <label htmlFor="" className="StudentAddForm__form--label">رقم الهاتف</label>
                    <input value={phone} onChange={e => setPhone(e.target.value)} type="tel" className="StudentAddForm__form--input" />
                </div>
                <div className="StudentAddForm__form--element">
                    <label htmlFor="" className="StudentAddForm__form--label">البريد الالكتروني</label>
                    <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="StudentAddForm__form--input" />
                </div>
                <div className="StudentAddForm__form--element">
                    <label htmlFor="" className="StudentAddForm__form--label">كلمة المرور</label>
                    <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="StudentAddForm__form--input" />
                </div>
                <div className="StudentAddForm__form--btn">
                    <button type={'submit'} >
                        {
                            addingStudentToGrade ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>اضافة الطالب</span>
                            )
                        }
                    </button>
                    <button onClick={e => setOpenForm(false)} type={'reset'}>الغاء</button>
                </div>
            </form>
            <div className="StudentAddForm__backdrop" onClick={e => setOpenForm(false)}></div>
        </div>
    );
};

const mapStateToProps = state => ({
    addingStudentToGrade: state.groups.addingStudentToGrade
})

export default connect(mapStateToProps, {addStudentToGrade, setAlert}) (StudentAddForm);