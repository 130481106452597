import React, {useEffect, useState} from 'react';
import './GroupStudentsData.css';
import Header from "../../../../../Components/Header/Header";
import {connect} from "react-redux";
import {importAllStudentsData} from "../../../../../store/actions/groups.actions";
import {useParams} from "react-router-dom";
import Spinner from "../../../../../Components/Spinner/Spinner";
import * as XLSX from 'xlsx';
import student from "../GroupAddStudent/Student/Student";
import StudentSearch from "../GroupAddStudent/StudentSearch/StudentSearch";
import GroupDayPicker from "../GroupAbsence/GroupDayPicker/GroupDayPicker";
import GroupAttend from "../GroupAbsence/GroupAttend/GroupAttend";
import Student from "../GroupAddStudent/Student/Student";

const GroupsStudentsData = ({importAllStudentsData, currentGroupCount, importingStudentsData, students}) => {
    const [searchResults, setSearchResults] = useState([]);
    const [term, setTerm] = useState('');
    const params = useParams();
    useEffect(() => {
        const data = {
            grade: params.id,
            group_id: params.group_id
        }
        importAllStudentsData(data);
    }, []);

    const handleSearch = (value) => {
        let studentsMatch = [];
        if(!isNaN(value)) {
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.phone.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch);
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.phone.toLowerCase().split('').indexOf(firstLetter[0]) - b.phone.toLowerCase().split('').indexOf(firstLetter[0]));

        } else {
            console.log(value);
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.name?.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch)
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.name.toLowerCase().split('').indexOf(firstLetter[0]) - b.name.toLowerCase().split('').indexOf(firstLetter[0]));
        }

        setSearchResults(studentsMatch);
    }

    useEffect(() => {
        handleSearch(term);
    }, [students]);

    const inputChangeHandler = e => {
        setTerm(e.target.value);
        let value = e.target.value;
        if(!value || value.trim().length == 0) setSearchResults(students);
        handleSearch(value);
    }

    const downloadExcel = data => {
        const wantedData = data.map((d, i) => {
            return {
                id: d.id,
                name: d.name,
                phone: d.phone,
                email: d.email,
                grade: d.grade
            }
        });
        const worksheet = XLSX.utils.json_to_sheet(wantedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    }

    // useEffect(() => {
    //     if(students.length === 0) return;
    //     downloadExcel(students)
    // }, [students]);
    return (
        <div className={'GroupStudentsData'}>
            <Header text={'انشاء ملف اكسل'} />
            {
                importingStudentsData ? (
                    <Spinner />
                ) : (
                    <div className={'GroupStudentsData__body'}>
                        <div className="GroupAbsence__header">
                            <StudentSearch term={term} inputChangeHandler={inputChangeHandler} />
                        </div>
                        <div className={'GroupAddStudent__students'}>
                            {
                                searchResults?.map((s, i) => (
                                    <Student student={s} attend={true} key={i} currentGroupCount={currentGroupCount} />
                                ))
                            }
                        </div>
                        <button className={'GroupStudentsData__download'} onClick={e => {
                            e.preventDefault();
                            if(students.length == 0) return;
                            downloadExcel(students);
                        }}>تحميل و مشاركة ملف الاكسل</button>
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    importingStudentsData: state.groups.importingStudentsData,
    students: state.groups.studentsData,
    currentGroupCount: state.groups.currentGroupCount
})

export default connect(mapStateToProps, {importAllStudentsData}) (GroupsStudentsData);