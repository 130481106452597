import * as actionTypes from '../actions/types.actions';

const initialState = {
    alerts: [],
    sidebar: false,
    currentVideoUrl: null,
    openVideoPlayer: false,
    openClassExamSaver: false,
    gradeId: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALERT:
            return {
                ...state,
                alerts: [...state.alerts, action.payload]
            }
        case actionTypes.REMOVE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
            }
        case actionTypes.TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebar: !state.sidebar
            }
        case actionTypes.CHANGE_CURRENT_VIDEO_URL:
            return {
                ...state,
                currentVideoUrl: action.url
            }
        case actionTypes.TOGGLE_VIDEO_PLAYER:
            return {
                ...state,
                openVideoPlayer: !state.openVideoPlayer
            }
        case actionTypes.TOGGLE_CLASS_EXAM_SAVER:
            return {
                ...state,
                openClassExamSaver: !state.openClassExamSaver
            }
        case actionTypes.CHANGE_GRADE_ID:
            return {
                ...state,
                gradeId: action.id
            }
        default:
            return state;
    }
}
