import React from 'react';
import './GroupAttend.css';

const GroupAttend = ({setAttend, attend, absenses}) => {
    return (
        <div className={'GroupAttend'}>
            <div onClick={e => setAttend(2)} className={`GroupAttend__absence ${attend == 2 && 'GroupAttend__active'}`}>
                <p>{absenses ? 'الغياب' : 'لم يسدد'}</p>
            </div>
            <div onClick={e => setAttend(1)} className={`GroupAttend__absence ${attend == 1 && 'GroupAttend__active'}`}>
                <p>{absenses ? 'الحضور' : 'سدد'}</p>
            </div>
        </div>
    );
};

export default GroupAttend;