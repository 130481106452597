import React from 'react';
import './ExamStudentsMarksList.css';
import ExamStudentsMark from "./ExamStudentsMark/ExamStudentsMark";

const ExamStudentsMarksList = ({marks, exam}) => {
    return (
        <div className={'ExamStudentsMarksList'}>
            {
                marks?.length > 0 && marks.map((m, i) => (
                    <ExamStudentsMark exam={exam} mark={m} key={m.id} />
                ))
            }
        </div>
    );
};

export default ExamStudentsMarksList;