import React, {useEffect} from 'react'
import "./video.css"
import { useState } from 'react';
import {fetchVideo,createVideo} from "../../../store/actions/video.action";
import {useParams, useOutlet, Outlet} from "react-router-dom";
import VideoList from "./videoList/VideoList";
import {useSelector,useDispatch} from "react-redux";
import Spinner from "../../../Components/Spinner/Spinner";
import {createGroup} from "../../../store/actions/groups.actions";
import VideoEditList from "./VideoEditList/VideoEditList";
import {setAlert} from "../../../store/actions/ui.actions";


const Video = () => {
    const data = useSelector(state => state.videos.videos)
    const fetchingVideo=useSelector(state => state.videos.fetchingVideo)
    const creatingVideo=useSelector(state => state.videos.creatingVideo)
    const dispatch =useDispatch()
    const param = useParams();
    const [isActive, setIsActive] = useState(true);
    const [listName,setListName]=useState();
    const [listDetails,setListDetails]=useState();
    const [editvideo,setEditvideo]=useState(true)
    const [editID,setEditID]=useState(null)


    useEffect(() => {
        dispatch(fetchVideo(param.id))
    }, []);


    const handleButtonClick= ()=>{
      setIsActive(current => !current);
    }
    const handleCreateVideo=async ()=>{
        if(listName.length == 0) return dispatch(setAlert('من فضلك اضف اسم القائمة', 'danger'));
        await   dispatch(createVideo(param.id,listDetails,listName))
        setIsActive(current => !current);
        setListDetails("")
        setListName("")
    }
    console.log("log",fetchingVideo)
    const handleSubmit=(e)=>{
        e.preventDefault()
        handleCreateVideo()
        setListDetails("")
        setListName("")

    }
  return (
    <div className='Video'>
        <div className='Video__container'>
            <div className='Video__header'>
                <h3 className='Video__header--text'>مكتبة فيديوهاتي</h3>
            </div>
            <div className='Video__list--container'>
                {
                    fetchingVideo ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className='Video__list--body'>
                                <VideoList editvideo={editvideo} setEditvideo={setEditvideo}  element={data} setEditID={setEditID}/>

                            </div>

                        </>
                    )
                }
            </div>
        </div>
        <button onClick={handleButtonClick} className='Video__addingButton'><span>+ اضف قائمة جديدة</span></button>
        {/* ********************************************** */}
        <div style={{display: isActive ? 'none' : 'block' }} className='Video__blue--div'>
            <div style={{display: isActive ? 'none' : 'block' }} className='Video__Container--git-info'>
              <form onSubmit={handleSubmit} className='Video__Container--form' >
                <label className='Video__Container-lable'>اسم القائمة </label>
                <input value={listName} onChange={(e)=>setListName(e.target.value)} con className='Video__Container-input' type='text' placeholder='ادخل اسم القائمة'/>
                <label className='Video__Container-lable'>وصف القائمة </label>
                <textarea value={listDetails} onChange={(e)=>setListDetails(e.target.value)} className='Video__git-info--textarea' placeholder='ادخل وصف القائمة'/>
              </form>
              <div className='Video__Container--git-butons'>
                  <button onClick={handleButtonClick} className='Video__butons--btn1'><span>الغاء</span></button>
                  <button onClick={handleCreateVideo} className='Video__butons--btn'>

                      {
                          creatingVideo ? (
                              <Spinner />
                          ) : (
                              <>
                                  <span>انشاء</span>
                              </>
                          )
                      }
                      </button>
              </div>
            </div>
            </div>
        <VideoEditList data={data} editvideo={editvideo} setEditvideo={setEditvideo} editID={editID}/>
        <Outlet/>
    </div>
  )
}



export default Video