import * as actionTypes from '../actions/types.actions';

const initial = {
    questionBank: [],
    fetchingQuestionBank: false
};

export default (state=initial, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_QUESTION_BANK:
            return {
                ...state,
                fetchingQuestionBank: true
            }
        case actionTypes.END_FETCHING_QUESTION_BANK:
            return {
                ...state,
                fetchingQuestionBank: false
            }
        case actionTypes.FETCH_QUESTION_BANK:
            return {
                ...state,
                questionBank: [...action.questions]
            }
        default:
            return state;
    }
}