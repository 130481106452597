import React, {useEffect, useRef} from 'react';
import pdfImage from '../../assets/images/pdf.jpg';
import './Pdf.css';
import { Link, useParams } from 'react-router-dom';

function Pdf() {
  const params = useParams();
    const boxRef = useRef();

    // const changeHeightToWidth = () => {
    //     const boxEle = boxRef.current;
    //     if(!boxEle) return;
    //     const boxWidth = boxEle.getBoundingClientRect().width;
    //     boxEle.style.height = `${boxWidth}px`;
    // }
    //
    // useEffect(() => {
    //     if(!boxRef.current) return;
    //     changeHeightToWidth();
    // }, []);
    //
    // useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         changeHeightToWidth();
    //     });
    // }, []);
  return (
    <Link ref={boxRef} to={`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/pdfs`} className='Pdf'>
        <img src={pdfImage} />
        <p>PDF</p>
    </Link>
  )
}

export default Pdf