import React from 'react'
import "./groups.css"
import ImageList from "../../../assets/images/third.png"
import Group from "../../../Components/GroupsComponents/Group/Group";


const ListOfGroups = ({showGroupe}) => {
  const renderShowList=showGroupe.map((content)=>{
    return<Group isGroup={true} content={content}/>
  })

const address = window.location.pathname;
  console.log(typeof address)
  return (
    <div className='ListOfGroups'>
      {renderShowList}
    </div>
  )
}

export default ListOfGroups;