import React, { useEffect } from 'react'
import "./groups.css"
import ListOfGroups from './ListOfGroups'
import { useState } from 'react'
import { connect } from 'react-redux';
import {createGroup, fetchGroups} from '../../../store/actions/groups.actions';
import Spinner from '../../../Components/Spinner/Spinner'
import {useDispatch} from "react-redux";
import {Outlet, useLocation, useParams} from "react-router-dom";
import GroupCreateForm from "./GroupCreateForm/GroupCreateForm";
const Groups = (props) => {
  const [isActive, setIsActive] = useState(false);
  const dispatch =useDispatch();
  const params = useParams();

  const location = useLocation()

  useEffect(() => {
    const data = {
      grade: params.id
    }
    props.fetchGroups(data);
  }, []);

// console.log("wind",window.location.pathname.split("/").length)
  return (
    <div className='Groups'>
        <div className='Groups__Container'>
          <GroupCreateForm isActive={isActive} setIsActive={setIsActive} />
          <div className='Groups__adress'>
              <h1 className='Groups__adress--text'>مجموعاتي</h1>
          </div>
          <div  className='Groups__body'>
            {
              props.fetchingGroups ? (
                <Spinner />
              ) : (
                <>
                      <div className='Groups__body--info'>
                        <ListOfGroups showGroupe={props.groups} />
                      </div>

                </>
              )
            }
            <button onClick={e => setIsActive(true)} className='Groups__body--btn1'><span>+ اضافة مجموعة</span></button>

            </div>
        </div>
      <Outlet />

    </div>
  )
}

const mapStateToProps = state => ({
  fetchingGroups: state.groups.fetchingGroups,
  groups: state.groups.groups
})

export default connect(mapStateToProps, {fetchGroups}) (Groups);