import React from 'react';
import './Report.css';

const Report = () => {
    return (
        <div className={'Report'}>

        </div>
    );
};

export default Report;