import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import './VideoPlayer.css';
import { toggleVideoPlayer } from '../../../store/actions/ui.actions';

function VideoPlayer({currentVideoUrl, openVideoPlayer, toggleVideoPlayer}) {
    const [valid, setValid] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    function validateYouTubeUrl(url)
    {
        
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                // Do anything for being valid
                // if need to change the url to embed url then use below line
                setVideoUrl('https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
                setValid(true);
            }
            else {
                // Do anything for not being valid
                setValid(false);
            }
        }
    }

    useEffect(() => {
        if(currentVideoUrl.url) {
            validateYouTubeUrl(currentVideoUrl.url)
        }
    }, []);
    return (
    <div className={`VideoPlayer ${openVideoPlayer && 'VideoPlayer__show'}`}>
        <div className='VideoPlayer__container'>
            {
                valid ? (
                    <iframe src={videoUrl} title={currentVideoUrl?.title && currentVideoUrl.title_video}></iframe>
                ) : (
                    <div className='VideoPlayer__error'>
                        <p>The video Url is not valid</p>
                    </div>
                )
            }
            <div onClick={e => toggleVideoPlayer()} className='VideoPlayer__close'>
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = state => ({
    currentVideoUrl: state.ui.currentVideoUrl,
    openVideoPlayer: state.ui.openVideoPlayer
})

export default connect(mapStateToProps, {toggleVideoPlayer}) (VideoPlayer);