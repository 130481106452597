import React, { useState } from 'react';
import './ClassPdfForm.css';
import { uploadClassPdf } from '../../../../../../../../store/actions/doors.actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

function ClassPdfForm({uploadingClassPdf, uploadClassPdf, show, setShow}) {
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const params = useParams();

  return (
    <div className={`ClassPdfForm ${show && 'ClassPdfForm__show'}`}>
        <form className={`ClassPdfForm__form ${show && 'ClassPdfForm__show'}`}>
            <div className='ClassPdfForm__form--element ClassPdfForm__form--upload'>
                {/* <label>اصافة PDF</label> */}
                <input onChange={e => setFile(e.target.files[0])} type="file" class="custom-file-input" accept='application/pdf' />
              </div>
            <div className='ClassPdfForm__form--element'>
                <label>اسم ملف PDF</label>
                <input value={name} onChange={e => setName(e.target.value)} type='text' />
            </div>
            <div className='ClassPdfForm__form--btns'>
              <button onClick={async e =>{
                e.preventDefault();
                const data = {
                  file,
                  name,
                  class_id: params.class_id ? params.class_id : 0,
                  grade: params.id
                };
                await uploadClassPdf(data);
                setFile(null);
                setName('');
                setShow(false);
              }} type='submit'>{
                uploadingClassPdf ? (
                  <span><i class="fa-solid fa-circle-notch"></i></span>
                ) : (
                  <span>تاكيد</span>
                )
              }</button>
              <button onClick={e => {
                e.preventDefault();
                setShow(false)
              }} type='cancel'>الغاء</button>
            </div>
        </form>
    </div>
  )
}

const mapStateToProps = state => ({
  uploadingClassPdf: state.doors.uploadingClassPdf
})

export default connect(mapStateToProps, {uploadClassPdf}) (ClassPdfForm);