import React, {useEffect} from 'react';
import './YoutubeVideoDeleteModal.css';
import {useParams} from "react-router-dom";


const YoutubeVideoDeleteModal = ({deleting, deletedVideo, setDeletedVideo, setDeleting, deleteVideo, deletingVideo}) => {
    const params = useParams();

    useEffect(() => {
        if(!deletedVideo) return setDeleting(false);
        return () => {
            setDeleting(false);
            setDeletedVideo(null);
        }
    }, [deletedVideo]);
    return (
        <div className={`YoutubeVideoDeleteModal ${deleting && 'YoutubeVideoDeleteModal__show'}`}>
            <div className="YoutubeVideoDeleteModal__container">
                <div className="YoutubeVideoDeleteModal__header">
                    <span>هل انت متأكد من مسح {deletedVideo?.title_video}</span>
                </div>
                <div className="YoutubeVideoDeleteModal__btns">
                    <div onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        const data = {
                            class_id: deletedVideo.class_id ? deletedVideo.class_id : 0,
                            id: deletedVideo.id,
                            videoListId: params.video_list ? params.video_list : 0
                        }
                        await deleteVideo(data);
                        setDeleting(false);
                        setDeletedVideo(null);
                    }} className="YoutubeVideoDeleteModal__btn YoutubeVideoDeleteModal__btn--delete">
                        {
                            deletingVideo ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>نعم</span>
                            )
                        }
                    </div>
                    <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeleting(false);
                        setDeletedVideo(null);
                    }} className="YoutubeVideoDeleteModal__btn YoutubeVideoDeleteModal__btn--cancel">لا</div>
                </div>
            </div>
        </div>
    );
};

export default YoutubeVideoDeleteModal;