import React, {useEffect} from 'react';
import './ClassBank.css';
import Header from "../../../../../../../Components/Header/Header";
import {connect} from "react-redux";
import {fetchQuestionBank} from "../../../../../../../store/actions/question.bank.actions";
import {Link, Outlet, useParams} from "react-router-dom";
import Spinner from "../../../../../../../Components/Spinner/Spinner";
import ClassBankQuestion from "./ClassBankQuestion/ClassBankQuestion";

const ClassBank = ({fetchQuestionBank, fetchingQuestionBank, questions}) => {
    useEffect(() => {
        const home = document.querySelector('.ClassPage');
        const freezeStyles = () => {
            home.classList.add('Home__hide')
        }
        const releaseStyles = () => {
            home.classList.remove('Home__hide')
        }

        freezeStyles();

        return () => {
            releaseStyles();
        }
    }, []);

    const params = useParams();

    useEffect(() => {
        const data = {
            class_id: params.class_id
        }
        fetchQuestionBank(data);
    }, []);

    return (
        <div className={'ClassBank'}>
            <Header text={'بنك الأسئلة'} />
            {
                fetchingQuestionBank ? (
                    <Spinner />
                ) : (
                    <div className={'ClassBank__container'}>
                        {
                            questions.map((q, i) => (
                                <ClassBankQuestion question={q} key={q.id} />
                            ))
                        }
                    </div>
                )
            }
            <Link to={`add`} className="ClassBank__btn">اضافة سؤال</Link>
            <Outlet />
        </div>
    );
};

const mapStateToProps = state => ({
    fetchingQuestionBank: state.questionBank.fetchingQuestionBank,
    questions: state.questionBank.questionBank
})

export default connect(mapStateToProps, {fetchQuestionBank}) (ClassBank);