import "./Login.screen.css"
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {loginTeacher, loadUser} from "../../store/actions/user.actions";
import {useNavigate} from "react-router-dom";
import TEACHER from '../../utls/constants';

function Login({loadingData, loginTeacher}){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const formSubmitHandler = async e => {
        e.preventDefault();
        const formData = {
            email,
            password
        };
        await loginTeacher(formData, navigate);
        setEmail('');
        setPassword('');
    }

    useEffect(() => {
        loadUser(navigate);
    }, []);

    return(
        <div className="Login">
            <div className="Login__container">
                <div className="Login__left">
                    <div className="Login__left--img">
                        <img src={TEACHER.loginImage} alt="chemistry"/>
                    </div>
                </div>
                <div className="Login__right">
                    <div className="Login__right--container">
                        <img src={TEACHER.logo} alt="logo"/>
                        <form onSubmit={formSubmitHandler} className="Login__form">
                            <div className="Login__form-element">
                                <label className="Login__form--label">البريد الالكتروني:</label>
                                <div className="Login__form--input-wrap">
                                    <input value={email} onChange={e => setEmail(e.target.value)} className="Login__form--input" type="email"  placeholder=" ادخل البريد الالكتروني  "/>
                                    <i class="fa-solid fa-envelope"></i>
                                </div>
                            </div>
                            <div className="Login__form-element">
                                <label className="Login__form--label">كلمة المرور:</label>
                                <div className="Login__form--input-wrap">
                                    <input value={password} onChange={e => setPassword(e.target.value)} className="Login__form--input" type="password" placeholder=" ادخل كلمه المرور " />
                                    <i class="fa-solid fa-key"></i>
                                </div>
                            </div>
                            <button type={'submit'} className="Login__form--btn">
                                {
                                    !loadingData ? 'تسجيل الدخول ' : <i className="fa-solid fa-circle-notch"></i>
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loadingData: state.userData.loggingTeacher
})
export default connect(mapStateToProps, {loginTeacher, loadUser}) (Login);