import * as actionTypes from '../actions/types.actions';

const initialState = {
    walletData: {},
    fetching: true,
    teachers: [],
    fetchingTeachers: false,
    addingTeacher: false,
    fetchingStudents: false,
    students: [],
    exceptingStudent: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_WALLET:
            return {
                ...state,
                fetching: true
            }
        case actionTypes.END_FETCHING_WALLET:
            return {
                ...state,
                fetching: false
            }
        case actionTypes.FETCH_WALLET:
            return {
                ...state,
                walletData: action.data
            }
        case actionTypes.START_FETCHING_TEACHER_ASSISTANTS:
            return {
                ...state,
                fetchingTeachers: true
            }
        case actionTypes.END_FETCHING_TEACHER_ASSISTANTS:
            return {
                ...state,
                fetchingTeachers: false
            }
        case actionTypes.FETCH_TEACHER_ASSISTANTS:
            return {
                ...state,
                teachers: action.teachers
            }
        case actionTypes.START_ADDING_TEACHER:
            return {
                ...state,
                addingTeacher: true
            }
        case actionTypes.END_ADDING_TEACHER:
            return {
                ...state,
                addingTeacher: false
            }
        case actionTypes.START_FETCHING_GRADE_STUDENTS:
            return {
                ...state,
                fetchingStudents: true
            }
        case actionTypes.END_FETCHING_GRADE_STUDENTS:
            return {
                ...state,
                fetchingStudents: false
            }
        case actionTypes.FETCH_GRADE_STUDENTS:
            return {
                ...state,
                students: action.students
            }
        case actionTypes.START_EXCEPTING_STUDENT:
            return {
                ...state,
                exceptingStudent: true
            }
        case actionTypes.END_EXCEPTING_STUDENT:
            return {
                ...state,
                exceptingStudent: false
            }
        default:
            return state
    }
}