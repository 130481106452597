import React, {useEffect, useState} from 'react';
import './ExamsDaily.css';
import Header from "../../../../Components/Header/Header";
import {fetchGroups} from "../../../../store/actions/groups.actions";
import {connect} from "react-redux";
import {Outlet, useParams} from "react-router-dom";
import Spinner from "../../../../Components/Spinner/Spinner";
import Group from "../../../../Components/GroupsComponents/Group/Group";
import ExamsDailyForm from "./ExamsDailyForm/ExamsDailyForm";

const ExamsDaily = ({groups, fetchingGroups, fetchGroups}) => {
    const [open, setOpen] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const params = useParams();
    useEffect(() => {
        const data = {
            grade: params.id
        }
        fetchGroups(data);
    }, []);

    return (
        <div className={'ExamsDaily'}>
            <Header text={'اختبار يومي'} />
            {
                fetchingGroups ? (
                    <Spinner />
                ) : (
                    <div className='ListOfGroups'>
                        {
                            groups?.length > 0 && groups.map((g, i) => (
                                <Group setGroupId={setGroupId} ExamsDailyFormOpen={open} setExamsDailyOpen={setOpen} exams={true} content={g}/>
                            ))
                        }
                    </div>
                )
            }
            <ExamsDailyForm open={open} setOpen={setOpen} groupId={groupId} />
            <Outlet />
        </div>
    );
};

const mapStateToProps = state => ({
    groups: state.groups.groups,
    fetchingGroups: state.groups.fetchingGroups
})

export default connect(mapStateToProps, {fetchGroups}) (ExamsDaily);