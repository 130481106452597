import './App.css';
import {connect} from "react-redux";
import {loadUser, loginTeacher, logUserOut} from "./store/actions/user.actions";
import {useEffect, useState} from "react";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import Spinner from "./Components/Spinner/Spinner";
import './App.css'
import HomeScreen from "./Screens/Home/Home.screen";
import Login from "./Screens/Login/Login.screen";
import Grade from "./Screens/Grade/Grade";
import Settings from "./Screens/settings/Settings";
import Loader from "./Screens/loader/Loader";
import NavBar from "./Components/Navbar/NavBar";
import Alert from "./Components/Alert/Alert";
import Doors from './Screens/Grade/Doors/Doors';
import Door from './Screens/Grade/Doors/Door/Door';
import CloseClass from './Screens/Grade/Doors/Door/Class/CloseClass/CloseClass';
import ClassPage from './Screens/Grade/Doors/Door/Class/Class.Page/Class.Page';
import ClassYoutube from './Screens/Grade/Doors/Door/Class/Class.Page/ClassYoutube/ClassYoutube'
import ClassPdfs from './Screens/Grade/Doors/Door/Class/Class.Page/ClassPdfs/ClassPdfs';
import ClassPdfViwer from './Screens/Grade/Doors/Door/Class/Class.Page/ClassPdfs/ClassPdfViwer/ClassPdfViwer';
import ClassExams from './Screens/Grade/Doors/Door/Class/Class.Page/ClassExams/ClassExams';
import ClassExamDetails from "./Screens/Grade/Doors/Door/Class/Class.Page/ClassExams/ClassExamDetails/ClassExamDetails";
import ClassExamAddQuestion
    from "./Screens/Grade/Doors/Door/Class/Class.Page/ClassExams/ClassExamDetails/ClassExamAddQuestion/ClassExamAddQuestion";
import Groups from "./Screens/Grade/Groups/Groups";
import Group from "./Screens/Grade/Groups/Group.Screen/Group";
import GroupAddStudent from "./Screens/Grade/Groups/Group.Screen/GroupAddStudent/GroupAddStudent";
import GroupAbsence from "./Screens/Grade/Groups/Group.Screen/GroupAbsence/GroupAbsence";
import GroupExpenses from "./Screens/Grade/Groups/Group.Screen/GroupExpenses/GroupExpenses";
import GroupsStudentsData from "./Screens/Grade/Groups/Group.Screen/GroupStudentsData/GroupsStudentsData";
import GroupPosts from "./Screens/Grade/Groups/Group.Screen/GroupPosts/GroupPosts";
import Exsam from "./Screens/Grade/Exsams/Exsam";
import classExams from "./Screens/Grade/Doors/Door/Class/Class.Page/ClassExams/ClassExams";
import exams from "./Components/Exams/Exams";
import ExamsDaily from "./Screens/Grade/Exsams/ExamsDaily/ExamsDaily";
import ExamsDailyStudents from "./Screens/Grade/Exsams/ExamsDaily/ExamsDailyStudents/ExamsDailyStudents";
import Video from "./Screens/Grade/video/Video";
import ExamStudentsMarks from "./Screens/Grade/Exsams/ExamStudentsMarks/ExamStudentsMarks";
import ClassBank from "./Screens/Grade/Doors/Door/Class/Class.Page/ClassBank/ClassBank";
import Intro from './Screens/Intro/Intro';
import SQs from "./Screens/Grade/SQ/SQs";
import Wallet from "./Screens/settings/Wallet/Wallet";
import AuthGuard from "./Components/HOC/AuthGuard";
import AntiAuthGuard from "./Components/HOC/AntiAuthGuard";
import TeacherAssistant from "./Screens/settings/TeacherAssistant/TeacherAssistant";
import Teacher from "./Screens/settings/TeacherAssistant/Teacher/Teacher";
import Reports from "./Screens/settings/Reports/Reports";
import Report from "./Screens/settings/Reports/Report/Report";
import Exception from "./Screens/settings/Exception/Exception";

function App({loadUser, loadingTeacher, logUserOut, loginTeacher, examsGrade, examsClass, isAuthenticated}) {
    const [loader, setloader] = useState(true);
    const navigate = useNavigate();

    const params = useParams();

    useEffect(() => {
        loadUser(navigate);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setloader(false)
        }, 4000);

    }, []);

    // useEffect(() => {
    //     window.addEventListener('beforeunload', e => {
    //         console.log('Hello there!');
    //         e.preventDefault();
    //         logUserOut();
    //     });
    // }, []);

    const examHomeGrade = '.Exsame';
    const examHomeClass = '.ClassExams';
    const videoListHome = '.Video';
    const videoClassHome = '.ClassPage';
    const pdfClassHome = '.ClassPage';
    const pdfGradeHome = '.Grade__left'

    return (
        <div className="App">
            <Alert />
            {
                loader ? <Intro /> : <>
                    {
                        !loadingTeacher ? (
                            <>
                                {
                                    isAuthenticated && <NavBar/>
                                }
                                <Routes>
                                    {/*{*/}
                                    {/*    isAuthenticated ? (*/}
                                    {/*        <>*/}
                                    <Route path={'/'} exact element={<AuthGuard isAuthenticated={isAuthenticated}><HomeScreen /></AuthGuard>}>
                                                    <Route path='/grade/:id' element={<Grade/>}>
                                                        <Route path={'settings'} exact element={<Settings />}>
                                                            <Route path={'exception'} exact element={<Exception />} />
                                                            <Route path={'wallet'} exact element={<Wallet />} />
                                                            <Route path={'reports'} exact element={<Reports />} >
                                                                <Route path={':student_id'} exact element={<Report />} />
                                                            </Route>
                                                            <Route path={'teacher'} exact element={<TeacherAssistant />} >
                                                                <Route path={':id'} exact element={<Teacher />} />
                                                            </Route>
                                                        </Route>
                                                        <Route path={'sq'} exact element={<SQs />} />
                                                        <Route path='pdfs' exact element={<ClassPdfs pdfHome={pdfGradeHome} />} >
                                                            <Route path=':pdf_id' exact element={<ClassPdfViwer />} />
                                                        </Route>
                                                        <Route path='video' exact element={<Video />}>
                                                            <Route path=':video_list' exact element={<ClassYoutube homeVideo={videoListHome} />} />
                                                        </Route>
                                                        <Route path={'exsam'} element={<Exsam />}>
                                                            <Route exact path={':exam_id/students/marks'} element={<ExamStudentsMarks isClass={false} />} />
                                                            <Route exact path={'daily'} element={<ExamsDaily />} >
                                                                <Route exact path={':exam_id/students/:group_id'} element={<ExamsDailyStudents />} />
                                                            </Route>
                                                            <Route path={':exam_id/details'} exact element={<ClassExamDetails isClass={false} exams={examsGrade} homeClass={examHomeGrade} />} >
                                                                <Route path={'add'} exact element={<ClassExamAddQuestion isClass={false} />} />
                                                            </Route>
                                                        </Route>
                                                        <Route path={'groups'} exact element={<Groups />} >
                                                            <Route exact path={':group_id'} element={<Group />}>
                                                                <Route exact path={'add-student'} element={<GroupAddStudent />} />
                                                                <Route exact path={'absence'} element={<GroupAbsence />} />
                                                                <Route exact path={'expenses'} element={<GroupExpenses />} />
                                                                <Route exact path={'students/data'} element={<GroupsStudentsData/>} />
                                                                <Route exact path={'posts'} element={<GroupPosts />} />
                                                            </Route>
                                                        </Route>
                                                        <Route path='doors' exact element={<Doors />} >
                                                            <Route path=':door_id' exact element={<Door />}>
                                                                <Route path='class/:class_id/close' exact element={<CloseClass />} />
                                                                <Route path='class/:class_id' exact element={<ClassPage />} >
                                                                    <Route path='videos' exact element={<ClassYoutube homeVideo={videoClassHome} />} />
                                                                    <Route path='pdfs' exact element={<ClassPdfs pdfHome={pdfClassHome} />} >
                                                                        <Route path=':pdf_id' exact element={<ClassPdfViwer />} />
                                                                    </Route>
                                                                    <Route exact path={'bank'} element={<ClassBank />} >
                                                                        <Route exact path={'add'} element={<ClassExamAddQuestion bank={true} isClass={false} />} />
                                                                    </Route>
                                                                    <Route path='exams' exact element={<ClassExams />}>
                                                                        <Route exact path={':exam_id/details'} element={<ClassExamDetails isClass={true} homeClass={examHomeClass} exams={examsClass} />} >
                                                                            <Route path={'add'} exact element={<ClassExamAddQuestion isClass={true} />} />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                    </Route>
                                                </Route>
                                                {/*<Route path='/settings' element={<Settings/>}/>*/}
                                            {/*</>*/}
                                        {/*) : (*/}
                                    <Route path={'/login'} exact element={<AntiAuthGuard isAuthenticated={isAuthenticated}> <Login /></AntiAuthGuard>} />
                                        {/*)*/}
                                    {/*}*/}
                                </Routes>
                            </>
                        ) : (
                            <Spinner />
                        )
                    }
                </>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    loadingTeacher: state.userData.loadingTeacher,
    isAuthenticated: state.userData.isAuthenticated,
    examsGrade: state.exsams.exsams,
    examsClass: state.doors.exams
});

export default connect(mapStateToProps, {loadUser, loginTeacher, logUserOut}) (App);