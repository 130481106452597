import React, {useState} from 'react';
import './ExamsAddForm.css';
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

const ExamsAddForm = ({isActive, setIsActive, createExsame, setAlert, creatingExam}) => {
    const [exsamName,setExsamName]=useState('');
    const [exsamDisc,setExsamDisc]=useState('');
    const [exsamStart,setExsamStart]=useState('');
    const [exsamEnd,setExsamEnd]=useState('');
    const [exsamStartDate,setExsamStartDate]=useState('');
    const [exsamEndDate,setExsamEndDate]=useState('');

    const params = useParams();
    const navigate = useNavigate();

    const formateDate = date => {
        if(date.length == 1) {
            return `0${date}`;
        }
        return date;
    }

    const handeleCreateEsame= async ()=>{
        if(exsamName.length === 0) return setAlert('من فضلك ادخل اسم الامتحان', 'danger');
        if(exsamDisc.length === 0) return setAlert('من فضلك ادخل وصف الامتحان', 'danger');
        if(exsamStart.length === 0) return setAlert('من فضلك ادخل وقت البداية', 'danger');
        if(exsamEnd.length === 0) return setAlert('من فضلك ادخل النهاية', 'danger');
        if(exsamStartDate.length === 0) return setAlert('من فضلك ادخل تاريخ البدأ', 'danger');
        if(exsamEndDate.length === 0) return setAlert('من فضلك ادخل تاريخ النهاية', 'danger');
        const now = new Date();
        const data = {
            exam_name: exsamName,
            exam_description: exsamDisc,
            grade: params.id,
            date: `${now.getFullYear()}-${formateDate(`${now.getMonth() + 1}`)}-${formateDate(`${now.getDate()}`)}`,
            start_time: exsamStart,
            end_time: exsamEnd,
            start_exam_date: exsamStartDate,
            end_exam_date: exsamEndDate,
            exam_status: 0,
            exam_mark: 0,
            class_id: 0,
            navigate
        };
        console.log(data);
        const res = await createExsame(data);
        if(res.status == 200) {
            setIsActive(current => !current);
            setExsamName("");
            setExsamDisc("");
            setExsamStart("");
            setExsamEnd("");
            setExsamStart("");
            setExsamEndDate("");
        }
    }

    const handleButtonClick= ()=>{
        setIsActive(current => !current);
    }
    return (
        <div style={{display: isActive ? 'none' : 'block' }} className='Exsam__blue--div'>
            <div style={{display: isActive ? 'none' : 'block' }} className='Exsame__Container--git-info'>
                <form className='Exsame__Container--gitinfo-form'>
                    <h3 className='Exsame__git-info--title'>تفاصيل الاختبار</h3>
                    <label className='Exsame__git-info--lable'>اسم الاختبار</label>
                    <input value={exsamName} onChange={(e)=>setExsamName(e.target.value)} className='Exsame__git-info--input' type='text' placeholder='ادخل اسم الاختبار'/>
                    <textarea value={exsamDisc} onChange={(e)=>setExsamDisc(e.target.value)} className='Exsame__git-info--textarea' placeholder='اضف وصف الاختبار'/>
                    <div className='Exsame__git-info--time'>
                        <div>
                            <label className='Exsame__time--lable' >وقت البداية</label>
                            <input value={exsamStart} onChange={(e)=>setExsamStart(e.target.value)}  className='Exsame__time--input' type='time'/>
                        </div>
                        <div>
                            <label className='Exsame__time--lable' >وقت النهاية</label>
                            <input value={exsamEnd} onChange={(e)=>setExsamEnd(e.target.value)} className='Exsame__time--input' type='time'/>
                        </div>
                    </div>
                    <label className='Exsame__git-info--lable'>يوم الاختبار</label>
                    <input value={exsamStartDate} onChange={(e)=>setExsamStartDate(e.target.value)} className='Exsame__git-info--input' type='date'/>
                    <label className='Exsame__git-info--lable' >تاريخ نهايه الاختبار</label>
                    <input value={exsamEndDate} onChange={(e)=>setExsamEndDate(e.target.value)}  className='Exsame__git-info--input' type='date'/>
                </form>
                <div className='Exsame__Container--git-butons'>
                    <button onClick={handleButtonClick} className='Exsame__butons--btn1'><span>الغاء</span></button>
                    <button onClick={handeleCreateEsame} className='Exsame__butons--btn'>
                        {
                            creatingExam ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>انشاء</span>
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExamsAddForm;