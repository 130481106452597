import React, {useEffect, useState} from 'react';
import NavBar from "../../../Components/Navbar/NavBar";
import Header from "../../../Components/Header/Header";
import './Wallet.css';
import GroupDayPicker from "../../Grade/Groups/Group.Screen/GroupAbsence/GroupDayPicker/GroupDayPicker";
import {connect} from "react-redux";
import {fetchWallet} from "../../../store/actions/Settings.actions";
import Spinner from "../../../Components/Spinner/Spinner";


const years = [
    {
        year: 'الصف الاول الثانوي',
    },
    {
        year: 'الصف الثاني الثانوي',
    },
    {
        year: 'الصف الثالث الثانوي',
    },
]

const Wallet = ({fetchWallet, wallet, fetching}) => {
    const [date, setDate] = useState(`${new Date().getMonth() + 1}`.length < 2 ? `${new Date().getFullYear()}-0${new Date().getMonth() + 1}` : `${new Date().getFullYear()}-${new Date().getMonth() + 1}`);

    useEffect(() => {
        fetchWallet({date});
    }, [date]);

    return (
        <div className={'Wallet'}>
            <Header text={'المحفظة'} />
            <div className="Wallet__container">
                <div className="Wallet__date">
                    {/*<div className="Wallet__date--date">{date}</div>*/}
                    <GroupDayPicker expenses={true} date={date} setDate={setDate} />
                </div>
                {
                    fetching ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className="Wallet__body">
                                {
                                    years.map((year, i) => (
                                        <div className={'Wallet__year'}>
                                            <div className="Wallet__year--right">
                                                <div className="Wallet__year--header">{year.year}</div>
                                            </div>
                                            <div className="Wallet__year--left">
                                                <div className="Wallet__year--payed"> دفع {wallet.students[i]['COUNT(student.id)']}/{wallet?.payed[i]['COUNT(student.id)']}</div>
                                                <p className="Wallet__year--total">{wallet?.profit[i]['total_mony']} جنيها</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="Wallet__total">
                                <span className="Wallet__total--header">المجموع الكلي</span>
                                <span className="Wallet__total--number">{wallet?.profit?.reduce((acc, cur) => acc + Number(cur['total_mony']), 0)} جنيها </span>
                            </div>
                        </>

                    )
                }

            </div>
        </div>
    );
};


const mapStateToProps = state => ({
    wallet: state.settings.walletData,
    fetching: state.settings.fetching
})

export default connect(mapStateToProps, {fetchWallet}) (Wallet);