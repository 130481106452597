import React from 'react';
import './GroupPost.css';

const GroupPost = ({post}) => {
    return (
        <div className={'GroupPost'}>
            {
                post?.date && <div className="GroupPost__date">{post.date}</div>
            }
            {
                post?.post_details && <div className={'GroupPost__details'}>{post.post_details}</div>
            }
            {
                post?.img_url && <div className={'GroupPost__image'}>
                    <img src={post.img_url} />
                </div>
            }

        </div>
    );
};

export default GroupPost;