import React, {useEffect, useState} from 'react';
import './GroupAddStudent.css';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Header from "../../../../../Components/Header/Header";
import {fetchStudentsToAdd, addStudentToGroup} from "../../../../../store/actions/groups.actions";
import Spinner from "../../../../../Components/Spinner/Spinner";
import Student from "./Student/Student";
import StudentSearch from "./StudentSearch/StudentSearch";
import StudentAddForm from "./StudentAddForm/StudentAddForm";

const GroupAddStudent = ({groups, fetchingGroups, currentGroupCount, addingStudentToGroup, addStudentToGroup, students, fetchingStudentsToAdd, fetchStudentsToAdd}) => {
    const [group, setGroup] = useState(null);
    const [term, setTerm] = useState('');
    const [searchResults, setSearchResults] = useState(students);
    const [openForm, setOpenForm] = useState(false);
    const params = useParams();

    useEffect(() => {
        handleSearch(term); 
    }, [students]);

    useEffect(() => {
        if(fetchingGroups || groups.length === 0) return;
        setGroup(prevState => {
            return groups.filter(g => g.id == params.group_id)[0];
        })
    }, [groups, fetchingGroups]);

    useEffect(() => {
        if(!params.id) return;
        const data = {
            grade: params.id
        }
        fetchStudentsToAdd(data);
    }, []);

    const handleSearch = (value) => {
        let studentsMatch = [];
        if(!isNaN(value)) {
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.phone.trim().toLowerCase().includes(value)) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch);
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.phone.toLowerCase().split('').indexOf(firstLetter[0]) - b.phone.toLowerCase().split('').indexOf(firstLetter[0]));

        } else {
            console.log(value);
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.name?.trim().toLowerCase().includes(value)) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch)
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.name.toLowerCase().split('').indexOf(firstLetter[0]) - b.name.toLowerCase().split('').indexOf(firstLetter[0]));
        }

        setSearchResults(studentsMatch);
    }
    const inputChangeHandler = e => {
        setTerm(e.target.value);
        let value = e.target.value;
        if(!value || value.trim().length == 0) setSearchResults(students);
        handleSearch(value);
    }
    return (
        <div className={'GroupAddStudent'}>
            <div className="GroupAddStudent__header">
                <Header text={(group?.group_name ? group?.group_name : '') + ` اضافة طالب الي `} />
                <StudentSearch term={term} inputChangeHandler={inputChangeHandler} />
                <div className="GroupAddStudent__total">
                    <p className="GroupAddStudent__total--text">اجمالي عدد الطلاب</p>
                    <span className="GroupAddStudent__total--num">{searchResults?.length}</span>
                </div>
            </div>
            {
                fetchingStudentsToAdd ? (
                    <Spinner />
                ) : (
                    <div className={'GroupAddStudent__students'}>
                        {
                            searchResults?.map((s, i) => (
                                <Student currentGroupCount={currentGroupCount} clickToAddFunc={addStudentToGroup} student={s} key={i} />
                            ))
                        }
                    </div>
                )
            }
            <StudentAddForm openForm={openForm} setOpenForm={setOpenForm} />
            <div className="GroupAddStudent__addBtn" onClick={e => setOpenForm(!openForm)}><i className="fa-solid fa-user-plus"></i></div>
        </div>
    );
};

const mapStateToProps = state => ({
    groups: state.groups.groups,
    fetchingGroups: state.groups.fetchingGroups,
    students: state.groups.students,
    fetchingStudentsToAdd: state.groups.fetchingStudentsToAdd,
    currentGroupCount: state.groups.currentGroupCount,
    addingStudentToGroup: state.groups.addingStudentToGroup
})

export default connect(mapStateToProps, {fetchStudentsToAdd, addStudentToGroup}) (GroupAddStudent);