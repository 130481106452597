import React, {useEffect} from 'react';
import './Exception.css';
import {fetchGradeStudents} from "../../../store/actions/Settings.actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Student from "../../Grade/Groups/Group.Screen/GroupAddStudent/Student/Student";
import Header from "../../../Components/Header/Header";
import Spinner from "../../../Components/Spinner/Spinner";

const Exception = ({students, fetchingStudents, fetchGradeStudents}) => {
    const params = useParams();
    useEffect(() => {
        if(students.length > 0 && !fetchingStudents) {
            return;
        } else {
            console.log(fetchingStudents, students)
            const data = {
                grade: params.id
            }
            console.log(data);
            fetchGradeStudents(data);
        }
    }, []);
    return (
        <div className={'Exception'}>
            <Header text={'الاستثناءات'} />
            <div className="Exception__students">
                {
                    fetchingStudents ? (
                        <Spinner />
                    ): (
                        students.map((student, i) => (
                            <Student student={student} attend={true} exception={true} />
                        ))
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    students: state.settings.students,
    fetchingStudents: state.settings.fetchingStudents
});

export default connect(mapStateToProps, {fetchGradeStudents}) (Exception);