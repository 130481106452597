import React, {useState} from 'react';
import './ExamsDailyForm.css';
import {useNavigate, useParams} from "react-router-dom";
import {setAlert} from "../../../../../store/actions/ui.actions";
import {connect} from "react-redux";
import {createExsame} from "../../../../../store/actions/exsam.action";


const formateDate = date => {
    if(date.length == 1) {
        return `0${date}`;
    }
    return date;
}
const ExamsDailyForm = ({open, setOpen, groupId, creatingExsam, setAlert, createExsame}) => {
    const now = new Date();
    const [date, setDate] = useState(`${now.getFullYear()}-${formateDate(`${now.getMonth() + 1}`)}-${formateDate(`${now.getDate()}`)}`);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [degree, setDegree] = useState(0);

    const params = useParams();
    const navigate = useNavigate();

    const examCreateHandler = async e => {
        e.preventDefault();

        if(name.length == 0) return setAlert('من فضلك ادخل اسم الامتحان!', 'danger');
        if(desc.length == 0) return setAlert('من فضلك ادخل وصف الامتحان!', 'danger');
        if(degree <= 0) return setAlert('من فضلك ادخل درجة الامتحان!', 'danger');

        const data = {
            exam_name: name,
            exam_description: desc,
            grade: params.id,
            date,
            start_time: 0,
            end_time: 0,
            start_exam_date: 0,
            end_exam_date: 0,
            exam_status: 4,
            exam_mark: degree,
            class_id: 0,
            navigate,
            group_id: groupId
        };

        const res = await createExsame(data);
        if(res.status == 200) {
            setName('');
            setDesc('');
            setDegree(0);
            setOpen(false);
        }
    }

    return (
        <div className={`ExamsDailyForm ${open && 'ExamsDailyForm__open'}`}>
            <div className="ExamsDailyForm__container">
                <div className="ExamsDailyForm__header">الاختبار اليومي</div>
                <div className="ExamsDailyForm__element">
                    <label htmlFor="name" className="ExamsDailyForm__element--label">اسم الاختبار</label>
                    <input type="text" name={'name'} value={name} onChange={e => setName(e.target.value)} className="ExamsDailyForm__element--input"/>
                </div>
                <div className="ExamsDailyForm__element">
                    <label htmlFor="desc" className="ExamsDailyForm__element--label">وصف الاختبار</label>
                    <input type="text" value={desc} onChange={e => setDesc(e.target.value)} name={'desc'} className="ExamsDailyForm__element--input"/>
                </div>
                <div className="ExamsDailyForm__element ExamsDailyForm__number">
                    <label htmlFor="deg" className="ExamsDailyForm__element--label">درجة الاختبار</label>
                    <input type="number" min={1} value={degree} onChange={e => setDegree(e.target.value)} name={'deg'} className="ExamsDailyForm__element--input"/>
                </div>
                <div className="ExamsDailyForm__element">
                    <label htmlFor="date" className="ExamsDailyForm__element--label">يوم الاختبار</label>
                    <input type="text" value={date} name={'date'} className="ExamsDailyForm__element--input ExamsDailyForm__element--date" readOnly/>
                </div>
                <div className="ExamsDailyForm__btns">
                    <button type={'submit'} onClick={e => {
                        examCreateHandler(e);
                    }} className="ExamsDailyForm__btn">
                        {
                            creatingExsam ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>انشاء</span>
                            )
                        }
                    </button>
                    <button type={'cancel'} onClick={() => {
                        setOpen(false);
                    }} className="ExamsDailyForm__btn">الغاء</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    creatingExsam: state.exsams.creatingExsam
});

export default connect(mapStateToProps, {setAlert, createExsame}) (ExamsDailyForm);