import ImageList from "../../../assets/images/SVG/SIGNATURE/groupShow.png";
import React from "react";
import {useParams,useNavigate} from "react-router-dom";
import {deleteGradeGroup} from "../../../store/actions/groups.actions";
import {useDispatch} from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import './Group.css';
const GroupShow=({content, isGroup, ExamsDailyFormOpen, setGroupId, setExamsDailyOpen, exams})=>{
    const {userId}  = useParams()
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const handlenav=()=>{
        if (isGroup) {
            navigate(`${content.id}`);
        } else {
            if(exams) {
                setGroupId(content.id);
                setExamsDailyOpen(!ExamsDailyFormOpen);
            }
        }
        // navigate(`${content.id}`);

    }
    const params = useParams()

    const handelDeletingGroup=()=>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            customClass: 'swal__wide',
            title: ' هل انتا متأكد ؟',
            text: "! انك تريد حذف المجموعة",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '! قم بالحذف',
            cancelButtonText: 'الغاء',
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(content, params.id);
                const data = {
                    grade: params.id,
                    group_id: content.id
                }
                console.log(data);

                if (dispatch(deleteGradeGroup(data))){
                    MySwal.fire({
                        title:'!تم الخذف',
                        text: '.تم حذف الجروب الخاص بك',
                        customClass: 'swal__wide',
                        icon: 'success',

                    })
                }
            }
        })

    }

    return(<div className="Group">
        <div onClick={handlenav} className='Group__container'>
            <div className='Group__img--container'>
                <img src={ImageList} alt='Group__img' className={'Group__img'}/>
                <h3>
                    {content.group_name}
                </h3>
            </div>
        </div>
        {
            isGroup && <div className="Group__delete">
                <i onClick={handelDeletingGroup}  className="fa-solid fa-square-xmark Group__delete--icon "></i>
            </div>
        }
    </div>)
}
export default GroupShow;
