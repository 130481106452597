import React, {useState} from 'react';
import './AssistantForm.css';
import {addTeacher} from "../../../../store/actions/Settings.actions";
import {connect} from "react-redux";
import {setAlert} from "../../../../store/actions/ui.actions";
import Spinner from "../../../../Components/Spinner/Spinner";

const AssistantForm = ({ adding, setAdding, addTeacher, setAlert, addingTeacher }) => {
    const [form, setForm] = useState({
        name: {
            value: '',
            type: 'text',
        },
        phone: {
            value: '',
            type: 'text',
        },
        email: {
            value: "",
            type: 'text'
        },
        password: {
            value: '',
            type: 'password',
            show: false
        },
        money: {
            checked: false,
            type: 'checkbox'
        }
    });
    return (
        <div className={'AssistantForm'}>
            <div className="AssistantForm__header">
                <h5>اضافة مساعد</h5>
            </div>
            <form className="AssistantForm__form">
                <div className="AssistantForm__element">
                    <label htmlFor="name" className="AssistantForm__element--label">الاسم</label>
                    <input onChange={e => {
                        setForm({
                            ...form,
                            name: {
                                ...form.name,
                                value: e.target.value
                            }
                        })
                    }} name={'name'} type="text" className="AssistantForm__element--input"/>
                </div>
                <div className="AssistantForm__element">
                    <label htmlFor="phone" className="AssistantForm__element--label">رقم الهاتف</label>
                    <input onChange={e => {
                        setForm({
                            ...form,
                            phone: {
                                ...form.phone,
                                value: e.target.value
                            }
                        })
                    }} name='phone' type="text" className="AssistantForm__element--input"/>
                </div>
                <div className="AssistantForm__element">
                    <label htmlFor="email" className="AssistantForm__element--label">البريد الالكتروني</label>
                    <input onChange={e => {
                        setForm({
                            ...form,
                            email: {
                                ...form.email,
                                value: e.target.value
                            }
                        })
                    }} name={'email'} type="text" className="AssistantForm__element--input"/>
                </div>
                <div className="AssistantForm__element">
                    <label htmlFor="password" className="AssistantForm__element--label">كلمة المرور</label>
                    <div className="AssistantForm__element--password-wrapper">
                        <input onChange={e => {
                            setForm({
                                ...form,
                                password: {
                                    ...form.password,
                                    value: e.target.value
                                }
                            })
                        }} name={'password'} type={form.password.type} className="AssistantForm__element--input"/>
                        <span className="AssistantForm__element--show">{form.password.show ? <i onClick={e => {
                            console.log('Hello')

                            setForm({
                                ...form,
                                password: {
                                    ...form.password,
                                    type: 'password',
                                    show: false
                                }
                            })
                        }} style={{color: 'green'}} className="fa-solid fa-eye-slash"></i> : <i onClick={e => setForm({
                            ...form,
                            password: {
                                ...form.password,
                                type: 'text',
                                show: true
                            }
                        })} style={{color: "red"}} className="fa-solid fa-eye"></i>}</span>
                    </div>
                </div>
                <div className="AssistantForm__element--money">
                    <label htmlFor="money" className="AssistantForm__element--label">تأخذ فلوس من الطلاب</label>
                    <input name={'money'} onChange={e => {
                        setForm({
                            ...form,
                            money: {
                                ...form.money,
                                checked: !form.money.checked
                            }
                        })
                    }} checked={form.money.checked} type="checkbox" className="AssistantForm__element--input"/>
                </div>
                <div className="AssistantForm__btn">
                    <button type={"submit"} onClick={async e => {
                        e.preventDefault();
                        const data = {
                            name: form.name.value,
                            phone: form.phone.value,
                            password: form.password.value,
                            email: form.email.value,
                            money_type: form.money.checked
                        };
                        if(!data.name || !data.phone || !data.password || !data.email) return setAlert('من فضلك اكمل جميع البيانات', 'danger');
                        const res = await addTeacher(data);
                        if(res.status == 200 && res.data == '1') {
                            setAdding(false);
                            setForm({
                                name: {
                                    value: '',
                                    type: 'text',
                                },
                                phone: {
                                    value: '',
                                    type: 'text',
                                },
                                email: {
                                    value: "",
                                    type: 'text'
                                },
                                password: {
                                    value: '',
                                    type: 'password',
                                    show: false
                                },
                                money: {
                                    checked: false,
                                    type: 'checkbox'
                                }
                            });
                        }
                    }} className="">
                        {
                            addingTeacher ? (
                                <Spinner />
                            ) : (
                                <span>اضافة</span>
                            )
                        }
                    </button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    addingTeacher: state.settings.addingTeacher
})

export default connect(mapStateToProps, {addTeacher, setAlert}) (AssistantForm);