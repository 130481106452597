import React, {useEffect, useState} from 'react';
import './ClassPdfs.css';
import Header from '../../../../../../../Components/Header/Header.js';
import { fetchClassPdfs, deletePdf } from '../../../../../../../store/actions/doors.actions';
import { connect } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import Spinner from '../../../../../../../Components/Spinner/Spinner';
import ClassPdf from './ClassPdf/ClassPdf';
import ClassPdfForm from './ClassPdfForm/ClassPdfForm';
import ClassPdfDeleteModal from "./ClassPdfDeleteModal/ClassPdfDeleteModal";
import ClassPdfEditModal from "./ClassPdfEditModal/ClassPdfEditModal";


function ClassYoutube({fetchClassPdfs, deletePdf, pdfHome, deletingPdf, fetchingClassPdfs, pdfs}) {
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [deletedPdf, setDeletedPdf] = useState(null);
	const [editing, setEditing] = useState(false);
	const [editedPdf, setEditedPdf] = useState(null);
	const params = useParams();

	useEffect(() => {
		fetchClassPdfs(params.id, params.class_id ? params.class_id : 0);
	}, []);

    useEffect(() => {
	    const home = document.querySelector(pdfHome);
	    const freezeStyles = () => {
	        home.classList.add('Home__hide')
	    }
	    const releaseStyles = () => {
	        home.classList.remove('Home__hide')
	    }

	    freezeStyles();

	    return () => {
	        releaseStyles();
	    }
    }, []);
	return (
		<div className='ClassPdfs'>
			<Header text={'PDFS'} />
			{
				fetchingClassPdfs ? (
					<Spinner />
				): (
					<div className='ClassPdfs__list'>
                        {
                            pdfs.map((pdf, i) => (
                                <ClassPdf editing={editing} setEditing={setEditing} setEditedPdf={setEditedPdf} deleting={deleting} setDeleting={setDeleting} setDeletedPdf={setDeletedPdf} key={pdf.id} pdf={pdf} number={i+1} />
                            ))
                        }
					</div>
				)
			}
			<ClassPdfDeleteModal deletingPdf={deletingPdf} deletePdf={deletePdf} deleting={deleting} deletedPdf={deletedPdf} setDeleting={setDeleting} setDeletedPdf={setDeletedPdf} />
			<ClassPdfForm show={show} setShow={setShow} />
			
			<div onClick={e => setShow(true)} className='ClassPdfs__add'>
				<p>ارفع ملف PDF</p>
				 {/*<i class="fa-solid fa-plus"></i>*/}
			</div>
			<Outlet />
		</div>
	)
}

const mapStateToProps = state => ({
	fetchingClassPdfs: state.doors.fetchingClassPdfs,
	pdfs: state.doors.pdfs,
	deletingPdf: state.doors.deletingPdf,
})

export default connect(mapStateToProps, {fetchClassPdfs, deletePdf}) (ClassYoutube);