import React, { useEffect, useState } from 'react';
import './Doors.css';
import DoorsForm from './DoorsForm/DoorsForm';
import { Link, Outlet, useParams } from 'react-router-dom';
import { fetchDoors } from '../../../store/actions/doors.actions';
import { connect } from 'react-redux';
import Spinner from '../../../Components/Spinner/Spinner';
import Header from '../../../Components/Header/Header';
import DoorDeleteModal from "./Door.Delete.Modal/Door.Delete.Modal";
import {deleteDoor, editDoor} from "../../../store/actions/doors.actions";
import DoorEditModal from "./Door.Edit.Modal/Door.Edit.Modal";

function Doors({fetchDoors, editDoor, doors, editingDoor, fetchingDoors, deletingDoor, deleteDoor}) {
    const params = useParams();
    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deletedDoorId, setDeletedDoorId] = useState(null);
    const [editing, setEditing] = useState(false);
    const [editedDoor, setEditedDoor] = useState(null);

    useEffect(() => {
        fetchDoors(params.id);
    }, []);

    return (
        <div className='Doors'>
            <Header text={'الابواب'} />
            {
                !fetchingDoors ? (
                    <div className='Doors__items'>
                        {
                            doors?.length > 0 && doors?.map((d, i) => (
                                <Link to={`/grade/${params.id}/doors/${d.id}`} className='Doors__item'>
                                    {d?.name && d.name}
                                    <div className="Doors__item--left">
                                        <span onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setDeletedDoorId(d);
                                            setDeleting(true);
                                        }} className="Doors__item--delete"><i className="fa-solid fa-trash"></i></span>
                                        <span onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setEditing(true);
                                            setEditedDoor(d);
                                        }} className="Doors__item--edit"><i className="fa-solid fa-pen"></i></span>
                                    </div>
                                </Link>
                            ))
                        }
                        <DoorDeleteModal deletingDoor={deletingDoor} deleteDoor={deleteDoor} deletedDoorId={deletedDoorId} setDeletedDoorId={setDeletedDoorId} deleting={deleting} setDeleting={setDeleting} />
                        <DoorEditModal editDoor={editDoor} editingDoor={editingDoor} editedDoor={editedDoor} setEditedDoor={setEditedDoor} editing={editing} setEditing={setEditing} />
                    </div>
    
                ) : (
                    <Spinner />
                )
            }
            <div onClick={e => setOpen(!open)} className='Doors__create'>
                <span>اضافة باب</span>
                <i class="fa-solid fa-plus"></i>
            </div>
            <DoorsForm setOpen={setOpen} grade={params.id} open={open} />
            <Outlet />
        </div>
    )
}

const mapStateToPrios = state => ({
    fetchingDoors: state.doors.fetchingDoors,
    doors: state.doors.doors,
    deletingDoor: state.doors.deletingDoor,
    editingDoor: state.doors.editingDoor
});

export default connect(mapStateToPrios, {fetchDoors, editDoor, deleteDoor}) (Doors);