import harvestLogo from '../assets/images/SVG/FAVICON/HARVEST.svg';
import samehIntroImage from '../assets/images/Sameh-elKholy-2.png';
import galilioLogo from '../assets/images/SVG/FAVICON/GALILIO.svg';
import galilioIntroImage from '../assets/images/chemistry-teacher-icon-top.jpg';
import harvestLoginImage from '../assets/images/harvestLogin.jpg';
import galilioLoginImage from '../assets/images/galilioLogin.jpg';

// export const BASE_URL = 'https://roomaclass.com/classroomaapps/harvest/';
const HARVEST = {
    logo: harvestLogo,
    name: "MR. Sameh Elkholy",
    subject: "مدرس انجليزي",
    intro: samehIntroImage,
    BASE_URL_TEST: 'https://doublem.site/projects/apps/elmester/',
    BASE_URL: 'https://appsapis.roomaclass.com/classroomaapps/harvest/',
    loginImage: harvestLoginImage
};

const GALILIO = {
    logo: galilioLogo,
    name: "MR. Sameh Elkholy",
    subject: "مدرس انجليزي",
    intro: galilioIntroImage,
    BASE_URL_TEST: 'https://doublem.site/projects/apps/elmester/',
    BASE_URL: 'https://roomaclassapps.roomaclass.com/classroomaapps/galilio/',
    loginImage: galilioLoginImage
};

// export const BASE_URL = HARVEST.BASE_URL;
export const BASE_URL = GALILIO.BASE_URL;
// export const BASE_URL = HARVEST.BASE_URL_TEST;

export default GALILIO;