import React, {useEffect} from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import { useState } from 'react'
import "./navbar.css"
// import Logo  from "../../assets/images/SVG/FAVICON/FAV.svg"
import { connect } from 'react-redux'
import { toggleSidebar } from '../../store/actions/ui.actions'
import {logUserOut} from "../../store/actions/user.actions";
import TEACHER from "../../utls/constants";

const NavBar = ({sidebar, toggleSidebar, logUserOut, gradeId}) => {
    const [settings, setSettings] = useState(false);
    // const[appear ,setappear]= useState(false)
    // const [disappear , setdisappear]=useState(true)
    // const [active ,setactive] = useState("")
    // const showNavbar=()=>{
    //     if(appear==true){
    //         setappear(false)
    //         setdisappear(true)
    //         setactive("active")
    //     }
    //     if(disappear==true){
    //         setappear(true)
    //         setdisappear(false)
    //         setactive("noActive")
    //     }

    // }

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        console.log(params);
        if(gradeId) {
            setSettings(true);
        } else {
            setSettings(false);
        }
    }, [gradeId]);

  return (
    <div className='navbar'>
        <div className='navbar__container'>
            <div className='navbar__container--items'>
                <div className='navbar__logo'>
                    <img src={TEACHER.logo} alt='logo'/>
               </div>
               <div className='navbar__container--links'>
                <nav className='container__links'> 
                   <ul id={!sidebar ? 'active' : 'noActive'} className='container__links--ul ' >
                     <li onClick={e => toggleSidebar()}>
                       <NavLink  to="/" className={({isActive}) => {
                           return (isActive ? 'main__li active': 'main__li inactive')
                       }}>الرئسية <i class="fa-solid fa-house"></i></NavLink>
                     </li>
                     <li onClick={e => toggleSidebar()}>
                       <NavLink to="/grade/1" className="exsame__li" >الصف الاول <i class="fa-solid fa-user"></i></NavLink>
                     </li>
                     <li onClick={e => toggleSidebar()}>
                       <NavLink to="/grade/2"  className="atendande__li">الصف الثاني<i class="fa-solid fa-user-group"></i></NavLink>
                     </li>
                     <li onClick={e => toggleSidebar()}>
                       <NavLink to="/grade/3" className="reserve__li" >الصف الثالث <i class="fa-solid fa-users"></i></NavLink>
                     </li>
                       {
                           settings && (
                             <li onClick={e => toggleSidebar()}>
                               <NavLink to={`/grade/${gradeId}/settings`} className="settings__li">الاعدادات<i class="fa-solid fa-gear"></i></NavLink>
                             </li>
                           )
                       }
                     <li onClick={e => {
                         toggleSidebar();
                         logUserOut();
                         navigate('/login');
                     }}>
                       <a className="settings__li">تسجيل الخروح<i className="fa-solid fa-right-from-bracket"></i></a>
                     </li>
                  </ul>
                    <div className='navbar__button--container' >
                        <button className='navbar__close--btn ' onClick={toggleSidebar}>{sidebar&& <i class="fa-solid fa-x"></i>}</button>
                        <button className='navbar__bars--btn 'onClick={toggleSidebar} >{!sidebar&&<i class="fa-solid fa-bars"></i>}</button>
                   </div>
                </nav>
              </div>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = state => ({
  sidebar: state.ui.sidebar,
    gradeId: state.ui.gradeId
})

export default connect(mapStateToProps, {toggleSidebar, logUserOut}) (NavBar);