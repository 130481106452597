import React, {useEffect, useState} from 'react';
import './ClassPdfViwer.css';
import Header from '../../../../../../../../Components/Header/Header';
import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core';
import {useParams} from "react-router-dom";
import {connect} from "react-redux"; // install this library
import {fetchClassPdfs} from "../../../../../../../../store/actions/doors.actions";
import Spinner from "../../../../../../../../Components/Spinner/Spinner";

function ClassPdfViwer({fetchingClassPdfs, fetchClassPdfs, pdfs}) {
    const [pdf, setPdf] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const params = useParams();
  console.log(params);
  useEffect(() => {
      if(pdfs.length > 0 && pdfs.filter(p => p.id == params.pdf_id).length > 0) setPdf(pdfs.filter(p => p.id == params.pdf_id)[0]);

  }, [pdfs]);

  return (
    <div className='ClassPdfViwer'>
        <Header text={'PDF عرض'} />
        {
            (pdf == null || fetchingClassPdfs) ? (
                <Spinner />
            ) : (
                // <div>fdafsd</div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdf?.pdf_url}
                    plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            )
        }
    </div>
  )
}

const mapStateToProps = state => ({
    fetchingClassPdfs: state.doors.fetchingClassPdfs,
    pdfs: state.doors.pdfs
})

export default connect(mapStateToProps, {fetchClassPdfs}) (ClassPdfViwer);