import React, {useEffect, useState} from 'react';
import './Door.Edit.Modal.css';
import {useParams} from "react-router-dom";

const DoorEditModal = ({editing, editingDoor, editDoor, setEditing, setEditedDoor, editedDoor}) => {
    const [name, setName] = useState('');
    const params = useParams();
    useEffect(() => {
        if(!editedDoor) return setEditing(false);
        return () => {
            setEditedDoor(null);
            setEditing(false);
        }
    }, [editedDoor]);
    useEffect(() => {
        if(!editedDoor) return;
        setName(editedDoor.name);
    }, [editedDoor]);
    return (
        <div className={`DoorEditModal ${editing && 'DoorEditModal__show'}`}>
            <div className="DoorEditedModal__form">
                <div className="DoorEditModal__form--element">
                    <label htmlFor="name">اسم الباب</label>
                    <input value={name} onChange={e => {
                        setName(e.target.value);
                    }} type="text"/>
                </div>
                <div className="DoorEditModal__form--btns">
                    <button onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(name === editedDoor) return;
                        const data = {
                            name,
                            grade: params.id,
                            door_id: editedDoor.id
                        };
                        await editDoor(data);
                        setEditing(false);
                        setEditedDoor(null);
                    }} className="DoorEditModal__form--btn DoorEditModal__form--edit">
                        {
                            editingDoor ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ): (
                                <span>تعديل</span>
                            )
                        }
                    </button>
                    <button onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditing(false);
                        setEditedDoor(null);
                    }} className="DoorEditModal__form--btn DoorEditModal__form--cancel">الغاء</button>
                </div>
            </div>
        </div>
    );
};

export default DoorEditModal;