import {
    END_ADDING_TEACHER, END_EXCEPTING_STUDENT, END_FETCHING_GRADE_STUDENTS,
    END_FETCHING_TEACHER_ASSISTANTS,
    END_FETCHING_WALLET, FETCH_GRADE_STUDENTS, FETCH_TEACHER_ASSISTANTS,
    FETCH_WALLET, START_ADDING_TEACHER, START_EXCEPTING_STUDENT, START_FETCHING_GRADE_STUDENTS,
    START_FETCHING_TEACHER_ASSISTANTS,
    START_FETCHING_WALLET
} from "./types.actions";
import axios from "axios";
import {BASE_URL} from "../../utls/constants";
import {setAlert} from "./ui.actions";

export const startFetchingWallet = {
    type: START_FETCHING_WALLET
}

export const endFetchingWallet = {
    type: END_FETCHING_WALLET
}

export const fetchWallet = (data) => async dispatch => {
    try {
        dispatch(startFetchingWallet);
        const formData = new FormData();
        formData.append('date', data.date);
        const res = await axios.post(`${BASE_URL}/select_feis_by_month.php`, formData);
        console.log(res);
        dispatch({
            type: FETCH_WALLET,
            data: res.data
        });
        dispatch(endFetchingWallet);
    } catch (e) {
        console.log(e.message);
    }
}

export const startFetchingTeacherAssistants = {
    type: START_FETCHING_TEACHER_ASSISTANTS
}

export const endFetchingTeacherAssistants = {
    type: END_FETCHING_TEACHER_ASSISTANTS
}

export const fetchTeacherAssistants = data => async dispatch => {
    try {
        dispatch(startFetchingTeacherAssistants);
        const res = await axios.get(`${BASE_URL}select_teacher.php`);
        console.log(res);
        dispatch({
            type: FETCH_TEACHER_ASSISTANTS,
            teachers: res.data
        });
        dispatch(endFetchingTeacherAssistants);
    } catch (e) {
        console.log(e.message);
    }
}

export const startAddingTeacher = {
    type: START_ADDING_TEACHER
}

export const endAddingTeacher = {
    type: END_ADDING_TEACHER
}

export const addTeacher = data => async dispatch => {
    try {
        dispatch(startAddingTeacher);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('password', data.password);
        formData.append('money_type', data.money_type);
        const res = await axios.post(`${BASE_URL}insert_teacher.php`, formData);
        console.log(res);
        if(res.data == '3') {
            dispatch(setAlert('من فضلك ادخل بريد الكتروني غير موجود مسبقا', 'danger'));
            dispatch(endAddingTeacher);
            return res;
        } else if(res.data == '2') {
            dispatch(setAlert('من فضلك ادخل رقم هاتف غير موجود مسبقا', 'danger'));
            dispatch(endAddingTeacher);
            return res;
        }
        dispatch(setAlert('تم اضافة مساعد بنجاح', 'success'));
        await dispatch(fetchTeacherAssistants(data));
        dispatch(endAddingTeacher);
        return res;
    } catch (e) {
        console.log(e.message);
    }
}

export const startFetchingGradeStudents = {
    type: START_FETCHING_GRADE_STUDENTS
}

export const endFetchingGradeStudents = {
    type: END_FETCHING_GRADE_STUDENTS
}

export const fetchGradeStudents = data => async dispatch => {
    try {
        dispatch(startFetchingGradeStudents);
        console.log(data);
        const formData = new FormData();
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}select_student_grade.php`, formData);
        console.log(res);
        dispatch({
            type: FETCH_GRADE_STUDENTS,
            students: res.data
        })
        dispatch(endFetchingGradeStudents);
    } catch (e) {
        console.log(e.message);
    }
}

export const startExceptingStudent = {
    type: START_EXCEPTING_STUDENT
}

export const endExceptingStudent = {
    type: END_EXCEPTING_STUDENT
}

export const exceptStudent = data => async dispatch => {
    try {
        dispatch(startExceptingStudent);
        const formData = new FormData();
        formData.append('id', data.id);
        formData.append('exception_status', data.exception_status);
        const res = await axios.post(`${BASE_URL}update_student_exception_status.php`, formData);
        if(res.data == '1') {
            dispatch(setAlert('تم استثناء الطالب بنجاح', 'success'));
            dispatch(fetchGradeStudents(data));
        }
        dispatch(endExceptingStudent);
    } catch (e) {
        console.log(e.message);
    }
}