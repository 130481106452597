import React, {useEffect, useState} from 'react';
import './ExamsDailyStudents.css';
import {useParams} from "react-router-dom";
import {importAllStudentsData} from "../../../../../store/actions/groups.actions";
import {connect} from "react-redux";
import Header from "../../../../../Components/Header/Header";
import Spinner from "../../../../../Components/Spinner/Spinner";
import ExamsDailyStudent from "./ExamsDailyStudent/ExamsDailyStudent";

const ExamsDailyStudents = ({importingStudentsData, studentsData, importAllStudentsData, exams}) => {
    const [exam, setExam] = useState(null);
    const params = useParams();
    useEffect(() => {
        const data = {
            grade: params.id,
            group_id: params.group_id
        };
        importAllStudentsData(data);
    }, []);

    useEffect(() => {
        if(exams.length == 0) return;
        setExam(exams.filter(e => e.id == params.exam_id)[0]);
    }, [exams]);

    return (
        <div className={'ExamsDailyStudents'}>
            <Header text={'اضف درجات الطلاب'} />
            {
                (importingStudentsData && exam == null) ? (
                    <Spinner />
                ) : (
                    <div className={'ExamsDailyStudents__students'}>
                        {
                            studentsData?.length > 0 && studentsData.map((s, i) => (
                                <ExamsDailyStudent exam={exam} student={s} key={s.id} />
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    importingStudentsData: state.groups.importingStudentsData,
    studentsData: state.groups.studentsData,
    exams: state.exsams.exsams
})

export default connect(mapStateToProps, {importAllStudentsData}) (ExamsDailyStudents);