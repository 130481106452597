import React, {useEffect, useState} from 'react';
import './EditDoorClassForm.css';
import {connect} from "react-redux";
import {editDoorClass} from "../../../../../store/actions/doors.actions";
import {useParams} from "react-router-dom";

const EditDoorClassForm = ({openEditClass, editingDoorClass, editDoorClass, setOpenEditClass, editedClassId, setEditedClassId, classes}) => {
    const [editedClassName, setEditedClassName] = useState('');
    const params = useParams();
    useEffect(() => {
        if (!editedClassId) return setOpenEditClass(false);
        setEditedClassName(classes?.filter(c => c.id == editedClassId)[0]?.name);
        return () => {
            setEditedClassId(null);
        }
    }, [editedClassId, classes]);
    return (
        <div className={`EditDoorClassForm ${openEditClass && 'EditDoorClassForm__show'}`}>
            <form className="EditDoorClassForm__container">
                <div className="EditDoorClassForm__element">
                    <label htmlFor="name" className="EditDoorClassForm__element--label">اسم الباب</label>
                    <input value={editedClassName} onChange={e => setEditedClassName(e.target.value)} type="text" className="EditDoorClassForm__element--input"/>
                </div>
                <div className="EditDoorClassForm__btns">
                    <p onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(editedClassName == classes?.filter(c => c.id == editedClassId)[0].name) return;
                        const data = {
                            door_id: params.door_id,
                            name: editedClassName,
                            class_id: classes?.filter(c => c.id == editedClassId)[0].id
                        }
                        await editDoorClass(data);
                        setOpenEditClass(false);
                    }} className="EditDoorClassForm__btn EditDoorClassForm__edit">
                        {
                            editingDoorClass ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>تحديث</span>
                            )
                        }
                    </p>
                    <p onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenEditClass(false);
                    }} className="EditDoorClassForm__btn EditDoorClassForm__cancel">
                        الغاء
                    </p>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    editingDoorClass: state.doors.editingDoorClass
})

export default connect(mapStateToProps, {editDoorClass}) (EditDoorClassForm);