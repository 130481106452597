import React, {useEffect} from 'react';
import './Reports.css';
import {fetchGradeStudents} from "../../../store/actions/Settings.actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Header from "../../../Components/Header/Header";
import Student from "../../Grade/Groups/Group.Screen/GroupAddStudent/Student/Student";

const Reports = ({fetchGradeStudents, students}) => {
    const params = useParams();

    useEffect(() => {
        const data = {
            grade: params.id
        };

        fetchGradeStudents(data);
    }, []);
    return (
        <div className={'Reports'}>
            <Header text={'تقاريرك'} />
            <div className="Reports__students">
                {
                    students.map((student, i) => (
                        <Student reports={true} student={student} attend={true}  />
                    ))
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    students: state.settings.students
})

export default connect(mapStateToProps, {fetchGradeStudents}) (Reports);