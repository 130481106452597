import {
    ADD_GROUP_POST,
    END_ADDING_ALL_STUDENTS_TO_EXPENSES, END_ADDING_GROUP_POST,
    END_ADDING_STUDENT_TO_ABSENCE,
    END_ADDING_STUDENT_TO_EXPENSES,
    END_ADDING_STUDENT_TO_GRADE,
    END_ADDING_STUDENT_TO_GROUP,
    END_CREATING_GROUP,
    END_DELETING_GRADE_GROUP,
    END_FETCHING_ABSENCE_STUDENTS,
    END_FETCHING_GRADE_GROUPS,
    END_FETCHING_GROUP_POSTS,
    END_FETCHING_STUDENTS_EXPENSES,
    END_FETCHING_STUDENTS_TO_ADD,
    END_FETCHING_THE_GROUP_COUNT,
    END_IMPORTING_ALL_STUDENTS_DATA, END_LOADING_MORE, END_LOADING_THE_ADDED_POST,
    FETCH_ABSENCE_STUDENTS,
    FETCH_GRADE_GROUPS,
    FETCH_GROUP_POSTS,
    FETCH_STUDENTS_EXPENSES,
    FETCH_STUDENTS_TO_ADD,
    FETCH_THE_GROUP_COUNT,
    IMPORT_ALL_STUDENTS_DATA,
    RESET_GROUP_POSTS,
    START_ADDING_ALL_STUDENTS_TO_EXPENSES,
    START_ADDING_GROUP_POST,
    START_ADDING_STUDENT_TO_ABSENCE,
    START_ADDING_STUDENT_TO_EXPENSES,
    START_ADDING_STUDENT_TO_GRADE,
    START_ADDING_STUDENT_TO_GROUP,
    START_CREATING_GROUP,
    START_DELETING_GRADE_GROUP,
    START_FETCHING_ABSENCE_STUDENTS,
    START_FETCHING_GRADE_GROUPS,
    START_FETCHING_GROUP_POSTS,
    START_FETCHING_STUDENTS_EXPENSES,
    START_FETCHING_STUDENTS_TO_ADD,
    START_FETCHING_THE_GROUP_COUNT,
    START_IMPORTING_ALL_STUDENTS_DATA, START_LOADING_MORE, START_LOADING_THE_ADDED_POST
} from "./types.actions";
import axios from "axios";
import {BASE_URL} from "../../utls/constants";
import {setAlert} from "./ui.actions";
import {Form} from "react-router-dom";

export const startFetchingGradeGroups = {
    type: START_FETCHING_GRADE_GROUPS
}

export const endFetchingGradeGroups = {
    type: END_FETCHING_GRADE_GROUPS
}

export const fetchGroups = data => async dispatch => {
    try {
        dispatch(startFetchingGradeGroups);
        console.log(data);
        const formData = new FormData();
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}select_groups.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_GRADE_GROUPS,
                groups: res.data
            });
        }
        dispatch(endFetchingGradeGroups);
    } catch (e) {
        dispatch(endFetchingGradeGroups);
        console.error(e.message);
    }
}

export const startDeletingGradeGroup = {
    type: START_DELETING_GRADE_GROUP
}

export const endDeletingGradeGroup = {
    type: END_DELETING_GRADE_GROUP
}

export const deleteGradeGroup = data => async dispatch => {
    try {
        dispatch(startDeletingGradeGroup);
        const formData = new FormData();
        formData.append('group_id', data.group_id);
        const res = await axios.post(`${BASE_URL}delete_group.php`, formData);
        if(res.status == 200 && res.data == '1') {
            console.log(data);
            dispatch(setAlert('تم الحذف بنجاح', 'success'));
            dispatch(fetchGroups(data));
        }
        dispatch(endDeletingGradeGroup);
    } catch (e) {
        console.error(e.message);
        dispatch(endDeletingGradeGroup);
        dispatch('حدث خطأ حاول مرة اخري', 'danger');
    }
}

export const startCreatingGroup = {
    type: START_CREATING_GROUP
}

export const endCreatingGroup = {
    type: END_CREATING_GROUP
}

export const createGroup = data => async dispatch => {
    try {
        dispatch(startCreatingGroup);
        const formData = new FormData();
        formData.append('grade', data.grade);
        formData.append('time', data.time);
        formData.append('days', data.days);
        formData.append('group_name', data.name);
        const res = await axios.post(`${BASE_URL}insert_group.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم انشاء المجموعة بنجاح', 'success'));
            dispatch(fetchGroups(data));
        }
        dispatch(endCreatingGroup);
    } catch (e) {
        dispatch(endCreatingGroup);
        console.error(e.message);
        dispatch(setAlert('حدث خطأ برجاء المحاولة لاحقأ', 'danger'))
    }
}

export const startFetchingStudentsToAdd = {
    type: START_FETCHING_STUDENTS_TO_ADD
}

export const endFetchingStudentsToAdd = {
    type: END_FETCHING_STUDENTS_TO_ADD
}

export const fetchStudentsToAdd = data => async dispatch => {
    try {
        dispatch(startFetchingStudentsToAdd);
        const formData = new FormData();
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}select_student_for_add_to_groups.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_STUDENTS_TO_ADD,
                students: res.data
            })
        }
        dispatch(endFetchingStudentsToAdd);
    } catch (e) {
        console.error(e.message);
        dispatch(endFetchingStudentsToAdd)
    }
}

export const startFetchingGroupCount = {
    type: START_FETCHING_THE_GROUP_COUNT
}

export const endFetchingGroupCount = {
    type: END_FETCHING_THE_GROUP_COUNT
}

export const fetchGroupCount = data => async dispatch => {
    try {
        dispatch(startFetchingGroupCount);
        const formData = new FormData();
        formData.append('group_id', data.group_id);
        const res = await axios.post(`${BASE_URL}select_count_student_in_group.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_THE_GROUP_COUNT,
                count: res.data
            })
        }
        dispatch(endFetchingGroupCount);
    } catch (e) {
        console.error(e.message);
        dispatch(endFetchingGroupCount);
    }
}

export const startAddingStudentToGroup = {
    type: START_ADDING_STUDENT_TO_GROUP
}

export const endAddingStudentToGroup = {
    type: END_ADDING_STUDENT_TO_GROUP
}

export const addStudentToGroup = data => async dispatch => {
    try {
        dispatch(startAddingStudentToGroup);
        const formData = new FormData();
        formData.append('student_id', data.student_id);
        formData.append('group_id', data.group_id);
        formData.append('studnt_group_number', data.studnt_group_number);
        const res = await axios.post(`${BASE_URL}add_student_in_group.php`, formData);
        if(res.data == '1' && res.status == 200) {
            dispatch(setAlert('تم اضافة الطالب بنجاح', 'success'));
            dispatch(fetchStudentsToAdd(data));
            dispatch(fetchGroupCount(data));
        }
        dispatch(endAddingStudentToGroup);
    } catch (e) {
        console.error(e.message);
        dispatch(endAddingStudentToGroup);
    }
}

export const startAddingStudentToGrade = {
    type: START_ADDING_STUDENT_TO_GRADE
}

export const endAddingStudentToGrade = {
    type: END_ADDING_STUDENT_TO_GRADE
}

export const addStudentToGrade = data => async dispatch => {
    try {
        dispatch(startAddingStudentToGrade);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('password', data.password);
        formData.append('phone', data.phone);
        formData.append('email', data.email);
        formData.append('phase', data.grade);
        const res = await axios.post(`${BASE_URL}insert_student.php`, formData);
        if(res.data == '2') {
            dispatch(setAlert('يوجد طالب بنفس الرقم', 'danger'));
            dispatch(endAddingStudentToGrade)

            return res;
        }else if (res.data == '3') {
            dispatch(setAlert('يوجد طالب بنفس الايميل', 'danger'));
            dispatch(endAddingStudentToGrade)

            return res;
        }else if (res.data == '1') {
            dispatch(setAlert('تم اضافة الطالب بنجاح', 'success'));
            dispatch(endAddingStudentToGrade);
            dispatch(fetchStudentsToAdd(data));
            return res
        }
        dispatch(endAddingStudentToGrade)
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('حدث خطأ برجاء المحاولة لاحقا', 'danger'));
        dispatch(endAddingStudentToGrade);
    }
}

export const startFetchingAbsenceStudents = {
    type: START_FETCHING_ABSENCE_STUDENTS
}

export const endFetchingAbsenceStudents = {
    type: END_FETCHING_ABSENCE_STUDENTS
}

export const fetchAbsenceStudents = data => async dispatch => {
    try {
        dispatch(startFetchingAbsenceStudents);
        const formData = new FormData();
        formData.append('attend', data.attend);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);
        const res = await axios.post(`${BASE_URL}select_absance.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_ABSENCE_STUDENTS,
                students: res.data
            });
        }
        dispatch(endFetchingAbsenceStudents);
    } catch (e) {
        console.error(e.message);
        dispatch(endFetchingAbsenceStudents);
        dispatch(setAlert('حدث خطأ من فضلك حاول مرة اخري', 'danger'))
    }
}

export const startAddingStudentToAbsence = {
    type: START_ADDING_STUDENT_TO_ABSENCE
}

export const endAddingStudentToAbsence = {
    type: END_ADDING_STUDENT_TO_ABSENCE
}

export const addStudentToAbsence = data => async dispatch => {
    try {
        dispatch(startAddingStudentToAbsence);
        const formData = new FormData();
        formData.append('student_id', data.student_id);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);
        const res = await axios.post(`${BASE_URL}insert_absance.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(fetchAbsenceStudents(data));
        }
        dispatch(endAddingStudentToAbsence);
    } catch (e) {
        dispatch(endAddingStudentToAbsence);
        console.error(e.message);
        dispatch(setAlert('حدث خطأ برجاء المحاولة مرة اخري', 'danger'))
    }
}

export const startFetchingStudentsExpenses = {
    type: START_FETCHING_STUDENTS_EXPENSES
}

export const endFetchingStudentsExpenses = {
    type: END_FETCHING_STUDENTS_EXPENSES
}

export const fetchStudentsExpenses = data => async dispatch => {
    try {
        dispatch(startFetchingStudentsExpenses);
        const formData = new FormData();
        formData.append('pay', data.pay);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);
        const res = await axios.post(`${BASE_URL}select_student_expenses.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_STUDENTS_EXPENSES,
                students: res.data
            })
        }
        dispatch(endFetchingStudentsExpenses);
    } catch (e) {
        console.error(e.message);
        dispatch(endFetchingStudentsExpenses);
        dispatch(setAlert('حدث خطأ من فضلك حاول مجددا', 'danger'));
    }
}

export const startAddingStudentToExpenses = {
    type: START_ADDING_STUDENT_TO_EXPENSES
}

export const endAddingStudentToExpenses = {
    type: END_ADDING_STUDENT_TO_EXPENSES
}

export const addStudentToExpenses = data => async dispatch => {
    try {
        dispatch(startAddingStudentToExpenses);
        const formData = new FormData();
        formData.append('student_id', data.student_id);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}insert_student_expenses.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم اضافة الطالب بنجاح', 'success'));
            dispatch(fetchStudentsExpenses(data));
        }
        dispatch(endAddingStudentToExpenses);
    } catch (e) {
        console.error(e.message);
        dispatch(endAddingStudentToExpenses);
        dispatch(setAlert('حدث خطأ من فضلك حاول مرة اخري', 'danger'))
    }
}

export const startAddingAllStudentsToExpenses = {
    type: START_ADDING_ALL_STUDENTS_TO_EXPENSES
}

export const endAddingAllStudentsToExpenses = {
    type: END_ADDING_ALL_STUDENTS_TO_EXPENSES
}

export const addAllStudentsToExpenses = data => async dispatch => {
    try {
        dispatch(startAddingAllStudentsToExpenses);
        const formData = new FormData();
        formData.append('date', data.date);
        formData.append('group_id', data.group_id);
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}insert_all_studnt_expense.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم اضافة جميع الطلاب بنجاح', 'success'));
            dispatch(fetchStudentsExpenses(data));
        }
        dispatch(endAddingAllStudentsToExpenses);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('حدث خطأ من فضلك حاول مرة اخري', 'danger'));
        dispatch(endAddingAllStudentsToExpenses)
    }
}

export const startImportingStudentsData = {
    type: START_IMPORTING_ALL_STUDENTS_DATA
}

export const endImportingStudentsData = {
    type: END_IMPORTING_ALL_STUDENTS_DATA
}

export const importAllStudentsData = data => async dispatch => {
    try {
        dispatch(startImportingStudentsData);
        const formData = new FormData();
        formData.append('grade', data.grade);
        formData.append('group_id', data.group_id);
        const res = await axios.post(`${BASE_URL}select_student_in_group.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: IMPORT_ALL_STUDENTS_DATA,
                data: res.data
            });
        }
        dispatch(endImportingStudentsData);
    } catch (e) {
        console.error(e.message);
        dispatch(endImportingStudentsData);
    }
}

/*****************************/
/* group posts actions */
/*****************************/

export const startFetchingGroupPosts = {
    type: START_FETCHING_GROUP_POSTS
}

export const endFetchingGroupPosts = {
    type: END_FETCHING_GROUP_POSTS
}

export const startLoadingMoreData = {
    type: START_LOADING_MORE
}

export const endLoadingMoreData = {
    type: END_LOADING_MORE
}

export const fetchGroupPosts = data => async dispatch => {
    try {
        if(data.record == 0) dispatch(startFetchingGroupPosts);
        if(data.record > 0) dispatch(startLoadingMoreData);
        const formData = new FormData();
        formData.append('group_id', data.group_id);
        formData.append('init_record_no', data.record);
        const res = await axios.post(`${BASE_URL}select_post_group.php`, formData);
        if(res.status == 200) {
            dispatch({
                type: FETCH_GROUP_POSTS,
                posts: res.data
            });
        }
        dispatch(endLoadingMoreData);
        dispatch(endFetchingGroupPosts);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('حدث خطأ من فضلك حاول مرة اخري!', 'danger'))
    }
}

export const resetGroupPosts = () => ({
    type: RESET_GROUP_POSTS
});

export const startAddingGroupPost = {
    type: START_ADDING_GROUP_POST
}

export const endAddingGroupPost = {
    type: END_ADDING_GROUP_POST
}

export const startLoadingTheAddedPost = {
    type: START_LOADING_THE_ADDED_POST
}

export const endLoadingTheAddedPost = {
    type: END_LOADING_THE_ADDED_POST
}

export const addGroupPost = data => async dispatch => {
    try {
        dispatch(startAddingGroupPost);
        const formData = new FormData();
        formData.append('post_details', data.post_details);
        formData.append('group_id', data.group_id);
        formData.append('date', data.date);
        formData.append('type_uploading', data.type_uploading);
        formData.append('filename', data.filename || false);
        const res = await axios.post(`${BASE_URL}insert_post_group.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(startLoadingTheAddedPost);
            const formDataFetch = new FormData();
            formDataFetch.append('group_id', data.group_id);
            formDataFetch.append('init_record_no', 0);
            const response = await axios.post(`${BASE_URL}select_post_group.php`, formDataFetch);
            if(res.status == 200) {
                dispatch({
                    type: ADD_GROUP_POST,
                    posts: response.data
                })
            }
            dispatch(endLoadingTheAddedPost);
            dispatch(setAlert('تمت الاضافة بنجاح!', 'success'));
        }
        dispatch(endAddingGroupPost);
    } catch (e) {
        console.error(e.message);
        dispatch(endAddingGroupPost);
    }
}