import * as actionTypes from '../actions/types.actions';
import {START_CREATING_EXSAM, START_CREATING_GROUP} from "../actions/types.actions";


const inistialState ={
    exsams: [],
    fetchingExsam : false,
    creatingExsam: false,
    deletingExam: false,
    insertingDegree: false,
    marks: [],
    fetchingMarks: false
}
export default (state=inistialState,action)=>{
     switch (action.type){
         case actionTypes.START_FETCHING_EXSAM :
             return {
                 ...state,
                 fetchingExsam: true
             }
         case actionTypes.END_FETCHING_EXSAM:
             return{
                 ...state,
                 fetchingExsam: false
             }
         case actionTypes.FETCH_EXAMS:
             return {
                 ...state,
                 exsams: action.exsams
             }
         case START_CREATING_EXSAM:
             return {
                 ...state,
                 creatingExsam:true
             }
         case actionTypes.END_CREATING_EXSAM:
             return {
                 ...state,
                 creatingExsam: false
             }
         case actionTypes.START_DELETING_EXSAM:
             return {
                 ...state,
                 deletingExam: true
             }
         case actionTypes.END_DELETING_EXSAM:
             return {
                 ...state,
                 deletingExam: false
             }
         case actionTypes.UPDATE_CLASS_EXAM:
             let updatedClassExamIndex = state.exsams.findIndex(e => e.id == action.updatedExam.id);
             console.log(updatedClassExamIndex);
             state.exsams[updatedClassExamIndex] = action.updatedExam;

             return {
                 ...state,
                 exsams: [...JSON.parse(JSON.stringify(state.exsams))],
             }
         case actionTypes.START_INSERTING_STUDENT_EXAM_DEGREE:
             return {
                 ...state,
                 insertingDegree: true
             }
         case actionTypes.END_INSERTING_STUDENT_EXAM_DEGREE:
             return {
                 ...state,
                 insertingDegree: false
             }
         case actionTypes.START_FETCHING_STUDENTS_MARKS:
             return {
                 ...state,
                fetchingMarks: true
             }
         case actionTypes.END_FETCHING_STUDENTS_MARKS:
             return {
                 ...state,
                 fetchingMarks: false
             }
         case actionTypes.FETCH_STUDENTS_MARKS:
             return {
                 ...state,
                 marks: action.marks
             }
         case actionTypes.UPDATE_EXAM_STATUS:
             console.log(action);
             let updatedExamIndex = state.exsams.findIndex(e => e.id == action.exam_id);

             state.exsams[updatedExamIndex].exam_status = action.exam_status;
             return {
                 ...state,
                 exsams: [...JSON.parse(JSON.stringify(state.exsams))]
             }
         default:
             return state;
     }

  }
