import React, {useEffect, useState} from 'react';
import './ExamStudentsMarks.css';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchStudentsMarks} from "../../../../store/actions/exsam.action";
import Spinner from "../../../../Components/Spinner/Spinner";
import exsam from "../Exsam";
import Header from "../../../../Components/Header/Header";
import ExamStudentsMarksList from "./ExamStudentsMarksList/ExamStudentsMarksList";

const ExamStudentsMarks = ({fetchingClassExams, fetchingMarks, marks, fetchStudentsMarks, isClass, fetchingExsam, exams, exsams}) => {
    const [loaded, setLoaded] = useState(false);
    const [exam, setExam] = useState(null);
    const params = useParams();

    useEffect(() => {
        if(exams.filter(e => e.id == params.exam_id).length != 0) setLoaded(true);
        if(exsams.filter(e => e.id == params.exam_id).length != 0) setLoaded(true);
    }, [exams, exsams]);

    useEffect(() => {
        if(!params.class_id && exsams.length > 0) {
            setExam(exsams.filter(e => e.id == params.exam_id)[0]);
        }
        if(params.class_id && exams.length > 0) {
            setExam(exams.filter(e => e.id == params.exam_id));
        }
    }, [exams, exsams]);

    useEffect(() => {
        const data = {
            exam_id: params.exam_id
        };

        fetchStudentsMarks(data);
    }, []);
    return (
        <div className={'ExamStudentsMarks'}>
            <Header text={'درجات الطلاب'} />
            {
                (fetchingExsam || fetchingClassExams || fetchingMarks) ? (
                    <Spinner />
                ) : (
                    (loaded && exam != null) ? (
                        <ExamStudentsMarksList exam={exam} marks={marks} />
                    ) : (
                        <span>There's no exam by this id</span>
                    )
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    fetchingExsam: state.exsams.fetchingExsam,
    fetchingClassExams: state.doors.fetchingClassExams,
    exams: state.doors.exams,
    exsams: state.exsams.exsams,
    fetchingMarks: state.exsams.fetchingMarks,
    marks: state.exsams.marks
})

export default connect(mapStateToProps, {fetchStudentsMarks}) (ExamStudentsMarks);