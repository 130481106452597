import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import './Door.css';
import Header from '../../../../Components/Header/Header';
import { Outlet, useParams } from 'react-router-dom';
import {fetchDoorClasses, deleteDoorClass} from '../../../../store/actions/doors.actions';
import Spinner from '../../../../Components/Spinner/Spinner';
import ClassScreen from './Class/Class.Screen';
import DoorForm from './DoorForm/DoorForm';
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import EditDoorClassForm from "./EditDoorClassForm/EditDoorClassForm";

const Door = ({fetchingClasses, fetchDoorClasses, classes, doors, deletingDoorClass}) => {
    const [formOpen, setFormOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openEditClass, setOpenEditClass] = useState(false);
    const [deletedClassId, setDeletedClassId] = useState(null);
    const [editedClassId, setEditedClassId] = useState(null);
    const params = useParams();

    useEffect(() => {
        fetchDoorClasses(params.door_id);
    }, []);

    useEffect(() => {
        const home = document.querySelector('.Doors');
        const freezeStyles = () => {
            home.classList.add('Home__hide')
        }
        const releaseStyles = () => {
            home.classList.remove('Home__hide')
        }

        freezeStyles();

        return () => {
            releaseStyles();
        }
    }, []);

    return (
        <div className='Door'>
            <Header text={doors.filter(door => door.id === params.door_id)[0]?.name } />
            {
                !fetchingClasses ? (
                    <div className='Door__classes'>
                        {
                            classes?.length > 0 && classes?.map((c, i) => (
                                <ClassScreen setOpenEditClass={setOpenEditClass} setEditedClassId={setEditedClassId} setOpenConfirm={setOpenConfirm} setDeletedClassId={setDeletedClassId} clas={c} />
                            ))
                        }
                    </div>
                ) : (
                    <Spinner />
                )
            }
            <EditDoorClassForm editedClassId={editedClassId} setEditedClassId={setEditedClassId} classes={classes} openEditClass={openEditClass} setOpenEditClass={setOpenEditClass} />
            <ConfirmModal setDeletedClassId={setDeletedClassId} deletedClassId={deletedClassId} deletingDoorClass={deletingDoorClass}  openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} />
            <div onClick={e => setFormOpen(true)} className='Door__add-btn'>
                <p>اضافة درس<i class="fa-solid fa-plus"></i></p>
            </div>
            <DoorForm show={formOpen} setShow={setFormOpen} />
            <Outlet />
        </div>
    )
}

const mapStateToProps = (state) => ({
    fetchingClasses: state.doors.fetchingClasses,
    classes: state.doors.classes,
    doors: state.doors.doors,
    deletingDoorClass: state.doors.deletingDoorClass
});

export default connect(mapStateToProps, {fetchDoorClasses, deleteDoorClass})(Door);