import {combineReducers} from "redux";
import userReducer from "./user.reducer";
import uiReducer from "./ui.reducer";
import doorsReducer from "./doors.reducer";
import groupsReducer from "./groups.reducer";
import exsamsReducer from './exsam.reducer';
import videoReducer from './video.reducer';
import questionBankRedcer from './question.bank.reducer';
import settingsReducer from "./settings.reducer";

const initialState = {
    message: 'Hello from the store'
}

const messageReudcer = (state = initialState) => {
    return state;
}

export default combineReducers({
    message: messageReudcer,
    userData: userReducer,
    ui: uiReducer,
    doors: doorsReducer,
    groups: groupsReducer,
    exsams:exsamsReducer,
    videos: videoReducer,
    questionBank: questionBankRedcer,
    settings: settingsReducer
});