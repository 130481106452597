import React from 'react';
import './ConfirmModal.css';
import {connect} from "react-redux";
import {deleteDoorClass} from "../../../../../store/actions/doors.actions";
import {useParams} from "react-router-dom";

const ConfirmModal = ({openConfirm, setOpenConfirm, deleteDoorClass, deletedClassId, deletingDoorClass}) => {
    const params = useParams();
    return (
        <div className={`ConfirmModal ${openConfirm && 'ConfirmModal__show'}`}>
            <div className="ConfirmModal__container">
                <div className="ConfirmModal__header">
                    <p>Are you sure to delete this class?</p>
                </div>
                <div className="ConfirmModal__links">
                    <p onClick={e => {
                        e.stopPropagation();
                        setOpenConfirm(false);
                    }} className="ConfirmModal__link ConfirmModal__no">
                        <span>No</span>
                    </p>
                    <p className="ConfirmModal__link ConfirmModal__yes" onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        await deleteDoorClass(deletedClassId && deletedClassId, params.door_id && params.door_id);
                        setOpenConfirm(false);
                    }}>
                        {
                            deletingDoorClass ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>Yes</span>
                            )
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default connect(null, {deleteDoorClass}) (ConfirmModal);