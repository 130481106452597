import Spinner from "../../../../Components/Spinner/Spinner";
import React from "react";
import './videoEditList.css'
import {useState,useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import {editVideoList} from "../../../../store/actions/video.action";
import {setAlert} from "../../../../store/actions/ui.actions";

const VideoEditList=({editvideo,setEditvideo,data,editID})=>{
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const params= useParams()
    const dispatch=useDispatch()
    const edittingVideoList=useSelector(state=>state.videos.edittingVideoList)


    let item = data.find((item) =>{
        if(item.id === editID){
            return item
        }
    });
    useEffect(()=>{
     if (item===undefined){
         console.log("value note set")
     }else {
         setTitle(item.list_name)
         setDesc(item.list_details)
     }
    },[item])
    const handlebtnclick=() =>{
        setEditvideo(true)
    }

    const handleEditeClickBtn= async ()=>{
        if(title == item.list_name) return dispatch(setAlert('من فضلك قم بتعديل الاسم او اضغط الغاء', 'danger'))
      await dispatch(editVideoList(params.id,title,desc,editID))
        setEditvideo(true)
    }
    console.log(data)
    console.log("editID",editID)
    console.log("edittingVideoList",edittingVideoList)

    return(
        <div className='VideoEditList'>
            <div style={{display: editvideo ? 'none' : 'block' }} className='Video__blue--div'>
                <div style={{display: editvideo ? 'none' : 'block' }} className='Video__Container--git-info'>
                    <form className='Video__Container--form' >
                        <label className='Video__Container-lable'>اسم القائمة </label>
                        <input value={title} onChange={(e)=>setTitle(e.target.value)} className='Video__Container-input' type='text' placeholder='ادخل اسم القائمة'/>
                        <label className='Video__Container-lable'>وصف القائمة </label>
                        <textarea value={desc} onChange={(e)=>setDesc(e.target.value)}   className='Video__git-info--textarea' placeholder='ادخل وصف القائمة'/>
                    </form>
                    <div className='Video__Container--git-butons'>
                        <button onClick={handlebtnclick} className='Video__butons--btn1'><span>الغاء</span></button>
                        <button  onClick={handleEditeClickBtn} className='VideoEditList__butons--btn'>

                            {
                                edittingVideoList ? (
                                    <Spinner/>
                                ) : (
                                    <span>تعديل</span>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoEditList