import React, {useEffect, useRef} from 'react';
import './ClassExamQuestion.css';
import {connect} from "react-redux";

const ClassExamQuestion = ({question, number, creatingClassQuestion}) => {
    const formatChoices = () => {
        if(question.ques_type == 0) {
            return [
                question.first_ans,
                question.second_ans
            ]
        }else {
            return [
                question.first_ans,
                question.second_ans,
                question.third_ans,
                question.fourth_ans
            ]
        }
    }

    const questionRef = useRef();
    // useEffect(() => {
    //     if(!question || !questionRef.current) return;
    //     console.log(question.question);
    //     questionRef.current?.appendChild(convertToHTML(question.question).firstChild);
    // }, [question]);

    const convertToHTML = (xmlString) => {
        var doc = new DOMParser().parseFromString(xmlString, "text/xml");
        return doc;
    }
    return (
        <div className={'ClassExamQuestion'}>
            <p className={'ClassExamQuestion__number'}>{number}</p>
            <div className="ClassExamQuestion__details">
                <p className="ClassExamQuestion__details--type">
                    <p>نوع السؤال</p>
                    <p>
                        {
                            question.ques_type == '0' ? (
                                <span>صح ام خطأ</span>
                            ) : (
                                <span>اختيار من متعدد</span>
                            )
                        }
                    </p>
                </p>
                <p className="ClassExamQuestion__details--degree">
                    <span>درجة السؤال</span>
                    <span className={'ClassExamQuestion__details--degree-num'}>{question.quest_degree}</span>
                </p>
            </div>
            <div className="ClassExamQuestion__question">
                <p className="ClassExamQuestion__question--header">السؤال</p>
                <p ref={questionRef} dangerouslySetInnerHTML={{__html: question.question}} className="ClassExamQuestion__question--text"></p>
                {
                    question.img_url && (
                        <img src={question.img_url} alt={'question img'} />
                    )
                }
            </div>
            <div className="ClassExamQuestion__choices">
                {
                    formatChoices().map((c, i) => (
                        <p className={`ClassExamQuestion__choices--choice ${question.true_answer - 1 == i && 'ClassExamQuestion__choices--choice-correct'}`}><span>{i+1}.</span><span dangerouslySetInnerHTML={{__html: c}}></span></p>
                    ))
                }
            </div>
            <div className="ClassExamQuestion__reference">
                <p>مرجع السؤال</p>
                <p className={'ClassExamQuestion__reference--text'}>{question.refrence}</p>
                {
                    question.img_ref_url && (
                        <img src={question.img_ref_url} alt={'question ref img'} />
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    creatingClassQuestion: state.doors.creatingClassQuestion
})

export default connect(mapStateToProps) (ClassExamQuestion);