import * as actionTypes from '../actions/types.actions';

const initialState = {
    fetchingGroups: false,
    groups: [],
    deleting: false,
    creatingGroup: false,
    fetchingStudentsToAdd: false,
    students: [],
    fetchingCount: false,
    currentGroupCount: null,
    addingStudentToGroup: false,
    addingStudentToGrade: false,
    fetchingAbsences: false,
    absences: [],
    fetchingStudentsExpenses: false,
    expenses: [],
    addingAllStudentsExpenses: false,
    studentsData: [],
    importingStudentsData: false,
    fetchingPosts: false,
    morePosts: true,
    posts: [],
    record: 0,
    addingGroupPost: false,
    loadingMore: false,
    loadingTheAddedPost: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_GRADE_GROUPS:
            return {
                ...state,
                fetchingGroups: true
            }
        case actionTypes.END_FETCHING_GRADE_GROUPS:
            return {
                ...state,
                fetchingGroups: false
            }
        case actionTypes.FETCH_GRADE_GROUPS:
            return {
                ...state,
                groups: action.groups
            }
        case actionTypes.START_CREATING_GROUP:
            return {
                ...state,
                creatingGroup: true
            }
        case actionTypes.END_CREATING_GROUP:
            return {
                ...state,
                creatingGroup: false
            }
        case actionTypes.START_FETCHING_STUDENTS_TO_ADD:
            return {
                ...state,
                fetchingStudentsToAdd: true
            }
        case actionTypes.END_FETCHING_STUDENTS_TO_ADD:
            return {
                ...state,
                fetchingStudentsToAdd: false
            }
        case actionTypes.FETCH_STUDENTS_TO_ADD:
            return {
                ...state,
                students: action.students
            }
        case actionTypes.START_FETCHING_THE_GROUP_COUNT:
            return {
                ...state,
                fetchingCount: true
            }
        case actionTypes.END_FETCHING_THE_GROUP_COUNT:
            return {
                ...state,
                fetchingCount: false
            }
        case actionTypes.FETCH_THE_GROUP_COUNT:
            return {
                ...state,
                currentGroupCount: action.count
            }
        case actionTypes.START_ADDING_STUDENT_TO_GROUP:
            return {
                ...state,
                addingStudentToGroup: true
            }
        case actionTypes.END_ADDING_STUDENT_TO_GROUP:
            return {
                ...state,
                addingStudentToGroup: false
            }
        case actionTypes.START_ADDING_STUDENT_TO_GRADE:
            return {
                ...state,
                addingStudentToGrade: true
            }
        case actionTypes.END_ADDING_STUDENT_TO_GRADE:
            return {
                ...state,
                addingStudentToGrade: false
            }
        case actionTypes.START_FETCHING_ABSENCE_STUDENTS:
            return {
                ...state,
                fetchingAbsences: true
            }
        case actionTypes.END_FETCHING_ABSENCE_STUDENTS:
            return {
                ...state,
                fetchingAbsences: false
            }
        case actionTypes.FETCH_ABSENCE_STUDENTS:
            return {
                ...state,
                absences: action.students
            }
        case actionTypes.START_FETCHING_STUDENTS_EXPENSES:
            return {
                ...state,
                fetchingStudentsExpenses: true
            }
        case actionTypes.END_FETCHING_STUDENTS_EXPENSES:
            return {
                ...state,
                fetchingStudentsExpenses: false
            }
        case actionTypes.FETCH_STUDENTS_EXPENSES:
            return {
                ...state,
                expenses: action.students
            }
        case actionTypes.START_ADDING_ALL_STUDENTS_TO_EXPENSES:
            return {
                ...state,
                addingAllStudentsExpenses: true
            }
        case actionTypes.END_ADDING_ALL_STUDENTS_TO_EXPENSES:
            return {
                ...state,
                addingAllStudentsExpenses: false
            }
        case actionTypes.START_IMPORTING_ALL_STUDENTS_DATA:
            return {
                ...state,
                importingStudentsData: true
            }
        case actionTypes.END_IMPORTING_ALL_STUDENTS_DATA:
            return {
                ...state,
                importingStudentsData: false
            }
        case actionTypes.IMPORT_ALL_STUDENTS_DATA:
            return {
                ...state,
                studentsData: action.data
            }
        case actionTypes.START_FETCHING_GROUP_POSTS:
            return {
                ...state,
                fetchingPosts: true
            }
        case actionTypes.END_FETCHING_GROUP_POSTS:
            return {
                ...state,
                fetchingPosts: false
            }
        case actionTypes.FETCH_GROUP_POSTS:
            return {
                ...state,
                posts: [...state.posts, ...action.posts.posts],
                record: action.posts.posts.length >= 50 ? state.record + 50 : state.record,
                morePosts: action.posts.posts.length >= 50
            }
        case actionTypes.RESET_GROUP_POSTS:
            return {
                ...state,
                posts: [],
                record: 0,
                morePosts: true
            }
        case actionTypes.START_ADDING_GROUP_POST:
            return {
                ...state,
                addingGroupPost: true
            }
        case actionTypes.END_ADDING_GROUP_POST:
            return {
                ...state,
                addingGroupPost: false
            }
        case actionTypes.ADD_GROUP_POST:
            const removeDuplicates = arr => {
                return arr.filter((item, index, self) => index === self.findIndex(t => (
                    t.id === item.id
                )));
            }
            return {
                ...state,
                posts: removeDuplicates([...action.posts.posts, ...state.posts])
            }
        case actionTypes.START_LOADING_THE_ADDED_POST:
            return {
                ...state,
                loadingTheAddedPost: true
            }
        case actionTypes.END_LOADING_THE_ADDED_POST:
            return {
                ...state,
                loadingTheAddedPost: false
            }
        case actionTypes.START_LOADING_MORE:
            return {
                ...state,
                loadingMore: true
            }
        case actionTypes.END_LOADING_MORE:
            return {
                ...state,
                loadingMore: false
            }
        default:
            return state;
    }
}