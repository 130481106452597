import React from 'react';
import './ClassExamModal.css';
import {connect} from "react-redux";
import {deleteClassExam, updateExamStatus} from "../../../../../../../../store/actions/doors.actions";
import {Link, useNavigate, useParams} from "react-router-dom";
import {setAlert} from "../../../../../../../../store/actions/ui.actions";
import {fetchExsam, deletExsam} from "../../../../../../../../store/actions/exsam.action";

const ClassExamModal = ({currentExam, updatingStatus, deletingExam, fetchExsam, link, deletExsam, setCurrentExam, setAlert, updateExamStatus, deletingClassExam, modal, setModal, deleteClassExam}) => {
    const params = useParams();
    const navigate = useNavigate();
    console.log(updatingStatus);
    return (
        <div className={`ClassExamModal ${modal && 'ClassExamModal__show'}`}>
            <div className="ClassExamModal__container">
                <div className="ClassExamModal__options">
                    <div onClick={async e => {
                        console.log(currentExam)
                        if(params.class_id) {
                            await deleteClassExam(currentExam?.id && currentExam.id, params.id, params.class_id || 0);
                        } else {
                            console.log('no class')
                            await deletExsam(0, params.id, currentExam?.id);
                        }
                        setModal(false);
                    }} className="ClassExamModal__option">
                        {
                            (deletingClassExam || deletingExam) ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ): (
                                <span>مسح</span>
                            )
                        }
                    </div>
                    <div onClick={e => {
                        currentExam?.exam_status == '4' && setAlert('هذا الاختبار يومي', 'danger');
                        setModal(false);
                        currentExam?.exam_status != '4' && navigate(`${currentExam?.id && currentExam.id}/details`)
                    }} className="ClassExamModal__option">مشاهدة الاختبار</div>
                    <div onClick={e => {
                        if(!params.class_id) {
                            navigate(`/grade/${params.id}/exsam/${currentExam.id}/students/marks`);
                        }
                        setModal(false);
                    }} className="ClassExamModal__option">مشاهدة درجات الطلاب</div>
                    {
                        currentExam?.exam_status != 0 && (
                            <div onClick={async e => {
                                const data = {
                                    exam_status: 0,
                                    exam_id: currentExam?.id,
                                    exam_type: params.class_id ? false : true,
                                    exam_mark: currentExam?.exam_mark
                                };
                                await updateExamStatus(data);
                                setModal(false);
                            }} className="ClassExamModal__option">
                                {
                                    updatingStatus ? (
                                        <i className="fa-solid fa-circle-notch"></i>
                                    ) : (
                                        <span>تغيير حالة الامتحان</span>
                                    )
                                }
                            </div>

                        )
                    }
                </div>
                <div className="ClassExamModal__btn">
                    <button onClick={e => setModal(false)}>الغاء</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    deletingClassExam: state.doors.deletingClassExam,
    currentExam: state.doors.currentExam,
    deletingExam: state.exsams.deletingExam,
    updatingStatus: state.doors.updatingStatus
})

export default connect(mapStateToProps, {deleteClassExam, deletExsam, fetchExsam, setAlert, updateExamStatus}) (ClassExamModal);