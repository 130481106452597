import {
    END_CREATING_EXSAM, END_CREATING_VIDEO,
    END_DELETING_EXSAM, END_EDITING_DOOR, END_EDITING_VIDEO, END_EDITING_VIDEO_LIST,
    END_FETCHING_EXSAM, END_FETCHING_VIDEO, END_LIST_DELETING_VIDEO,
    FETCH_EXAMS, FETCH_VIDEO,
    START_CREATING_EXSAM, START_CREATING_VIDEO,
    START_DELETING_EXSAM, START_EDITING_DOOR, START_EDITING_VIDEO, START_EDITING_VIDEO_LIST,
    START_FETCHING_EXSAM, START_FETCHING_VIDEO, START_LIST_DELETING_VIDEO,
} from "./types.actions";
import axios from "axios";
import {fetchExsam, startCreatingExsam} from "./exsam.action";
import {BASE_URL} from "../../utls/constants";
import {setAlert} from "./ui.actions";
import {endEditingDoor, fetchClassVideos, fetchDoors, startEditingDoor} from "./doors.actions";



export const startFetchingVideo = {
    type: START_FETCHING_VIDEO
}

export const endFetchingVideo = {
    type: END_FETCHING_VIDEO
}
export const fetchVideo = (grade )=> async dispatch => {
    try {
        dispatch(startFetchingVideo);
        const formData = new FormData();
        formData.append('grade', grade);
        const res = await axios.post(`${BASE_URL}select_viedo_list.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status === 200) {
            console.log("res",res.data);
            dispatch({
                type: FETCH_VIDEO,
                videos:res.data
            });
            dispatch(endFetchingVideo)
        }
    } catch(e) {
        console.error(e.message);
    }
}

const startCreatingVideo={
    type:START_CREATING_VIDEO
};
const endCreatingVideo={
    type:END_CREATING_VIDEO
};
export const createVideo = ( grade,list_details,list_name) => async dispatch => {
    try {
        dispatch(startCreatingVideo);
        const formData = new FormData();
        formData.append('grade', grade);
        formData.append('list_details', list_details);
        formData.append('list_name', list_name);
        const res = await axios.post(`${BASE_URL}insert_viedo_list.php`, formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        if(res.status === 200) {
            console.log("res6",res);
            dispatch(fetchVideo(grade));
        }
        dispatch(endCreatingVideo);
    } catch (e) {
        console.error(e.message);
    }
}
export const startListDeletingVideo={
    type:START_LIST_DELETING_VIDEO
}

export const endDeletingListVideo= {
    type:END_LIST_DELETING_VIDEO
}

export const deletVideoList=( grade,list_id)=>async(dispatch)=>{
    try{
        dispatch(startListDeletingVideo)
        const formData= new FormData();
        formData.append('grade',grade)
        formData.append('list_id',list_id)

        const res = await axios.post(`${BASE_URL}delete_viedo_list.php` ,formData,{
            headers :{
                'Content-Type': 'application/form-data'
            }
        });
        if(res.status === 200) {
            console.log("res6",res);
            dispatch(fetchVideo(grade));
        }
        dispatch(endDeletingListVideo)

    } catch (e) {
        console.error(e.message)

    }
}
export const startEditingVideoList = {
    type: START_EDITING_VIDEO_LIST
}

export const endEditingVideoList = {
    type: END_EDITING_VIDEO_LIST
}

export const editVideoList = (grade,list_name,list_details,list_id) => async dispatch => {
    try {
        dispatch(startEditingVideoList);
        const formData = new FormData();
        formData.append('list_id', list_id);
        formData.append('list_details', list_details);
        formData.append('list_name', list_name);
        formData.append('grade',grade);
        console.log(formData);
        const res = await axios.post(`${BASE_URL}update_viedo_list.php`, formData);
        if(res.status === 200 && res.data === '1') {
            dispatch(setAlert('تم التعديل بنجاح', 'success'));
            dispatch(fetchVideo(grade));
        }
        dispatch(endEditingVideoList);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('يوجد خطأ حاول مرة اخري', 'danger'));
        dispatch(endEditingVideoList);
    }
}


