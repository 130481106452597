import Student from "../../../../assets/images/addStudent.avif"
import Atendance from "../../../../assets/images/atendance.jpg"
import Post from "../../../../assets/images/post.jpg"
import Mony from "../../../../assets/images/mony.png"
import Excel from "../../../../assets/images/Excel.jfif"
import {connect, useSelector} from "react-redux";
import {Link, Outlet, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {deleteGroupe} from "../../../../store/actions/groups.actions";
import {useNavigate} from "react-router-dom";
import {setAlert} from "../../../../store/actions/ui.actions";
import './Group.css';
import {fetchGroupCount} from "../../../../store/actions/groups.actions";

const Group=({groups, exams, ExamsDailyFormOpen, setExamsDailyOpen, currentGroupCount, fetchingCount, fetchGroupCount})=>{
    const [group, setGroup] = useState(null);
    const navigate=useNavigate()
    const {userId}  = useParams();
    const params = useParams();

    useEffect(() => {
        setGroup(groups.filter((group)=>{
            return group.id == params.group_id;
        })[0]);
    }, [groups]);

    useEffect(() => {
        if(!group) return;
        console.log(group);
        const data = {
            group_id: params.group_id
        }
        fetchGroupCount(data);
    }, [group]);

    return(
        <div className="GroupScreen">
            <div className="GroupScreen__container">
                <div className="GroupScreenDetails__header">
                   <div className="GroupScreenDetails__header--container">
                       <h3 className="GroupScreenDetails__header--name">{group?.group_name}</h3>
                       <div className="GroupScreenDetails__Counter">
                           <h3 className="GroupScreenDetails__header--count">اجمالي عدد الطلاب </h3>
                           <div className="GroupScreenDetails__header--counter">
                               <h3 className="GroupScreenDetails__header--countertext">
                                   {
                                       fetchingCount ? (
                                           <span><i className="fa-solid fa-circle-notch"></i></span>
                                       ) : (
                                           <span>{currentGroupCount && currentGroupCount}</span>
                                       )
                                   }
                               </h3>
                           </div>
                       </div>
                   </div>
                </div>
                <div className="GroupScreenDetails__bottom">
                    <div className="GroupScreenDetails__bottom--container">
                        <Link to={`add-student`} className="GroupScreenDetails__bottom--list">
                            <img className="GroupScreenDetails__bottom--listImage" src={Student} alt="student image"/>
                            <h3 className="GroupScreenDetails__bottom--listtext">اضافه طالب</h3>
                        </Link>
                        <Link to={'absence'} className="GroupScreenDetails__bottom--list">
                            <img className="GroupScreenDetails__bottom--listImage"  src={Atendance} alt="student image"/>
                            <h3 className="GroupScreenDetails__bottom--listtext"> الحضور و الغياب</h3>
                        </Link>
                        <Link to={'expenses'} className="GroupScreenDetails__bottom--list">
                            <img className="GroupScreenDetails__bottom--listImage"  src={Mony} alt="student image"/>
                            <h3 className="GroupScreenDetails__bottom--listtext" >المصروفات</h3>
                        </Link>
                        <Link to={'posts'} className="GroupScreenDetails__bottom--list">
                            <img className="GroupScreenDetails__bottom--listImage"  src={Post} alt="student image"/>
                            <h3 className="GroupScreenDetails__bottom--listtext"> منشور جديد</h3>
                        </Link>
                        <Link to={'students/data'} className="GroupScreenDetails__bottom--list">
                            <img className="GroupScreenDetails__bottom--listImage"  src={Excel} alt="student image"/>
                            <h3 className="GroupScreenDetails__bottom--listtext"> Exsel ملف</h3>
                        </Link>
                    </div>

                </div>
            </div>
            <Outlet />
        </div>
    )
}

const mapStateToProps = state => ({
    groups: state.groups.groups,
    fetchingCount: state.groups.fetchingCount,
    currentGroupCount: state.groups.currentGroupCount
})

export default connect(mapStateToProps, {fetchGroupCount}) (Group);