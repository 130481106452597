import axios from "axios";
import {
    CREATE_CLASS,
    END_CREATING_CLASS,
    END_CREATING_CLASS_VIDEO,
    END_CREATING_CLASS_VIDOE,
    END_CREATING_DOOR,
    END_FETCHING_CLASS_EXAMS,
    END_FETCHING_CLASS_PDFS,
    END_FETCHING_CLASS_VIDEOS,
    END_FETCHING_DOOR_CLASSESS,
    END_FETCHING_GRADE_DOORS,
    END_UPLOADING_CLASS_PDF,
    FETCHING_CLASS_VIDEOS,
    FETCH_CLASS_EXAMS,
    FETCH_CLASS_PDFS,
    FETCH_DOOR_CLASSES,
    FETCH_GRADE_DOORS,
    START_CREATING_CLASS,
    START_CREATING_CLASS_VIDEO,
    START_CREATING_DOOR,
    START_FETCHING_CLASS_EXAMS,
    START_FETCHING_CLASS_PDFS,
    START_FETCHING_CLASS_VIDEOS,
    START_FETCHING_DOOR_CLASSES,
    START_FETCHING_GRADE_DOORS,
    START_UPLOADING_CLASS_PDF,
    START_CREATING_CLASS_EXAM,
    END_CREATING_CLASS_EXAM,
    CREATE_CLASS_EXAM,
    START_DELETING_CLASS_EXAM,
    END_DELETING_CLASS_EXAM,
    START_FETCHING_CLASS_QUESTIONS,
    END_FETCHING_CLASS_QUESTIONS,
    FETCH_CLASS_QUESTIONS,
    CHANGE_CLASS_CURRENT_EXAM,
    START_CREATING_CLASS_EXAM_QUESTION,
    END_CREATING_CLASS_EXAM_QUESTION,
    CREATE_CLASS_EXAM_QUESTION,
    START_UPDATING_CLASS_EXAM,
    END_UPDATING_CLASS_EXAM,
    UPDATE_CLASS_EXAM,
    TOGGLE_CLASS_EXAM_SAVER,
    START_DELETING_DOOR_CLASS,
    END_DELETING_DOOR_CLASS,
    START_EDITING_DOOR_CLASS,
    END_EDITING_DOOR_CLASS,
    START_DELETING_DOOR,
    END_DELETING_DOOR,
    START_EDITING_DOOR,
    END_EDITING_DOOR,
    START_DELETING_VIDEO,
    END_DELETING_VIDEO,
    START_EDITING_VIDEO,
    END_EDITING_VIDEO,
    START_DELETING_PDF,
    END_DELETING_PDF,
    START_EDITING_PDF,
    END_EDITING_PDF, START_UPDATING_EXAM_STATUS, END_UPDATING_EXAM_STATUS, UPDATE_EXAM_STATUS, UPDATE_CLASS_EXAM_STATUS
} from "./types.actions"
import { BASE_URL } from "../../utls/constants";
import { setAlert } from "./ui.actions";
import {Form} from "react-router-dom";
import videoList from "../../Screens/Grade/video/videoList/VideoList";
import {fetchQuestionBank} from "./question.bank.actions";

export const fetchDoors = grade => async dispatch => {
    try {
        dispatch(startFetchingDoors);
        
        const formData = new FormData();
        formData.append('grade', Number(grade));

        const res = await axios.post(`${BASE_URL}select_doors.php`, formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });

        dispatch({
            type: FETCH_GRADE_DOORS,
            doors: res.data
        })
        dispatch(endFetchingDoors);
    } catch(e) {
        console.error(e.message);
    }
}

export const startCreatingDoor = {
    type: START_CREATING_DOOR
}

export const endCreatingDoor = {
    type: END_CREATING_DOOR
}

export const createDoor = (name, grade) => async dispatch => {
    try {
        dispatch(startCreatingDoor);
        const formData = new FormData();
        formData.append('grade', grade);
        formData.append('name', name);
        const res = await axios.post(`${BASE_URL}insert_door.php`, formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(res);
        if(res.status === 200 && res.data === '1') {
            dispatch(setAlert('تم انشاء الباب بنجاح', 'success'))
            dispatch(fetchDoors(grade));   
        }
        dispatch(endCreatingDoor);
    } catch (e) {
        console.error(e.message);
    }
}

export const startFetchingDoorClasses ={
    type: START_FETCHING_DOOR_CLASSES
}

export const endFetchingDoorClasses ={
    type: END_FETCHING_DOOR_CLASSESS
}

export const fetchDoorClasses = door_id => async dispatch => {
    try {
        dispatch(startFetchingDoorClasses);
        const formData = new FormData();
        formData.append('door_id', door_id);
        const res = await axios.post(`${BASE_URL}select_classes.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        dispatch({
            type: FETCH_DOOR_CLASSES,
            classes: res.data
        });
        dispatch(endFetchingDoorClasses);
    } catch(e) {
        console.error(e.message);
    }
}

export const startFetchingDoors = {
    type: START_FETCHING_GRADE_DOORS
}

export const endFetchingDoors = {
    type: END_FETCHING_GRADE_DOORS
}

export const startCreatingClass = {
    type: START_CREATING_CLASS
}

export const endCreatingClass = {
    type: END_CREATING_CLASS
}

export const createClass = (door_id, name) => async dispatch => {
    try{
        dispatch(startCreatingClass);
        const formData = new FormData();
        formData.append('door_id', door_id);
        formData.append('name', name);
        const res = await axios.post(`${BASE_URL}insert_class.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200 && res.data == '1') {
            dispatch({
                type: CREATE_CLASS
            });
            dispatch(fetchDoorClasses(door_id));
            dispatch(setAlert('تم انشاء الدرس بنجاح', 'success'));
        } else {

        }
        
        dispatch(endCreatingClass);
    } catch (e) {
        console.error(e.message);
    }
}

export const startCreatingClassVideo = {
    type: START_CREATING_CLASS_VIDEO
}

export const endCreatingClassVideo = {
    type: END_CREATING_CLASS_VIDEO
}

export const insertClassVideo = data => async dispatch => {
    try {
        dispatch(startCreatingClassVideo);
        const formData = new FormData();
        formData.append('desc_video', data.desc);
        formData.append('video_list_id', data.videoListId);
        formData.append('title_video', data.title);
        formData.append('viedo_url', data.url);
        formData.append('class_id', data.class_id);
        const res = await axios.post(`${BASE_URL}insert_viedo_details.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.data === '1' && res.status == 200) {
            console.log(res);
            dispatch(fetchClassVideos(data.class_id, data.videoListId));
            dispatch(setAlert('تم رفع الفديو بنجاح', 'success'))
        }
        dispatch(endCreatingClassVideo);
    } catch(e) {
        console.error(e.message);
        dispatch(endCreatingClassVideo);
        dispatch(setAlert('حدث خطأ من فضلك حاول مرة اخري', 'danger'));
    }
}

export const startFetchingClassVideos = {
    type: START_FETCHING_CLASS_VIDEOS
}

export const endFetchingClassVideos = {
    type: END_FETCHING_CLASS_VIDEOS
}

export const fetchClassVideos = (class_id, videoListId) => async dispatch => {
    try {
        dispatch(startFetchingClassVideos);
        const formData = new FormData();
        formData.append('class_id', class_id);
        formData.append('video_list_id', videoListId);
        const res = await axios.post(`${BASE_URL}select_videos.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status === 200) {
            dispatch({
                type: FETCHING_CLASS_VIDEOS,
                videos: res.data
            })
        }
        dispatch(endFetchingClassVideos);
    } catch(e) {
        console.error(e.message);
    }
}

export const startFetchingClassPdfs = () => ({
    type: START_FETCHING_CLASS_PDFS
});

export const endFetchingClassPdfs = () => ({
    type: END_FETCHING_CLASS_PDFS
});

export const fetchClassPdfs = (grade, class_id) => async dispatch => {
    try {
        dispatch(startFetchingClassPdfs());
        const formData = new FormData();
        formData.append('grade', grade);
        formData.append('class_id', class_id);
        const res = await axios.post(`${BASE_URL}select_pdfs.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200) {
            dispatch({
                type: FETCH_CLASS_PDFS,
                pdfs: res.data
            });
        }
        dispatch(endFetchingClassPdfs());
    } catch(e) {
        console.error(e.message);
    }
}


export const startUploadingClassPdf = {
    type: START_UPLOADING_CLASS_PDF
}

export const endUploadingClassPdf = {
    type: END_UPLOADING_CLASS_PDF
}

export const uploadClassPdf = data => async dispatch => {
    try {
        dispatch(startUploadingClassPdf);
        const formData = new FormData();
        formData.append('filename', data.file, data.file.name);
        // formData.append('filename', 'reusem');
        formData.append('grade', data.grade);
        formData.append('name', data.name);
        formData.append('class_id', data.class_id);
        const res = await axios.post(`${BASE_URL}upload_pdf.php`, formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });

        dispatch(endUploadingClassPdf);
        if(res.status == 200 && res.data == '1') {
            dispatch(fetchClassPdfs(data.grade, data.class_id));
        }
    } catch(e) {
        console.error(e.message);
    }
}

export const startFetchingClassExams = {
    type: START_FETCHING_CLASS_EXAMS
}

export const endFetchingClassExams = {
    type: END_FETCHING_CLASS_EXAMS
}

export const fetchClassExams = data => async dispatch => {
    try{
        dispatch(startFetchingClassExams);
        const formData = new FormData();
        formData.append('grade', data.grade);
        formData.append('class_id', data.class_id);
        const res = await axios.post(`${BASE_URL}select_exams.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        })
        if(res.status == 200) {
            dispatch({
                type: FETCH_CLASS_EXAMS,
                exams: res.data
            });
        }
        dispatch(endFetchingClassExams)
    } catch(e) {
        console.error(e.message);
    }
}

export const startCreatingClassExam = {
    type: START_CREATING_CLASS_EXAM
}

export const endCreatingClasExam = {
    type: END_CREATING_CLASS_EXAM
}

export const createClassExam = (data, navigate, door_id) => async dispatch => {
    try{
        dispatch(startCreatingClassExam);
        const formData = new FormData();
        formData.append('exam_name', data.exam_name);
        formData.append('exam_description', data.exam_description);
        formData.append('grade', data.grade);
        formData.append('exam_mark', data.exam_mark);
        formData.append('class_id', data.class_id);
        formData.append('exam_time', data.exam_time);
        formData.append('exam_status', 0);
        const res = await axios.post(`${BASE_URL}insert_exam_class.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });

        if(res.status == 200) {
            // dispatch({
            //     type: CREATE_CLASS_EXAM,
            //     newExam: res.data
            // });
            dispatch(fetchClassExams({
                grade: data.grade,
                class_id: data.class_id
            }));
            dispatch(setAlert('تم انشاء الاداء', 'success'));
            navigate(`/grade/${data.grade}/doors/${door_id}/class/${data.class_id}/exams/${res.data.id}/details/add`);
        }
        dispatch(endCreatingClasExam);
    } catch (e) {
        console.error(e.message)
    }
}

export const startDeletingClassExam = {
    type: START_DELETING_CLASS_EXAM
}

export const endDeletingClassExam = {
    type: END_DELETING_CLASS_EXAM
}

export const deleteClassExam = (exam_id, grade, class_id) => async dispatch => {
    try {
        dispatch(startDeletingClassExam);
        console.log(exam_id, grade, class_id)
        const formData = new FormData();
        formData.append('exam_id', exam_id);
        const res = await axios.post(`${BASE_URL}delete_exam.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        })
        if(res.status == 200 && res.data == '1') {
            const data = {
                class_id,
                grade
            }
            dispatch(setAlert('تم الحذف بنجاح', 'success'))
            dispatch(fetchClassExams(data));
        }
        dispatch(endDeletingClassExam);
    } catch (e) {
        console.error(e.message);
    }
}

export const startFetchingClassQuestions = {
    type: START_FETCHING_CLASS_QUESTIONS
}

export const endFetchingClassQuestions = {
    type: END_FETCHING_CLASS_QUESTIONS
}

export const fetchClassQuestions = exam_id => async dispatch => {
    try {
        dispatch(startFetchingClassQuestions);
        const formData = new FormData();
        formData.append('exam_id', exam_id);
        const res = await axios.post(`${BASE_URL}select_exam_question.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });

        if(res.status === 200) {
            console.log(res.data);
            dispatch({
                type: FETCH_CLASS_QUESTIONS,
                questions: res.data
            });
            dispatch(endFetchingClassQuestions);
        }
    } catch (e) {
        console.error(e.message);
    }
}

export const changeClassCurrentExam = exam => {
    return {
        type: CHANGE_CLASS_CURRENT_EXAM,
        exam
    }
}

export const startCreatingClassExamQuestion = {
    type: START_CREATING_CLASS_EXAM_QUESTION
}

export const endCreatingClassExamQuestion = {
    type: END_CREATING_CLASS_EXAM_QUESTION
}

export const createClassExamQuesiton = data => async dispatch => {
    try{
        dispatch(startCreatingClassExamQuestion);
        const formData = new FormData();
        formData.append('exam_id', data.exam_id);
        formData.append('question', data.question);
        formData.append('refrence', data.refrence);
        formData.append('first_ans', data.first_ans);
        formData.append('second_ans', data.second_ans);
        formData.append('third_ans', data.third_ans);
        formData.append('fourth_ans', data.fourth_ans);
        formData.append('ques_type', data.ques_type);
        formData.append('quest_degree', data.quest_degree);
        formData.append('type_uploading', data.type_uploading);
        formData.append('class_id', data.class_id);
        formData.append('image_data_ques', data.image_data_ques);
        formData.append('image_data_ref', data.image_data_ref);
        formData.append('true_answer', data.true_answer);
        formData.append('id', data.id);
        console.log(data);
        const res = await axios.post(`${BASE_URL}insert_question.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });

        if(res.status == 200 && data.exam_id != 0) {
            dispatch({
                type: CREATE_CLASS_EXAM_QUESTION,
                question: res.data
            });
            fetchClassQuestions(data.exam_id);
            dispatch(setAlert('تمت الاضافة بنجاح', 'success'));
            dispatch(endCreatingClassExamQuestion);
        }else if(res.status == 200 && data.exam_id == 0) {
            await dispatch(fetchQuestionBank(data));
            dispatch(setAlert('تمت الاضافة بنجاح', 'success'));
            dispatch(endCreatingClassExamQuestion);

        }
    } catch (e) {
        console.error(e.message);
    }
}

export const startUpdatingClassExam = {
    type: START_UPDATING_CLASS_EXAM
}

export const endUpdatingClassExam = {
    type: END_UPDATING_CLASS_EXAM
}

export const updateClassExam = data => async dispatch => {
    try {
        dispatch(startUpdatingClassExam);
        const date = new Date();

        let currentDay= String(date.getDate()).padStart(2, '0');

        let currentMonth = String(date.getMonth()+1).padStart(2,"0");

        let currentYear = date.getFullYear();

        let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;

        const now = new Date().toString()
        const formData = new FormData();
        formData.append('exam_name', data.exam_name);
        formData.append('exam_description', data.exam_description);
        formData.append('grade', data.grade);
        formData.append('date', currentDate);
        formData.append('exam_id', data.exam_id);
        formData.append('exam_mark', data.exam_mark);
        formData.append('class_id', data.class_id);
        formData.append('exam_time', data.exam_time);
        formData.append('exam_status', 1);
        const res = await axios.post(`${BASE_URL}update_exam_class.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200) {
            dispatch({
                type: UPDATE_CLASS_EXAM,
                updatedExam: res.data
            });
            dispatch(setAlert('تم تحديث الاختبار', 'success'));
            dispatch(endUpdatingClassExam);
        }
    } catch (e) {
        console.error(e.message)
    }
}

export const startDeletingDoorClass = {
    type: START_DELETING_DOOR_CLASS
}

export const endDeletingDoorClass = {
    type: END_DELETING_DOOR_CLASS
}

export const deleteDoorClass = (class_id, door_id) => async dispatch => {
    try{
        dispatch(startDeletingDoorClass);
        const formData = new FormData();
        formData.append('classe_id', class_id);
        const res = await axios.post(`${BASE_URL}delete_class.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200 && res.data == '1') {
            dispatch(fetchDoorClasses(door_id));
            dispatch(setAlert('تم مسح الدرس بنجاح', 'success'));
            dispatch(endDeletingDoorClass);
        }
    } catch (e) {
        console.error(e.message);
    }
}

export const startEditingDoorClass = {
    type: START_EDITING_DOOR_CLASS
}

export const endEditingDoorClass = {
    type: END_EDITING_DOOR_CLASS
}

export const editDoorClass = (data) => async dispatch => {
    try {
        dispatch(startEditingDoorClass);
        const formData = new FormData();
        formData.append('classe_id', data.class_id);
        formData.append('name', data.name);
        const res = await axios.post(`${BASE_URL}update_class.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم التعديل بنجاح', 'success'));
            dispatch(fetchDoorClasses(data.door_id));
        }
        dispatch(endEditingDoorClass);
    } catch (e) {
        console.error(e.message);
    }
}

export const startDeletingDoor = {
    type: START_DELETING_DOOR
}

export const endDeletingDoor = {
    type: END_DELETING_DOOR
}

export const deleteDoor = data => async dispatch => {
    try {
        dispatch(startDeletingDoor);
        const formData = new FormData();
        formData.append('door_id', data.door_id);
        const res = await axios.post(`${BASE_URL}delete_door.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم مسح الباب بنجاح', 'success'));
            dispatch(fetchDoors(data.grade))
        }
        dispatch(endDeletingDoor);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('يوجد خطأ جرب مرة اخري', 'danger'));
        dispatch(endDeletingDoor);
    }
}

export const startEditingDoor = {
    type: START_EDITING_DOOR
}

export const endEditingDoor = {
    type: END_EDITING_DOOR
}

export const editDoor = data => async dispatch => {
    try {
        dispatch(startEditingDoor);
        const formData = new FormData();
        formData.append('door_id', data.door_id);
        formData.append('name', data.name);
        const res = await axios.post(`${BASE_URL}update_door.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم التعديل', 'success'));
            dispatch(fetchDoors(data.grade));
        }
        dispatch(endEditingDoor);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('يوجد خطأ حاول مجددا', 'danger'));
        dispatch(endEditingDoor);
    }
}

export const startDeletingVideo = {
    type: START_DELETING_VIDEO
}

export const endDeletingVideo = {
    type: END_DELETING_VIDEO
}

export const deleteVideo = data => async dispatch => {
    try {
        dispatch(startDeletingVideo);
        const formData = new FormData();
        formData.append('id', data.id);
        const res = await axios.post(`${BASE_URL}delete_viedo_details.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم الحذف', 'success'));
            dispatch(fetchClassVideos(data.class_id, data.videoListId));
        }
        dispatch(endDeletingVideo);
    } catch (e) {
        console.error(e.message);
        dispatch(endDeletingVideo);
        dispatch(setAlert('يوجد خطأ حاول مرة اخري', 'danger'))
    }
}

export const startEditingVideo = {
    type: START_EDITING_VIDEO
}

export const endEditingVideo = {
    type: END_EDITING_VIDEO
}

export const editVideo = data => async dispatch => {
    try {
        dispatch(startEditingVideo);
        const formData = new FormData();
        formData.append('video_list_id', data.videoListId);
        formData.append('id', data.id);
        formData.append('title_video', data.title);
        formData.append('viedo_url', data.url);
        formData.append('desc_video', data.desc);
        console.log(formData);
        const res = await axios.post(`${BASE_URL}update_viedo_details.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم التعديل بنجاح', 'success'));
            dispatch(fetchClassVideos(data.class_id, data.videoListId));
        }
        dispatch(endEditingVideo);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('يوجد خطأ حاول مرة اخري', 'danger'));
        dispatch(endEditingVideo);
    }
}

export const startDeletingPdf = {
    type: START_DELETING_PDF
}

export const endDeletingPdf = {
    type: END_DELETING_PDF
}

export const deletePdf = data => async dispatch => {
    try {
        dispatch(startDeletingPdf);
        const formData = new FormData();
        formData.append('id', data.id);
        const res = await axios.post(`${BASE_URL}delete_pdf_file.php`, formData);
        if(res.status == 200 && res.data == '1') {
            dispatch(setAlert('تم الحذف بنجاح', 'success'));
            dispatch(fetchClassPdfs(data.grade, data.class_id));
        }
        dispatch(endDeletingPdf);
    } catch (e) {
        console.error(e.message);
        dispatch(setAlert('يوجد خطأ حاول مرة اخري', 'danger'));
        dispatch(endDeletingPdf);
    }
}

export const startEditingPdf = {
    type: START_EDITING_PDF
}

export const endEditingPdf = {
    type: END_EDITING_PDF
}

export const updateExamStatus = data => async dispatch => {
    try {
        console.log(data);
        dispatch(startUpdatingExamStatus);
        const formData = new FormData();
        formData.append('exam_status', data.exam_status);
        formData.append('exam_id', data.exam_id);
        formData.append('exam_mark', data.exam_mark);
        const res = await axios.post(`${BASE_URL}update_exam_status.php`, formData);
        if(res.status === 200) {
            if(data.exam_type) {
                console.log(data);
                dispatch({
                    type: UPDATE_EXAM_STATUS,
                    exam_id: data.exam_id,
                    exam_status: data.exam_status,
                });
            }else {
                dispatch({
                    type: UPDATE_CLASS_EXAM_STATUS,
                    exam_id: data.exam_id,
                    exam_status: data.exam_status,
                });
            }

        }

        dispatch(endUpdatingExamStatus);
    } catch (e) {
        console.log(e?.message);
        dispatch(endUpdatingExamStatus);
    }
}

export const startUpdatingExamStatus = {
    type: START_UPDATING_EXAM_STATUS
}

export const endUpdatingExamStatus = {
    type: END_UPDATING_EXAM_STATUS
}

