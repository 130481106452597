import React from 'react';
import './Intro.css';
// import intro from '../../assets/images/Sameh-elKholy-2.png';
import TEACHER from "../../utls/constants";


const Intro = () => {
    return (
        <div className={'Intro'}>
            <img src={TEACHER.intro} alt=""/>
        </div>
    );
};

export default Intro;