import * as actionTypes from '../actions/types.actions';
import {LOG_USER_OUT} from "../actions/types.actions";

const initialState ={
    info: {},
    loggingTeacher: false,
    loadingTeacher: true,
    isAuthenticated: false,
    error: {}
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_LOGGING_USER_IN:
            return {
                ...state,
                loggingTeacher: true
            }
        case actionTypes.END_LOGGING_USER_IN:
            return {
                ...state,
                loggingTeacher: false
            }
        case actionTypes.LOAD_USER_START:
            return {
                ...state,
                loadingTeacher: true
            }
        case actionTypes.LOAD_USER_END:
            return {
                ...state,
                loadingTeacher: false
            }
        case actionTypes.FETCHING_TEACHER_INFO:
            localStorage.setItem('user', JSON.stringify(action.data));
            return {
                ...state,
                isAuthenticated: true,
                info: action.data,
                error: {}
            }
        case actionTypes.RETURN_USER_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                error: {
                    error: 'wrong data'
                }
            }
        case actionTypes.LOG_USER_OUT:
            localStorage.removeItem('user');
            return {
                ...state,
                info: {},
                isAuthenticated: false,
            }
        default:
            return state;
    }
}