import React, {useEffect} from 'react';
import './ClassPdfDeleteModal.css';
import {useParams} from "react-router-dom";

const ClassPdfDeleteModal = ({deleting, setDeletedPdf, deletedPdf, setDeleting, deletingPdf, deletePdf}) => {
    const params = useParams();
    useEffect(() => {
        if(!deletedPdf) return setDeleting(false);
        return () => {
            setDeleting(false);
            setDeletedPdf(null);
        }
    }, [deletedPdf]);
    return (
        <div className={`ClassPdfDeleteModal ${deleting && 'ClassPdfDeleteModal__show'}`}>
            <div className="ClassPdfDeleteModal__container">
                <div className="ClassPdfDeleteModal__header">
                    <span>هل انت متأكد من مسح {deletedPdf?.pdf_name}</span>
                </div>
                <div className="ClassPdfDeleteModal__btns">
                    <div onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        const data = {
                            id: deletedPdf.id,
                            grade: params.id,
                            class_id: params.class_id ? params.class_id : 0
                        }
                        await deletePdf(data);
                        setDeletedPdf(null);
                        setDeleting(false);
                    }} className="ClassPdfDeleteModal__btn ClassPdfDeleteModal__btn--delete">
                        {
                            deletingPdf ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>حذف</span>
                            )
                        }
                    </div>
                    <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeletedPdf(null);
                        setDeleting(false);
                    }} className="ClassPdfDeleteModal__btn ClassPdfDeleteModal__btn--cancel">الغاء</div>
                </div>
            </div>
        </div>
    );
};

export default ClassPdfDeleteModal;