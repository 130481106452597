import React, {useEffect, useState} from 'react';
import './ClassExamEditAnswer.css';
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import draftToHtml from "draftjs-to-html";

const ClassExamEditAnswer = ({show, setShow, currentAnswer, setCurrentAnswer}) => {
    const [input, setInput] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const _contentState = ContentState.createFromText('Sample content state')
    const raw = convertToRaw(_contentState) // RawDraftContentState JSON
    const [contentState, setContentState] = useState(raw) // ContentState JSON
    const [convertedContent, setConvertedContent] = useState(null)


    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    };

    useEffect(() => {
        // let html = convertToHTML(editorState.getCurrentContent())
        // setConvertedContent(html)
        // console.log(html);
        // currentAnswer.setCurrentAnswerValue(html);
        // setInput(html);
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(html);
        setInput(html);
    }, [editorState]);

    return (
        <div className={`ClassExamEditAnswer ${show && 'ClassExamEditAnswer__show'}`}>
            <div className="ClassExamEditAnswer__container">
                <label htmlFor="">اضافة اجابة</label>
                <Editor
                    defaultContentState={contentState}
                    onContentStateChange={setContentState}
                    editorState={editorState}
                    toolbar={{options: ['inline'/*, 'blockType'*//*, 'fontSize'*//*, 'fontFamily'*/, 'list'/*, 'textAlign'*//*, 'colorPicker'*//*, 'link'*//*, 'embedded'*//*, 'emoji'*//*, 'image'*//*, 'remove'*//*, 'history'*/]}}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    onChange={e => {
                        console.log(e);
                        // setQuestion(e.target.value);
                    }}
                />
                <button onClick={e => {
                    currentAnswer.setCurrentAnswerValue(input);
                    setShow(false);
                    setInput('');
                    setEditorState(EditorState.createEmpty());
                    setConvertedContent(null);
                    const _contentState = ContentState.createFromText('Sample content state')
                    const raw = convertToRaw(_contentState) // RawDraftContentState JSON
                    setContentState(raw);
                }}>اضافة</button>
            </div>
        </div>
    );
};

export default ClassExamEditAnswer;