import React, {useEffect} from 'react';
import './Home.screen.css';
import {Outlet, useParams} from "react-router-dom";
// import TEACHER1 from "../../assets/images/SVG/FAVICON/HARVEST.svg";
// import TEACHER2 from '../../assets/images/SVG/FAVICON/GALILIO.svg';
import First from "../../assets/images/student1.jpg";
import Second from "../../assets/images/student2.avif";
import Third from "../../assets/images/third.png";
import {useNavigate} from "react-router-dom";
import TEACHER from "../../utls/constants";
import {connect} from "react-redux";
import {changeGradeId} from "../../store/actions/ui.actions";

const HomeScreen = ({changeGradeId}) => {
    const navigate = useNavigate();
    const handleUrl= ()=>{
        navigate('/grade/1')
    }
    const handleSecondUrl= ()=>{
        navigate('/grade/2')
    }
    const handleThirdUrl= ()=>{
        navigate('/grade/3')
    }

    const params = useParams();

    useEffect(() => {
        console.log(params);
        if(params.id) {
            changeGradeId(params.id);
        } else {
            changeGradeId(null);
        }
    }, [params.id]);
    return (
        <div className={'Home'}>
            <div className={'home__container'}>
                <div className='home__top'>
                    <div className='home__top--container'>
                        <div className='home__top--image'>
                            <img src={TEACHER.logo}/>
                        </div>
                        <h4 className='home__top--container-h4'>{TEACHER.name}</h4>
                        <div className='home__top--content'>
                            <h4 className='home__top--content-h4' >{TEACHER.subject}</h4>
                        </div>
                    </div>
                </div>
                <div className='home__bottom'>
                    <div onClick={handleUrl} className='grade--secondary'>
                        <div className='first__grade--image'>
                            <img className='grade--image-img' src={First}/>
                        </div>
                        <h3 className='grade-text' >الصف الاول الثانوي</h3>
                    </div>
                    <div onClick={handleSecondUrl}  className='grade--secondary'>
                        <div className='second__grade--image'>
                            <img className='grade--image-img' src={Second}/>
                        </div>
                        <h3 className='grade-text' >الصف الثاني الثانوي</h3>
                    </div>
                    <div onClick={handleThirdUrl}  className='grade--secondary'>
                        <div className='grade--image'>
                            <img className='grade--image-img' src={Third}/>
                        </div>
                        <h3 className='grade-text' >الصف الثالث الثانوي</h3>
                    </div>
                </div>
            </div>
            <Outlet/>
        </div>
    );
};

export default connect(null, {changeGradeId}) (HomeScreen);