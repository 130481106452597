import React, { useEffect, useState } from 'react';
import './Class.Page.css';
import Header from '../../../../../../Components/Header/Header';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Youtube from '../../../../../../Components/Youtube/Youtube';
import Pdf from '../../../../../../Components/Pdf/Pdf';
import Exams from '../../../../../../Components/Exams/Exams';
import {Outlet} from 'react-router-dom';
import QuestionBank from "../../../../../../Components/QuestionBank/QuestionBank";

function ClassPage({classes}) {
    const [lesson, setLesson] = useState(null);
    const params = useParams();
    useEffect(() => {
        const lesson = classes.filter(c => c.id == params.class_id)[0]
        setLesson(lesson);
    }, [classes]);
    useEffect(() => {
        const home = document.querySelector('.Door');
        const freezeStyles = () => {
            home.classList.add('Home__hide')
        }
        const releaseStyles = () => {
            home.classList.remove('Home__hide')
        }

        freezeStyles();

        return () => {
            releaseStyles();
        }
    }, []);

    return (
        <div className='ClassPage'>
            <Header text={lesson && lesson.name} />
            <div className='ClassPage__container'>
                <Youtube />
                <Pdf />
                <Exams />
                <QuestionBank />
            </div>
            <Outlet />
        </div>
    )
}

const mapStateToProps = state => ({
    classes: state.doors.classes
})

export default connect(mapStateToProps) (ClassPage);