import React from 'react';
import './ExamStudentsMark.css';
import avatarImg from '../../../../../../assets/images/student.avatar.jpg';


const ExamStudentsMark = ({mark, exam}) => {
    return (
        <div className={'ExamStudentsMark'}>
            <div className="ExamStudentsMark__left">
                <div className="ExamStudentsMark__avatar">
                    <img src={avatarImg} alt="" className="ExamStudentsMark__avatar--img"/>
                </div>
                <div className="ExamStudentsMark__details">
                    <p className="ExamStudentsMark__details--name">{mark?.name}</p>
                    <p className="ExamStudentsMark__details--phone">{mark?.phone}</p>
                </div>
            </div>
            <div className="ExamStudentsMark__right">
                <p className="ExamStudentsMark__icon">
                    {
                        mark?.mark == 0 ? (
                            <span className={'ExamStudentsMark__sad'}><i className="fa-solid fa-face-frown-open"></i></span>
                        ) : (
                            <span className={'ExamStudentsMark__happy'}><i className="fa-solid fa-medal"></i></span>
                        )
                    }
                </p>
                <p className="ExamStudentsMark__mark">{Number(mark?.mark).toFixed(1)}</p>
                {+exam?.exam_mark != 0 && <p className="ExamStudentsMark__percent">{((+mark?.mark / +exam?.exam_mark) * 100).toFixed(1)}%</p>}
            </div>
        </div>
    );
};

export default ExamStudentsMark;