import axios from 'axios';
import {
    END_GRADE_STUDENTS_QUESTIONS,
    FETCH_GRADE_STUDENTS_QUESTIONS,
    START_GRADE_STUDENTS_QUESTIONS
} from "./types.actions";
import {BASE_URL} from "../../utls/constants";

export const startFetchingGradeSQ = {
    type: START_GRADE_STUDENTS_QUESTIONS
}

export const endFetchingGradeSQ = {
    type: END_GRADE_STUDENTS_QUESTIONS
}

export const fetchGradeSQ = data => async dispatch => {
    try {
        dispatch(startFetchingGradeSQ);
        const formData = new FormData();
        formData.append('init_record_no', data.record);
        formData.append('grade', data.grade);
        const res = await axios.post(`${BASE_URL}select_question_student.php`, formData);
        console.log(res);
        dispatch({
            type: FETCH_GRADE_STUDENTS_QUESTIONS,
            questions: res.data
        })
        dispatch(endFetchingGradeSQ);
    } catch (e) {
        console.log(e.message);
    }
}