import React from 'react';
import './YoutubeVideo.css';
import {changeCurrentVideoUrl, toggleVideoPlayer} from '../../../../../../../../store/actions/ui.actions.js';
import { connect } from 'react-redux';

function YoutubeVideo({video, number, setEditedVideo, setEditing, editing, changeCurrentVideoUrl, toggleVideoPlayer, setDeleting, setDeletedVideo}) {
  return (
    <div onClick={e => {
      toggleVideoPlayer();
      changeCurrentVideoUrl(video);
    }} className='YoutubeVideo'>
      <p>{video.title_video}</p>
        <div className="YoutubeVideo__left">
            <span onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setDeleting(true);
                setDeletedVideo(video);
            }} className="YoutubeVideo__delete"><i className="fa-solid fa-trash"></i></span>
            <span onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setEditedVideo(video);
                setEditing(true);
            }} className="YoutubeVideo__edit"><i className="fa-solid fa-pen"></i></span>
            <span className={'YoutubeVideo__num'}>{number+1}</span>
        </div>
    </div>
  )
}

export default connect(null, {changeCurrentVideoUrl, toggleVideoPlayer}) (YoutubeVideo);