import React, { useState } from 'react';
import './DoorForm.css';
import { connect } from 'react-redux';
import { createClass } from '../../../../../store/actions/doors.actions';
import { useParams } from 'react-router-dom';
import {setAlert} from '../../../../../store/actions/ui.actions.js';

function DoorForm({show, setShow, createClass, creatingClass, setAlert}) {
    const [name, setName] = useState('');

    const params = useParams();
    const submitHandler = async e => {
        e.preventDefault();

        if(name.length === 0) {
            setAlert('ادخل اسم الدرس', 'danger');
        }

        if(params.door_id && name.length > 0) {
            await createClass(params.door_id, name);
            setShow(false);
        }
    }

    return (
        <div onClick={e => setShow(false)} className={`DoorForm ${show && 'DoorForm__show'}`}>
            <form onClick={e => {
                e.preventDefault();
                e.stopPropagation();    
            }} className={`DoorForm__form`}>
                <div className='DoorForm__element'>
                    <label>اسم الدرس</label>
                    <input value={name} onChange={e => setName(e.target.value)} type='text' />
                </div>
                <div className='DoorForm__btns'>
                    <button onClick={e => {
                        e.preventDefault();
                        setShow(false);
                    }} type='cancel'>الغاء</button>
                    <button onClick= {e => {
                        e.preventDefault();
                        e.stopPropagation();
                        submitHandler(e);
                    }} type='submit'>{creatingClass ? <i class="fa-solid fa-circle-notch"></i> : 'انشاء'}</button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    creatingClass: state.doors.creatingClass
})

export default connect(mapStateToProps, {createClass, setAlert}) (DoorForm)