import React from 'react';
import './Assistant.css';
import person from '../../../../assets/images/personplaceholder.jpg';
import {Link} from "react-router-dom";

const Assistant = ({teacher}) => {
    return (
        <Link to={`${teacher.id}`} className={'Assistant'}>
            <img className={'Assistant__image'} src={teacher?.image || person} alt="assistant"/>
            <div className="Assistant__right">
                <div className="Assistant__name">
                    <p>{teacher?.name}</p>
                </div>
                <div className="Assistant__phone">
                    <p>{teacher?.phone}</p>
                </div>
            </div>
        </Link>
    );
};

export default Assistant;