import React, {useEffect} from 'react'
import "./exsam.css"
import { useState } from 'react';
import {connect} from "react-redux";
import ExsamList from "./ExsamList/ExsamList";
import {fetchExsam} from "../../../store/actions/exsam.action";
import Spinner from "../../../Components/Spinner/Spinner";
import {createExsame} from "../../../store/actions/exsam.action";
import {useDispatch} from "react-redux";
import ExamsAddForm from "./ExamsAddForm/ExamsAddForm";
import {setAlert} from "../../../store/actions/ui.actions";
import {Outlet, useNavigate, useParams} from "react-router-dom";

const Exsam = (props) => {
    const dispatch=useDispatch()
    const [exsamName,setExsamName]=useState();
    const [exsamDisc,setExsamDisc]=useState();
    const [isActive, setIsActive] = useState(true);
    const [exsamStart,setExsamStart]=useState();
    const [exsamEnd,setExsamEnd]=useState();
    const [exsamStartDate,setExsamStartDate]=useState();
    const [exsamEndDate,setExsamEndDate]=useState();
    const params = useParams();

    const [time,setTime]=useState({
        exsamStart:'',
        exsamEnd:''
    });

    // const home = document.querySelector('.Exsame');

    const handleButtonClick= ()=>{
      setIsActive(current => !current);
    }

    useEffect(() => {
         props.fetchExsam(params.id, 0);
    }, []);

    const navigate = useNavigate();

  const calcTim=parseInt(time.exsamEnd)-parseInt(time.exsamStart);

  return (
    <div className='Exsame'>
        <div className='Exsame__Container'>
            <div className='Exsame__adress'>
               <h2 className='Exsame__adress-h3'>الاختبارات الالوكترونيه</h2>
            </div>
            <div className='Exsame__Container--search'>
                <label className='Exsame__search--lable'>قائمة البحث عن الاختبارات</label>
                <input  className='Exsame__search--input' type='text'  id="listFilter" placeholder="&#xF002;"/>
            </div>
            <div className='Exsame__Container--buttons'>
                <button onClick={e => navigate('daily')} className='Exsame__addingButton'><span>+ اضف اختبار يومي</span></button>
                <button onClick={handleButtonClick} className='Exsame__addingButton'><span>+ اضف اختبار شامل</span></button>
                
            </div>
            {/*****************************************************************************/}

            <div className='Exsame__allExsams--body'>
                {
                    props.fetchingExsam ? (
                        <Spinner />
                    ) : (
                        <>
                        <div className='Exsame__body--info--container'>
                            <div className='Exsame__body--info'>
                                <ExsamList element={props.exsams}/>
                            </div>
                        </div>

                        </>
                    )
                }


            </div>
            {/*****************************************************************************/}
            <ExamsAddForm isClass={false} creatingExam={props.creatingExam} setAlert={props.setAlert} isActive={isActive} setIsActive={setIsActive} createExsame={props.createExsame} />
            <Outlet />
        </div>

    </div>
  )
}

const mapStateToProps = state => ({
    fetchingExsam: state.exsams.fetchingExsam,
    exsams: state.exsams.exsams,
    creatingExam: state.exsams.creatingExsam
})

export default connect(mapStateToProps, {fetchExsam, createExsame, setAlert}) (Exsam);