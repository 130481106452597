import React, {useEffect, useState} from 'react';
import './ClassExamSave.css';
import {connect} from "react-redux";
import {setAlert, toggleClassExamSaver} from "../../../../../../../../../store/actions/ui.actions";
import {updateClassExam} from "../../../../../../../../../store/actions/doors.actions";
import {useParams} from "react-router-dom";

const ClassExamSave = ({classExamQuestions, toggleClassExamSaver, updatingExam, show, setShow, currentClassExam, setAlert, updateClassExam, openClassExamSaver}) => {
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const params = useParams();
    useEffect(() => {
        setName(currentClassExam?.exam_name);
        setDesc(currentClassExam?.exam_description);
    }, [currentClassExam]);
    const formateTime = (start, end) => {
        const startToString = start?.split(':');
        const endToString = end?.split(':');
        const startMin = Number(start && start[0])* 60 + Number(start&& start[1]);
        const endMin = Number(end && end[0]) * 60 + Number(end && start[1]);
        return startMin + endMin;
    }
    return (
        <div className={`ClassExamSave ${openClassExamSaver && 'ClassExamSave__show'}`}>
            <div className="ClassExamSave__container">
                <h3 className="ClassExamSave__header">تفاصيل الاختبار</h3>
                <form action="" className="ClassExamSave__form">
                    <div className="ClassExamSave__form--element">
                        <label htmlFor="">اسم الاختبار</label>
                        <input onChange={e => setName(e.target.value)} type="text" value={name}/>
                    </div>
                    <div className="ClassExamSave__form--element">
                        <textarea onChange={e => setDesc(e.target.value)} value={desc}/>
                    </div>
                    <div className="ClassExamSave__form--duration">
                        <p className="ClassExamSave__form--duration-header">مدة الاداء {currentClassExam?.exam_time > 0 || formateTime(currentClassExam?.start_time, currentClassExam?.end_time)} دقيقة</p>
                    </div>
                    <div className="ClassExamSave__form--btns">
                        <button onClick={ async e => {
                            e.preventDefault();
                            if(name.length === 0) return setAlert('من فضلك ادخل اسم الاختبار', 'danger');
                            const data = {
                                exam_name: name,
                                exam_description: desc,
                                grade: params.id,
                                exam_id: params.exam_id,
                                exam_mark:classExamQuestions.length > 0 ? classExamQuestions?.reduce((sum, curr) => {
                                    return sum + +curr.quest_degree
                                }, 0) : 0,
                                class_id: params.class_id,
                                exam_time: currentClassExam?.exam_time > 0 || formateTime(currentClassExam?.start_time, currentClassExam?.end_time)
                            };
                            await updateClassExam(data);
                            toggleClassExamSaver()
                        }} type={'save'}>{
                            updatingExam ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>حفظ</span>
                            )
                        }</button>
                        <button onClick={e => {
                            e.preventDefault();
                            toggleClassExamSaver()
                        }} type={'cancel'}>الغاء</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    currentClassExam: state.doors.currentExam,
    classExamQuestions: state.doors.classQuestions,
    updatingExam: state.doors.updatingClassExam,
    openClassExamSaver: state.ui.openClassExamSaver
})

export default connect(mapStateToProps, {setAlert, updateClassExam, toggleClassExamSaver}) (ClassExamSave);