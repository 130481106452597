import React, {useEffect, useRef, useState} from 'react';
import './ClassExamAddQuestion.css';
import Header from "../../../../../../../../../Components/Header/Header";
import {connect} from "react-redux";
import ClassExamEditAnswer from "./ClassExamEditAnswer/ClassExamEditAnswer";
import {useNavigate, useParams} from "react-router-dom";
import {createClassExamQuesiton} from "../../../../../../../../../store/actions/doors.actions";
import {setAlert, toggleClassExamSaver} from "../../../../../../../../../store/actions/ui.actions";
import { Editor } from "react-draft-wysiwyg";
// import {Editor} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import {convertFromHTML, convertToHTML} from 'draft-convert';
import FormulaEditorDemo from "./FormulaEditor/FormulaEditor";
import draftToHtml from 'draftjs-to-html';
import {useStateWithCallback} from "../../../../../../../../../utls/useStateWithCallback";
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import Spinner from "../../../../../../../../../Components/Spinner/Spinner";
import ContentBlock from "draft-js/lib/ContentBlock";

function ClassExamAddQuestion({classQuestions, questionBank, fetchingClassQuestions, fetchingQuestionBank, isClass, bank, setAlert, createClassExamQuesiton, creatingClassQuestion, currentExam, toggleClassExamSaver}) {
    const [type, setType] = useState(0);
    const [degree, setDegree] = useState('');
    const [question, setQuestion] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState(1);
    const [firstAnswer, setFirstAnswer] = useState(type == 0 ? 'صح' : '');
    const [secondAnswer, setSecondAnswer] = useState(type == 0 ? 'خطأ' : '');
    const [thirdAnswer, setThirdAnswer] = useState('');
    const [fourthAnswer, setFourthAnswer] = useState('');
    const [show, setShow] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const _contentState = ContentState.createFromText('Sample content state')
    const raw = convertToRaw(_contentState) // RawDraftContentState JSON
    const [contentState, setContentState] = useState(raw) // ContentState JSON
    const [convertedContent, setConvertedContent] = useState(null)
    const [blocks, setBlocks] = useState({});

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    };

    const [currentAnswer, setCurrentAnswer] = useState({
        currentAnswerValue: firstAnswer,
        setCurrentAnswerValue: setFirstAnswer
    });
    const [prevQuestionId, setPrevQuestionId] = useState(null);
    // const [currentAnswerValue, setCurrentAnswerValue] = useState(null);
    // const [currentAnswerFun, setCurrentAnswerFun] = useState(null);
    const [img, setImg] = useState(null);
    const [refImg, setRefImg] = useState(null);
    const [reference, setReference] = useState('');

    const min = 1;
    const params = useParams();

    useEffect(() => {
        const home = document.querySelector('.ClassExamDetails');
        const freezeStyles = () => {
            home?.classList.add('Home__hide')
        }
        const releaseStyles = () => {
            home?.classList.remove('Home__hide')
        }

        freezeStyles();

        return () => {
            releaseStyles();
        }
    }, []);

    const sendQuestionData = async (value) => {
        async function readFileAsDataURL(file) {
            let result_base64 = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsDataURL(file);
            });

            return result_base64;
        }

        console.log(img, refImg);
        const data = {
            exam_id: params.exam_id || 0,
            question: question.toString(),
            refrence: reference,
            first_ans: type == 0 ? 'صح' : firstAnswer,
            second_ans: type == 0 ? 'خطأ' : secondAnswer,
            third_ans: thirdAnswer,
            fourth_ans: fourthAnswer,
            ques_type: type,
            quest_degree: degree,
            type_uploading: img === null ? 0 : 1,
            class_id: params.class_id || 0,
            image_data_ques: img !== null && await readFileAsDataURL(img),
            image_data_ref: refImg !== null && await readFileAsDataURL(refImg),
            true_answer: correctAnswer,
            id: prevQuestionId === null ? 0 : classQuestions[prevQuestionId].id
        };
        console.log(data);
        if(!question || !degree || !firstAnswer || !secondAnswer || (type === 1 && (!thirdAnswer || !fourthAnswer))) return setAlert('من فضلك ادخل جميع البيانات المطلوبة', 'danger');
        await createClassExamQuesiton(data);
        // setQuestion('');
        // setEditorState(EditorState.createEmpty());
        // setType(0);
        // setDegree('');
        // setImg(null);
        // setReference('');
        // setFourthAnswer('');
        // setThirdAnswer('');
        // setSecondAnswer(type == 0 ? 'خطأ' : '');
        // setFirstAnswer(type == 0 ? 'صح' : '');
        // setCorrectAnswer(1);
        // setPrevQuestionId(null);
        // setRefImg(null);
    }

    const createExamQuestionHandler = async e => {
        // setQuestion(() => convertedContent);
        sendQuestionData();
    }

    // useEffect(() => {
    //     if(question.length == 0 || question == classQuestions.filter(q => q.id == prevQuestionId)[0].question) return;
    //     sendQuestionData();
    // }, [question]);

    const navigate = useNavigate();

    useEffect(() => {
        if(!editorState.getCurrentContent().hasText()) return setQuestion('');
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(html);
        setQuestion(html);
    }, [editorState]);

    const firstAnswerRef = useRef();

    useEffect(() => {
        if(currentExam && currentExam.id == params.exam_id && currentExam?.exam_status == 1) {
            bank && navigate(`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/bank`);
            isClass && navigate(`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/exams/${currentExam?.id && currentExam.id}/details`);
            !isClass && navigate(`/grade/${params.id}/exsam/${currentExam?.id && currentExam.id}/details`);
        }
    }, [currentExam]);

    const getContentStyle = () => {
        return {
            direction: 'rtl'
        }
    }

    const handleEditorStateChannge = () => {
        const contentState = editorState.getCurrentContent();

        const newContentState = ContentState.createFromBlockArray(
            contentState.getBlockMap().toArray().map((block) => {
                const reversedText = block
                    .getText()
                    .split(' ')
                    .map((word) => word.split('').reverse().join(''))
                    .join(' ');

                return new ContentBlock({
                    key: block.getKey(),
                    type: block.getType(),
                    text: reversedText,
                    characterList: block.getCharacterList(),
                    data: block.getData(),
                });
            })
        )
        const newEditorState = EditorState.createWithContent(newContentState);
        setEditorState(newEditorState);
    }
    return (
        <div className={'ClassExamAddQuestion'}>
            <Header text={'انشاء اختبار'} />
            <div className="ClassExamAddQuestion__container">
                <p className="ClassExamAddQuestion__num">
                    {
                        (!fetchingClassQuestions & !fetchingQuestionBank) && prevQuestionId === null ? (bank ? questionBank.length + 1 : classQuestions.length + 1) : prevQuestionId + 1
                    }
                </p>
                <div className="ClassExamAddQuestion__details">
                    <div className="ClassExamAddQuestion__details--type">
                        <p>نوع السؤال </p>
                        <select value={type} onChange={e => {
                            setType(+e.target.value);
                            setCorrectAnswer(1);
                            setFirstAnswer(+e.target.value == 0 ? "صح" : '');
                            setSecondAnswer(+e.target.value == 0 ? "خطأ" : '');
                            setThirdAnswer('');
                            setFourthAnswer('');
                        }} id="type" name="type">
                            <option value={0}>صح أم خطأ</option>
                            <option value={1}>اختيار متعدد</option>
                        </select>
                    </div>
                    <div className="ClassExamAddQuestion__details--degree">
                        <p>درجة السؤال</p>
                        <input min={0} onChange={e => {
                            const value = Math.max(min, Number(e.target.value));
                            setDegree(value);
                        }} type="number" value={degree} className="ClassExamAddQuestion__details--degree-input"/>
                    </div>
                </div>
                <div className="ClassExamAddQuestion__question">
                    <label htmlFor="question" className="ClassExamAddQuestion__question--label">
                        أدخل السؤال
                    </label>
                    {/*<FormulaEditorDemo />*/}
                    {/*<Example />*/}
                    {/*<FormulaEditor*/}
                    {/*    // editorValue={editorValue}*/}
                    {/*    // onChange={(event) => changeEditorValue(event.target.value)}*/}
                    {/*    placeholder={"Start typing..."}*/}
                    {/*/>*/}
                    <Editor
                        defaultContentState={contentState}
                        onContentStateChange={setContentState}
                        editorState={editorState}
                        toolbar={{options: ['inline'/*, 'blockType'*//*, 'fontSize'*//*, 'fontFamily'*/, 'list', 'textAlign'/*, 'colorPicker'*//*, 'link'*//*, 'embedded'*//*, 'emoji'*//*, 'image'*//*, 'remove'*//*, 'history'*/]}}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        // toolbar={{
                        //     options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'],
                        //     // inline: {
                        //     //     inDropdown: true,
                        //     // },
                        //     // list: {
                        //     //     inDropdown: true,
                        //     // },
                        //     // textAlign: {
                        //     //     inDropdown: true,
                        //     // },
                        //     // link: {
                        //     //     inDropdown: true,
                        //     // },
                        // }}
                        contentStyle={getContentStyle()}
                        onChange={e => {
                            console.log(e);
                            // setQuestion(e.target.value);
                            setBlocks(e);
                        }}
                    />
                    {/*<input value={question} onChange={e => setQuestion(e.target.value)} type="text" className="ClassExamAddQuestion__question--input"/>*/}
                </div>
                <div className="ClassExamAddQuestion__image">
                    <label htmlFor=""><i className="fa-regular fa-image"></i> أضف صورة للسؤال</label>
                    <input type="file" onChange={e => setImg(e.target.files[0])} accept={'.png, .jpg'} className="ClassExamAddQuestion__image--input"/>
                </div>
                <div className="ClassExamAddQuestion__options">
                    {
                        type == 0 ? (
                            <>
                                <div onClick={e => setCorrectAnswer(1)} className="ClassExamAddQuestion__options--option">
                                    <input checked={correctAnswer == 1} type="checkbox" name={'correct'}/>
                                    <label htmlFor="">صح</label>
                                </div>
                                <div className="ClassExamAddQuestion__options--option" onClick={e => setCorrectAnswer(2)} >
                                    <input checked={correctAnswer == 2} type="checkbox" name={'correct'}/>
                                    <label htmlFor="">خطأ</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <div  className="ClassExamAddQuestion__options--option ClassExamAddQuestion__options--option-contianer">
                                    <div onClick={e => setCorrectAnswer(1)} className={'ClassExamAddQuestion__options--option-multi'}>
                                        <input checked={correctAnswer == 1} type="checkbox" name={'correct'}/>
                                        <p ref={firstAnswerRef} dangerouslySetInnerHTML={{__html: firstAnswer == '' ? '<p>الاختيار الاول</p>' : firstAnswer}}></p>
                                        {/*<label ref={firstAnswerRef} htmlFor="">{firstAnswer || 'الخيار الاول'}</label>*/}
                                    </div>
                                    <p onClick={e => {
                                        // setCurrentAnswerFun(setFirstAnswer);
                                        // setCurrentAnswerValue(firstAnswer);
                                        setCurrentAnswer({
                                            ...currentAnswer,
                                            currentAnswerValue: firstAnswer,
                                            setCurrentAnswerValue: setFirstAnswer
                                        })
                                        setShow(true);
                                    }} className={'ClassExamAddQuestion__options--option-edit'}><i className="fa-solid fa-pen"></i></p>

                                </div>
                                <div className="ClassExamAddQuestion__options--option ClassExamAddQuestion__options--option-contianer"  >
                                    <div onClick={e => setCorrectAnswer(2)} className={'ClassExamAddQuestion__options--option-multi'}>
                                        <input checked={correctAnswer == 2} type="checkbox" name={'correct'}/>
                                        <p ref={firstAnswerRef} dangerouslySetInnerHTML={{__html: secondAnswer == '' ? '<p>الاختيار الثاني</p>' : secondAnswer}}></p>
                                    </div>
                                    <p onClick={e => {
                                        setCurrentAnswer({
                                            ...currentAnswer,
                                            currentAnswerValue: secondAnswer,
                                            setCurrentAnswerValue: setSecondAnswer
                                        })
                                        setShow(true);
                                    }} className={'ClassExamAddQuestion__options--option-edit'}><i className="fa-solid fa-pen"></i></p>

                                </div>
                                <div  className="ClassExamAddQuestion__options--option ClassExamAddQuestion__options--option-contianer">
                                    <div onClick={e => setCorrectAnswer(3)} className={'ClassExamAddQuestion__options--option-multi'}>
                                        <input checked={correctAnswer == 3} type="checkbox" name={'correct'}/>
                                        <p ref={firstAnswerRef} dangerouslySetInnerHTML={{__html: thirdAnswer == '' ? '<p>الاختيار الثالث</p>' : thirdAnswer}}></p>
                                    </div>
                                    <p onClick={e => {
                                        setCurrentAnswer({
                                            ...currentAnswer,
                                            currentAnswerValue: thirdAnswer,
                                            setCurrentAnswerValue: setThirdAnswer
                                        })
                                        setShow(true);
                                    }} className={'ClassExamAddQuestion__options--option-edit'}><i className="fa-solid fa-pen"></i></p>

                                </div>
                                <div className="ClassExamAddQuestion__options--option ClassExamAddQuestion__options--option-contianer"  >
                                    <div onClick={e => setCorrectAnswer(4)} className={'ClassExamAddQuestion__options--option-multi'}>
                                        <input checked={correctAnswer == 4} type="checkbox" name={'correct'}/>
                                        {/*<label htmlFor="">{fourthAnswer || 'الخيار الرابع'}</label>*/}
                                        <p ref={firstAnswerRef} dangerouslySetInnerHTML={{__html: fourthAnswer == '' ? '<p>الاختيار الرابع</p>' : fourthAnswer}}></p>
                                    </div>
                                    <p onClick={e => {
                                        setCurrentAnswer({
                                            ...currentAnswer,
                                            currentAnswerValue: fourthAnswer,
                                            setCurrentAnswerValue: setFourthAnswer
                                        })
                                        setShow(true);
                                    }} className={'ClassExamAddQuestion__options--option-edit'}><i className="fa-solid fa-pen"></i></p>
                                </div>
                                <ClassExamEditAnswer show={show} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} setShow={setShow} />
                            </>
                        )
                    }
                </div>
                <div className="ClassExamAddQuestion__btns">
                    <button onClick={e => {
                        e.preventDefault();
                        createExamQuestionHandler(e)
                    }} type={'next'}>{
                        creatingClassQuestion ? (
                            <spa><i className="fa-solid fa-circle-notch"></i></spa>
                        ) : (
                            <span>التالي</span>
                        )
                    }</button>
                    <button onClick={e => {
                        e.preventDefault();
                        const questions = bank ? questionBank : classQuestions;
                        const prevQuestion = questions[prevQuestionId == null ? (() => {
                            setPrevQuestionId(questions.length - 1 >= 0 ? questions.length - 1 : null);
                            console.log(prevQuestionId);
                            return questions.length - 1 >= 0 ? questions.length - 1 : null;
                        })() : (() => {
                            setPrevQuestionId(prevQuestionId - 1 >= 0 ? prevQuestionId - 1 : (questions.length - 1 >= 0 ? questions.length -1 : 0));
                            return prevQuestionId - 1 >= 0 ? prevQuestionId - 1 : (questions.length - 1 >= 0 ? questions.length -1 : null);
                        })()];
                        if(prevQuestion) {
                            // const contentBlocks = convertFromHTML(prevQuestion.question);
                            // const contentStateRev = ContentState.createFromBlockArray(contentBlocks);
                            const processedHTML = DraftPasteProcessor.processHTML(prevQuestion.question);
                            console.log(processedHTML);
                            const contentStateRev = ContentState.createFromBlockArray(processedHTML);
                            let editorStateRev = EditorState.createWithContent(contentStateRev);
                            editorStateRev = EditorState.moveFocusToEnd(editorStateRev);
                            // console.log(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(prevQuestion.question).contentBlocks, convertFromHTML(prevQuestion.question).entityMap)))
                            setQuestion(prevQuestion?.question);
                            setCorrectAnswer(prevQuestion?.true_answer);
                            setDegree(prevQuestion?.quest_degree);
                            setFirstAnswer(prevQuestion?.first_ans);
                            setSecondAnswer(prevQuestion?.second_ans);
                            setThirdAnswer(prevQuestion?.third_ans);
                            setFourthAnswer(prevQuestion?.fourth_ans);
                            setReference(prevQuestion?.refrence);
                            setType(prevQuestion.ques_type);
                            setEditorState(editorStateRev);
                        }
                    }} type={'past'}>السابق</button>
                </div>
                <div className="ClassExamAddQuestion__reference">
                    <label htmlFor="">اضافة مرجع للسؤال</label>
                    <textarea value={reference} onChange={e => setReference(e.target.value)} />
                </div>
                <div className="ClassExamAddQuestion__image">
                    <label htmlFor=""><i className="fa-regular fa-image"></i> أضف صورة للمرجع</label>
                    <input type="file" onChange={e => setRefImg(e.target.files[0])} accept={'.png, .jpg'} className="ClassExamAddQuestion__image--input"/>
                </div>
                <div className="ClassExamAddQuestion__total">
                    <p>اجمالي درجات الاسئلة السابقة</p>
                    <p className={'ClassExamAddQuestion__total--num'}>
                        {
                            classQuestions.length > 0 && classQuestions.reduce((sum, curr) => {
                                return sum + +curr.quest_degree
                            }, 0)
                        }
                    </p>
                </div>
                <div onClick={e => toggleClassExamSaver()} className="ClassExamAddQuestion__saver">
                    <p>حفظ الاسئلة</p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    classQuestions: state.doors.classQuestions,
    creatingClassQuestion: state.doors.creatingClassQuestion,
    currentExam: state.doors.currentExam,
    fetchingQuestionBank: state.questionBank.fetchingQuestionBank,
    fetchingClassQuestions: state.doors.fetchingClassQuestions,
    questionBank: state.questionBank.questionBank
})

export default connect(mapStateToProps, {createClassExamQuesiton, setAlert, toggleClassExamSaver}) (ClassExamAddQuestion);