import React, { useState } from 'react';
import './DoorsForm.css';
import { createDoor } from '../../../../store/actions/doors.actions';
import { connect } from 'react-redux';

function DoorsForm({creatingDoor, open, setOpen, createDoor, grade}) {
  const [name, setName] = useState('');
  const formSubmitHandler = async e => {
    e.preventDefault();
    await createDoor(name, grade);
    setName('');
    setOpen(false);
  }
  return (
    <div className={`DoorsForm ${open && 'DoorsForm__open'}`}>
        <form onSubmit={formSubmitHandler} className='DoorsForm__form'>
          <div className='DoorsForm__element'>
            <label className='DoorsForm__element--label'>
              اسم الباب
            </label>
            <input className='DoorsForm__element--input' type='text' value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className='DoorsForm__btns'>
            <button onClick={e => {
              e.preventDefault();
              setOpen(false);
            }} type='cancel'>الغاء</button>
            <button type='submit'>{creatingDoor ? <i class="fa-solid fa-circle-notch"></i> : 'انشاء'}</button>
          </div>
        </form>
        <div onClick={e => setOpen(false)} className='DoorsForm__backdrop'></div>
    </div>
  )
}

const mapStateToProps = state => ({
  creatingDoor: state.doors.creatingDoor
})

export default connect(mapStateToProps, {createDoor}) (DoorsForm);