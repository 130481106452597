import React, {useState} from 'react';
import './Student.css';
import avatar from '../../../../../../assets/images/student.avatar.jpg'
import {useNavigate, useParams} from "react-router-dom";
import {exceptStudent} from "../../../../../../store/actions/Settings.actions";
import {connect} from "react-redux";
import Spinner from "../../../../../../Components/Spinner/Spinner";

const Student = ({student, exception, exceptStudent, clickToAddFunc, reports, currentGroupCount, date, attend, pay}) => {
    const [addingStudentToGroup, setAddingStudentToGroup] = useState(false)
    const [exceptingStudent, setExceptingStudent] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    return (
        <div onClick={async e => {
            if(reports) return navigate(`/grade/${params.id}/settings/reports/${student.id}`);
            if(attend == 1) return;
            const data = {
                group_id: params.group_id,
                studnt_group_number: currentGroupCount + 1,
                student_id: student.id,
                grade: params.id,
                date,
                attend,
                pay
            }
            setAddingStudentToGroup(true);
            await clickToAddFunc(data);
            setAddingStudentToGroup(false);
        }} className={'Student'}>
            <div className="Student__container">
                <div className="Student__right">
                    <div className="Student__hero">
                        <img src={avatar} />
                    </div>
                    <div className="Student__details">
                        <p className="Student__details--name">{student?.name}</p>
                        <p className="Student__details--phone">{student?.phone}</p>
                    </div>
                </div>
                {
                    attend != 1 && (
                        <div className="Student__left">
                            {
                                addingStudentToGroup ? (
                                    <span><i className="fa-solid fa-circle-notch"></i></span>
                                ) : (
                                    <div className="Student__select"></div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            {
                exception && (
                    <div className={'Student__exception'}>
                        {
                            student.exception !== '0' ? (
                                <div className={'Student__exception--added'}>
                                    مضاف
                                </div>
                            ) : (
                                <div onClick={async e => {
                                    setExceptingStudent(true);
                                    const data = {
                                        grade: params.id,
                                        id: student.id,
                                        exception_status: 1
                                    };
                                    await exceptStudent(data);
                                    setExceptingStudent(false);
                                }} className={'Student__exception--add'}>{
                                    exceptingStudent ? (
                                        <Spinner />
                                    ) : (
                                        <span>استثناء الطالب</span>
                                    )
                                }</div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    exceptingStudent: state.settings.exceptingStudent
})

export default connect(mapStateToProps, {exceptStudent}) (Student);