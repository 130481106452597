import React from 'react';
import './Teacher.css';

const Teacher = () => {
    return (
        <div className={'Teacher'}>
            <div className="Teacher__container">
                <div className="Teacher__avatar">
                    <img src="" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Teacher;