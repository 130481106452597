import React from 'react';
import './ClassExam.css';
import ExamImg from '../../../../../../../../assets/images/classExam.jpg';
import {Link, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {changeClassCurrentExam} from "../../../../../../../../store/actions/doors.actions";
import {changeCurrentVideoUrl} from "../../../../../../../../store/actions/ui.actions";

function ClassExam({exam, currentExam, link, setCurrentExam, modal, setModal, changeClassCurrentExam}) {
    const params = useParams()
  return (
    <div onClick={e => {
        setModal(true);
        changeClassCurrentExam(exam);
    }} className={'ClassExam'}>
      <div className="ClassExam__img">
        <img src={ExamImg} alt={'examImg'}/>
      </div>
      <div className="ClassExam__name">
        <p>{exam.exam_name}</p>
      </div>
    </div>
  )
}

export default connect(null, {changeClassCurrentExam}) (ClassExam);