import React from 'react';
import './ClassBankQuestion.css';
import ClassBankQuestionAnswer from "./ClassBankQuestionAnswer/ClassBankQuestionAnswer";

const ClassBankQuestion = ({question}) => {
    const formatChoices = () => {
        if(question.ques_type == 0) {
            return [
                question.first_ans,
                question.second_ans
            ]
        }else {
            return [
                question.first_ans,
                question.second_ans,
                question.third_ans,
                question.fourth_ans
            ]
        }
    }
    return (
        <div className={'ClassBankQuestion'}>
            <p className={'ClassBankQuestion__question'} dangerouslySetInnerHTML={{__html: question.question}}></p>
            <div className="ClassBankQuestion__answers">
                {
                    formatChoices().map((answ, i) => (
                        <ClassBankQuestionAnswer rightAnsw={question.true_answer == i+1} answ={answ} key={i} />
                    ))
                }
            </div>
        </div>
    );
};

export default ClassBankQuestion;