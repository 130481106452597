import React, {useEffect} from 'react';
import './SQs.css';
import {fetchGradeSQ} from "../../../store/actions/sq.actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

const SQs = ({fetchGradeSQ}) => {
    const params = useParams();
    useEffect(() => {
        const data = {
            record: 0,
            grade: params.id
        };

        fetchGradeSQ(data);
    }, []);
    return (
        <div className={'Sqs'}>

        </div>
    );
};

export default connect(null, {fetchGradeSQ}) (SQs);