import React, {useEffect, useState} from 'react';
import './GroupAbsence.css';
import Header from "../../../../../Components/Header/Header";
import GroupDayPicker from "./GroupDayPicker/GroupDayPicker";
import {connect} from "react-redux";
import {fetchAbsenceStudents} from "../../../../../store/actions/groups.actions";
import {useParams} from "react-router-dom";
import StudentSearch from "../GroupAddStudent/StudentSearch/StudentSearch";
import Spinner from "../../../../../Components/Spinner/Spinner";
import Student from "../GroupAddStudent/Student/Student";
import GroupAttend from "./GroupAttend/GroupAttend";
import {addStudentToAbsence} from "../../../../../store/actions/groups.actions";

const GroupAbsence = ({students, fetchingAbsences, fetchAbsenceStudents, addStudentToAbsence, currentGroupCount}) => {
    const [date, setDate] = useState(new Date().toLocaleDateString('en-ZA'));
    const [attend, setAttend] = useState(2);
    const [term, setTerm] = useState('');
    const [searchResults, setSearchResults] = useState(students);
    const params = useParams();

    useEffect(() => {
        handleSearch(term);
    }, [students, attend, date]);

    useEffect(() => {
        if(!attend) return;
        const data = {
            date,
            group_id: params.group_id,
            attend
        }
        fetchAbsenceStudents(data);
    }, [attend, date]);

    const handleSearch = (value) => {
        let studentsMatch = [];
        if(!isNaN(value)) {
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.phone.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch);
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.phone.toLowerCase().split('').indexOf(firstLetter[0]) - b.phone.toLowerCase().split('').indexOf(firstLetter[0]));

        } else {
            console.log(value);
            for(let i = 0; i < students?.length; i++) {
                if(students[i]?.name?.trim().toLowerCase().includes(value.toLowerCase())) {
                    studentsMatch.push(students[i]);
                    console.log(studentsMatch)
                }
            }
            const firstLetter = value.split('').splice(0, 1);
            studentsMatch.sort((a, b) => a.name.toLowerCase().split('').indexOf(firstLetter[0]) - b.name.toLowerCase().split('').indexOf(firstLetter[0]));
        }

        setSearchResults(studentsMatch);
    }
    const inputChangeHandler = e => {
        setTerm(e.target.value);
        let value = e.target.value;
        if(!value || value.trim().length == 0) setSearchResults(students);
        handleSearch(value);
    }

    return (
        <div className={'GroupAbsence'}>
            <Header text={'اضافة الحضور و الغياب'} />
            <div className="GroupAbsence__header">
                <GroupDayPicker date={date} setDate={setDate} />
                <StudentSearch term={term} inputChangeHandler={inputChangeHandler} />
                <GroupAttend absenses={true} setAttend={setAttend} attend={attend} />
            </div>
            {
                fetchingAbsences ? (
                    <Spinner />
                ) : (
                    <div className={'GroupAddStudent__students'}>
                        {
                            searchResults?.map((s, i) => (
                                <Student clickToAddFunc={addStudentToAbsence} date={date} attend={attend} student={s} key={i} currentGroupCount={currentGroupCount} />
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    fetchingAbsences: state.groups.fetchingAbsences,
    students: state.groups.absences,
    currentGroupCount: state.groups.currentGroupCount
})

export default connect(mapStateToProps, {fetchAbsenceStudents, addStudentToAbsence}) (GroupAbsence);