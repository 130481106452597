import {END_FETCHING_QUESTION_BANK, FETCH_QUESTION_BANK, START_FETCHING_QUESTION_BANK} from "./types.actions";
import axios from "axios";
import {BASE_URL} from "../../utls/constants";

const startFetchingQuestionBank = {
    type: START_FETCHING_QUESTION_BANK
}

const endFetchingQuestionBank = {
    type: END_FETCHING_QUESTION_BANK
}

export const fetchQuestionBank = data => async dispatch => {
    try {
        dispatch(startFetchingQuestionBank);
        const formData = new FormData();
        formData.append('class_id', data.class_id);
        const res = await axios.post(`${BASE_URL}select_question_bank.php`, formData);
        console.log(res);
        if(res.status == 200) {
            dispatch({
                type: FETCH_QUESTION_BANK,
                questions: res.data
            })
        }
        dispatch(endFetchingQuestionBank);
    } catch (e) {
        console.error(e?.message);
        dispatch(endFetchingQuestionBank);
    }
}