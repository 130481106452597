import React from 'react';
import './StudentSearch.css';

const StudentSearch = ({term, inputChangeHandler}) => {
    return (
        <div className={'StudentSearch'}>
            <div className="StudentSearch__container">
                <i className="fa-solid fa-magnifying-glass StudentSearch__mag"></i>
                <input placeholder={'search by name or phone'} value={term} onChange={inputChangeHandler} type="text" className="StudentSearch__input"/>
            </div>
        </div>
    );
};

export default StudentSearch;