import React, {useEffect} from 'react';
import './Door.Delete.Modal.css';
import {useParams} from "react-router-dom";

const DoorDeleteModal = ({deleting, deletedDoorId, setDeletedDoorId, deletingDoor, deleteDoor, setDeleting}) => {
    const params = useParams();
    useEffect(() => {
        if(!deletedDoorId) return setDeleting(false);
        return () => {
            setDeletedDoorId(null);
        }
    }, [deletedDoorId]);

    return (
        <div className={`DoorDeleteModal ${deleting && 'DoorDeleteModal__show'}`}>
            <div className="DoorDeleteModal__container">
                <div className="DoorDeleteModal__header">
                    <p> هل انت متأكد من مسح {deletedDoorId?.name}</p>
                </div>
                <div className="DoorDeleteModal__btns">
                    <div className="DoorDeleteModal__btns--btn DoorDeleteModal__btns--yes" onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        const data = {
                            door_id: deletedDoorId.id,
                            grade: params.id
                        };
                        await deleteDoor(data);
                        setDeletedDoorId(null);
                        setDeleting(false);
                    }}>
                        {
                            deletingDoor ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>YES</span>
                            )
                        }
                    </div>
                    <div className="DoorDeleteModal__btns--btn DoorDeleteModal__btns--no" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeletedDoorId(null);
                        setDeleting(false);
                    }}>NO</div>
                </div>
            </div>

        </div>
    );
};

export default DoorDeleteModal;