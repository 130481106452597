import axios from "axios";
import {
    END_LOGGING_USER_IN, END_LOGGING_USER_OUT,
    FETCHING_TEACHER_INFO, LOAD_USER_END,
    LOAD_USER_START, LOG_USER_OUT,
    RETURN_USER_ERROR,
    START_LOGGING_USER_IN, START_LOGGING_USER_OUT
} from "./types.actions";
import {setAlert} from "./ui.actions";
import {BASE_URL} from "../../utls/constants";


export const loadUser = (navigate) => async dispatch => {
    try {
        dispatch(loadStart);
        const userInfo = JSON.parse(localStorage.getItem("user"));
        if(!userInfo || userInfo === '0') {
            navigate('/login');
            dispatch(loadEnd);

            setTimeout(() => dispatch(setAlert('Please, login again!', 'danger')), 4000)

        }else {
            dispatch({
                type: FETCHING_TEACHER_INFO,
                data: userInfo
            });
            setTimeout(() => dispatch(setAlert('Welcome back mister!', 'success')), 4000)
            dispatch(loadEnd);

        }
    } catch (e) {
        console.error(e.message);
        dispatch(loginEnd);
    }
}


export const loginTeacher = (data, navigate) => async dispatch => {
    try {
        dispatch(loginStart);
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);
        const res = await axios.post(`${BASE_URL}teacher_login.php`, formData, {
            headers: {
                'content-type': 'application/form-data'
            }
        });
        if(res.data === '0') {
            dispatch(loginEnd);
            dispatch(setAlert('Wrong credentials!', 'danger'))

            return dispatch({
                type: RETURN_USER_ERROR
            });
        }
        dispatch({
            type: FETCHING_TEACHER_INFO,
            data: res.data
        });
        dispatch(setAlert('Welcome back mister!', 'success'))
        navigate('/');
        dispatch(loginEnd);
    } catch (e) {
        console.error(e.message);
        dispatch(loginEnd);
    }
}

export const loginStart = {
    type: START_LOGGING_USER_IN
};

export const loginEnd = {
    type: END_LOGGING_USER_IN
}

export const loadStart = {
    type: LOAD_USER_START
}

export const loadEnd = {
    type: LOAD_USER_END
}

export const startLoggingUserOut = {
    type: START_LOGGING_USER_OUT
}

export const endLoggingUserOut = {
    type: END_LOGGING_USER_OUT
}

export const logUserOut = () => async dispatch => {
    try {
        dispatch(startLoggingUserOut);
        dispatch({
            type: LOG_USER_OUT
        });
        dispatch(endLoggingUserOut);
    } catch (e) {
        console.log(e.message);
        dispatch(endLoggingUserOut);
    }
}
