import VideoImage from '../../../../assets/images/grade Video.png'
import ShowVideoList from "./ShowVideoList/ShowVideoList";
const VideoList = ({element,setEditvideo,setEditID}) => {
    const renderShowElement=element.map((el)=>{
        return<ShowVideoList el={el.list_name} image={VideoImage} ID={el.id} setEditvideo={setEditvideo} setEditID={setEditID} />
    })
    console.log('videoID')
    console.log(setEditvideo)
    return (
        <div className='ListOfElement'>
            {renderShowElement}
        </div>
    )
}
export default VideoList;