import React from 'react';
import {connect} from "react-redux";
import './Alert.css';

const Alert = (props) => {
    return (
        <div className={'Alert'}>
            {
                props.alerts.map(alert => (
                    <div className={`Alert__item Alert__item-${alert.alertType}`}>
                        {alert.msg}
                    </div>
                ))
            }
        </div>
    );
};

const mapStateToProps = state => ({
    alerts: state.ui.alerts
})

export default connect(mapStateToProps) (Alert);