import React, {useEffect, useState} from 'react';
import './YoutubeForm.css';
import { connect } from 'react-redux';
import { insertClassVideo } from '../../../../../../../../store/actions/doors.actions';
import { useParams } from 'react-router-dom';
import { setAlert } from '../../../../../../../../store/actions/ui.actions';

function YoutubeForm({show, setShow, insertClassVideo, setAlert, creatingClassVideo}) {
	const [url, setUrl] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
    const params = useParams();

    useEffect(() => {

    }, []);

    const submitHandler = async e => {
        e.preventDefault();
        const data = {
            url,
            title,
            desc: description,
            class_id: params.class_id ? params.class_id : 0,
            videoListId: params.video_list ? params.video_list : 0
        }
        if(url.length === 0 || title.length === 0) {
            return setAlert('من فضل اخل البيانات المطلوبة!', 'danger');
        }
        await insertClassVideo(data);
        setUrl('');
        setTitle('');
        setDescription('');
        setShow(false);
    }
	return (
		<div onClick={e => setShow(false)} className={`YoutubeForm ${show && 'YoutubeForm__show'}`}>
			<form onClick={e => {
                e.preventDefault();
                e.stopPropagation();    
            }} className={`YoutubeForm__form`}>
                <div className='YoutubeForm__element'>
                    <label>رابط الفديو</label>
                    <input value={url} onChange={e => setUrl(e.target.value)} type='text' />
                </div>
                <div className='YoutubeForm__element'>
                    <label>عنوان الفديو</label>
                    <input value={title} onChange={e => setTitle(e.target.value)} type='text' />
                </div>
                <div className='YoutubeForm__element'>
                    <label>وصف الفديو</label>
                    <textarea value={description} onChange={e => setDescription(e.target.value)} />
                </div>
                <div className='YoutubeForm__btns'>
                    <button onClick={e => {
                        // e.preventDefault();
                        setShow(false);
                    }} type='cancel'>الغاء</button>
                    <button onClick= {e => {
                        e.preventDefault();
                        e.stopPropagation();
                        submitHandler(e);
                    }} type='submit'>{creatingClassVideo ? <i class="fa-solid fa-circle-notch"></i> : 'انشاء'}</button>
                </div>
            </form>
		</div>
	)
}

const mapStateToProps = state => ({
    creatingClassVideo: state.doors.creatingClassVideo
})

export default connect(mapStateToProps, {insertClassVideo, setAlert}) (YoutubeForm);