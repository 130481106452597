import React, {useEffect, useRef} from 'react';
import examImg from '../../assets/images/exam.jpg';
import './Exams.css';
import { Link, useParams } from 'react-router-dom';

function Exams() {
  const params = useParams();
    const boxRef = useRef();

    // const changeHeightToWidth = () => {
    //     const boxEle = boxRef.current;
    //     if(!boxEle) return;
    //     const boxWidth = boxEle.getBoundingClientRect().width;
    //     boxEle.style.height = `${boxWidth}px`;
    // }
    //
    // useEffect(() => {
    //     if(!boxRef.current) return;
    //     changeHeightToWidth();
    // }, []);
    //
    // useEffect(() => {
    //     window.addEventListener('resize', () => {
    //         changeHeightToWidth();
    //     });
    // }, []);
  return (
    <Link ref={boxRef} to={`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/exams`} className='Exams'>
      <img src={examImg} />
      <p>الاداء</p>
    </Link>
  )
}

export default Exams