import React, {useState} from 'react';
import './GroupCreateForm.css';
import {createGroup} from "../../../../store/actions/groups.actions";
import {connect, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {setAlert} from "../../../../store/actions/ui.actions";

const GroupCreateForm = ({isActive, setIsActive, creatingGroup}) => {
    const [title,setTitle]=useState(" ")
    const [time,setTime]=useState("")
    const [days,setDays]=useState("")
    const dispatch =useDispatch();
    const params = useParams();

    const handleCreateGroup=async (e)=>{
        e.preventDefault();
        if(!title) return dispatch(setAlert('من فضلك ادخل اسم المجموعة', 'danger'));
        if(!days) return dispatch(setAlert('من فضلك ادخل ايام المجموعة', 'danger'));
        const data = {
            grade: params.id,
            time,
            days,
            name: title
        }
        await dispatch(createGroup(data));
        setIsActive(current => !current);
        setTitle('');
        setTime('');
        setDays('');
    }

    const handleCancelClick= (e)=>{
        e.preventDefault();
        setIsActive(current => !current);
        setTitle('');
        setTime('');
        setDays('');
    }
    return (
        <div style={{display: !isActive ? 'none' : 'block' }} className='GroupCreateForm'>
            <div style={{display: !isActive ? 'none' : 'block' }} className='Groups__Container--git-info'>
                <form>
                    <label className='Groups__Container-lable'>اسم المجموعة </label>
                    <input  value={title} onChange={(e)=>setTitle(e.target.value)} className='Groups__Container-input' type='text' placeholder='ادخل اسم المحموعة'/>
                    <label  className='Groups__Container-lable'>وقت المجموعة (غير مطلوب)</label>
                    <input value={time} onChange={(e)=>setTime(e.target.value)} className='Groups__Container-input' type='text' placeholder='ادخل وقت المحموعة'/>
                    <label  className='Groups__Container-lable' >ايام المجموعة(مطلوب)</label>
                    <input  value={days} onChange={(e)=>setDays(e.target.value)} className='Groups__Container-input' type='text' placeholder='ادخل ايام المحموعة'/>
                    <div className='Groups__Container--git-butons'>
                        <button onClick={handleCancelClick} className='git__butons--btn1'><span>الغاء</span></button>
                        <button onClick={handleCreateGroup}  className='git__butons--btn'><span>
                            {
                                creatingGroup ? (
                                    <span><i className="fa-solid fa-circle-notch"></i></span>
                                ) : (
                                    <span>انشاء</span>
                                )
                            }
                        </span></button>
                    </div>
                </form>
            </div>
        </div>

    );
};

const mapStateToProps = state => ({
    creatingGroup: state.groups.creatingGroup
})

export default connect(mapStateToProps, {}) (GroupCreateForm);