import React from 'react';
import './QuestionBank.css';
import {Link, useParams} from "react-router-dom";
import bankImage from '../../assets/images/questionbank.jpg';

const QuestionBank = () => {
    const params = useParams();
    return (
        <Link to={`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/bank`} className={'QuestionBank'}>
            <img src={bankImage} alt=""/>
            <p>بنك الاسئلة</p>
        </Link>
    );
};

export default QuestionBank;