import {v4} from 'uuid';
import {
    CHANGE_CURRENT_VIDEO_URL, CHANGE_GRADE_ID,
    REMOVE_ALERT,
    SET_ALERT,
    TOGGLE_CLASS_EXAM_SAVER,
    TOGGLE_SIDEBAR,
    TOGGLE_VIDEO_PLAYER
} from "./types.actions";


export const setAlert = (msg, alertType, timeout=3000) => dispatch => {
    const id = v4();
    console.log(id);
    dispatch({
        type: SET_ALERT,
        payload: {msg, alertType, id}
    });
    setTimeout(() => dispatch({type: REMOVE_ALERT, payload: id}), timeout);
}

export const toggleSidebar = () => {
    return {
        type: TOGGLE_SIDEBAR
    }
}

export const changeCurrentVideoUrl = url => ({
    type: CHANGE_CURRENT_VIDEO_URL,
    url
});

export const toggleVideoPlayer = () =>  ({
    type: TOGGLE_VIDEO_PLAYER
});


export const toggleClassExamSaver = () => {
    return {
        type: TOGGLE_CLASS_EXAM_SAVER
    }
}

export const changeGradeId = id =>  ({
    type: CHANGE_GRADE_ID,
    id
});