import React, {useEffect, useState} from 'react';
import './YoutubeVideoEditModal.css';
import {useParams} from "react-router-dom";

const YoutubeVideoEditModal = ({editedVideo, setEditedVideo, setEditing, editing, editVideo, editingVideo}) => {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [desc, setDesc] = useState('');

    const params = useParams();

    useEffect(() => {
        if(!editedVideo) return setEditing(false);
        setDesc(editedVideo.desc_video);
        setTitle(editedVideo.title_video);
        setUrl(editedVideo.url);

        return () => {
            setEditing(false);
            setEditedVideo(null);
        }
    }, [editedVideo]);

    return (
        <div className={`YoutubeVideoEditModal ${editing && 'YoutubeVideoEditModal__show'}`}>
            <div className="YoutubeVideoEditModal__container">
                <div className="YoutubeVideoEditModal__element">
                    <label htmlFor="title">رابط الفديو</label>
                    <input value={url} onChange={e => {
                        setUrl(e.target.value);
                    }} type="text"/>
                </div>
                <div className="YoutubeVideoEditModal__element">
                    <label htmlFor="title">عنوان الفديو</label>
                    <input value={title} onChange={e => {
                        setTitle(e.target.value);
                    }} type="text"/>
                </div>
                <div className="YoutubeVideoEditModal__element">
                    <label htmlFor="title">وصف الفديو</label>
                    <input type={'text'} value={desc} onChange={e => {
                        setDesc(e.target.value);
                    }} />
                </div>
                <div className="YoutubeVideoEditModal__btns">
                    <div onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(editedVideo.desc_video == desc && editedVideo.title_video == title && editedVideo.url == url) return;
                        const data = {
                            id: editedVideo.id,
                            class_id: params.class_id ? params.class_id : 0,
                            title,
                            desc,
                            url,
                            videoListId: params.video_list ? params.video_list : 0
                        };
                        await editVideo(data);
                        setEditing(false);
                        setEditedVideo(null);
                    }} className="YoutubeVideoEditModal__btn YoutubeVideoEditModal__btn--edit">
                        {
                            editingVideo ? (
                                <span><i className="fa-solid fa-circle-notch"></i></span>
                            ) : (
                                <span>تعديل</span>
                            )
                        }
                    </div>
                    <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditedVideo(null);
                        setEditing(false);
                    }} className="YoutubeVideoEditModal__btn YoutubeVideoEditModal__btn--cancel">
                        <span>الغاء</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutubeVideoEditModal;