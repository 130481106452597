import React, {useEffect, useRef} from 'react';
import youtubeImg from '../../assets/images/youtube.jpg';
import './Youtube.css';
import { Link, useParams } from 'react-router-dom';

function Youtube() {
  const params = useParams();
  const boxRef = useRef();

  // const changeHeightToWidth = () => {
  //     const boxEle = boxRef.current;
  //     if(!boxEle) return;
  //     const boxWidth = boxEle.getBoundingClientRect().width;
  //     boxEle.style.height = `${boxWidth}px`;
  //     console.log(boxWidth)
  // }
  //
  // useEffect(() => {
  //     if(!boxRef.current) return;
  //     changeHeightToWidth();
  // }, []);
  //
  // useEffect(() => {
  //     window.addEventListener('resize', () => {
  //         changeHeightToWidth();
  //     });
  // }, []);

  return (
    <Link ref={boxRef} to={`/grade/${params.id}/doors/${params.door_id}/class/${params.class_id}/videos`} className='Youtube'>
      <img src={youtubeImg} />
      <p>الفديوهات</p>
    </Link>
  )
}

export default Youtube;