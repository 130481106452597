import React, {useEffect, useState} from 'react'
import "./settings.css";
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import Group from "../../assets/images/group.avif";
import Exsame from "../../assets/images/exsame.webp";
import Video from "../../assets/images/video.jpg";
import SettingsImg from '../../assets/images/settings.jpg';
import {logUserOut} from "../../store/actions/user.actions";
import {toggleSidebar} from "../../store/actions/ui.actions";

const Settings = ({gradeId, logUserOut, toggleSidebar}) => {
  const [pathLength, setPathLength] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const history = useLocation();

  useEffect(() => {
    setPathLength(history.pathname.split('/').length > 4);
  }, [history]);


  return (
    <div className='Setting'>
      <div className='Settings__container'>
        <div className='Settings__right'>
          <h2 className='Settings__right-adress'><i class="fa-solid fa-sliders"></i> الاعدادات</h2>
          <div className='Settings__right--line'></div>
          <div className='Settings__right--links'>
            <div className="Settings__right--links--section">
              <Link to='teacher' className='Settings__right--links-a'><i className="fa-solid fa-plus"></i>اضافة مساعد مدرس </Link>
              <Link className='Settings__right--links-a'><i className="fa-solid fa-arrow-up"></i>نقل الطلبه للمرحلة المقبلة </Link>
              <Link className='Settings__right--links-a'><i className="fa-solid fa-wallet"></i>تحديث بيانات المصروفات</Link>
              <Link to={'exception'} className='Settings__right--links-a'><i className="fa-regular fa-circle-xmark"></i>الاستثناءات</Link>
              <Link to={'reports'} className='Settings__right--links-a'><i class="fa-regular fa-newspaper"></i>تقريرك </Link>
            </div>
            <div className='Settings__right--links--section'>
              <div onClick={e => navigate(`/grade/${params.id}/groups`)} className='Settings__right--links-a'><i class="fa-solid fa-users"></i> مجموعاتي</div>
              <div onClick={e => navigate(`/grade/${params.id}/video`)} className='Settings__right--links-a'><i class="fa-solid fa-video"></i> مكتبة الفديوهات</div>
              <div onClick={e => navigate(`/grade/${params.id}/exsam`)} className='Settings__right--links-a'><i class="fa-solid fa-flask-vial"></i> اختباراتي</div>
            </div>
            <div className="Settings__right--links--section">
              <div onClick={e => navigate(`/grade/${params.id}/settings/wallet`)} className='Settings__right--links-a'><i class="fa-solid fa-wallet"></i> محفظتي الشهريه</div>
            </div>
            <div onClick={e => {
              toggleSidebar();
              logUserOut();
              console.log('kfldjalkfjdslk')
              navigate('/login');
            }} className='Settings__right--links-a'> <i class="fa-solid fa-right-from-bracket"></i> تسجيل الخروج</div>
          </div>
        </div>
        <div className={`Settings__left ${pathLength && 'Settings__left--full'}`}>
          {!pathLength && <img src={SettingsImg} className={'Settings__left--image'}/>}
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  gradeId: state.ui.gradeId
})

export default connect(mapStateToProps, {logUserOut, toggleSidebar}) (Settings);